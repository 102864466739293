import React, { useState } from "react";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";

interface CalendarProps {
  onChange: (value: string) => void;
  closeCalendar: () => void;
}

const Calendar: React.FC<CalendarProps> = ({ onChange, closeCalendar }) => {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState<Date>(today);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const daysInMonth = (month: number, year: number): number =>
    new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = (month: number, year: number): number =>
    new Date(year, month, 1).getDay();

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };

  const handleDateClick = (day: number) => {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day,
      5,
      30
    );
    if (date <= today) {
      setSelectedDate(date);
      onChange(date.toISOString().split("T")[0]);
      closeCalendar();
    }
  };

  const renderDays = () => {
    const days: JSX.Element[] = [];
    const totalDays = daysInMonth(
      currentDate.getMonth(),
      currentDate.getFullYear()
    );
    const firstDay = firstDayOfMonth(
      currentDate.getMonth(),
      currentDate.getFullYear()
    );

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`}></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const isSelected =
        selectedDate &&
        selectedDate.getDate() === day &&
        selectedDate.getMonth() === currentDate.getMonth() &&
        selectedDate.getFullYear() === currentDate.getFullYear();
      const isDisabled = date > today || date < new Date("2024-09-29");

      days.push(
        <div
          key={day}
          className={`cursor-pointer text-sm ${
            isSelected ? "bg-active p-0.5 rounded-full" : ""
          } ${isDisabled ? "text-gray-300 cursor-not-allowed" : ""}`}
          onClick={() => !isDisabled && handleDateClick(day)}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="bg-white rounded-lg p-3 w-44 -ml-4 shadow-2xl">
      <div className="flex justify-between items-center mb-3">
        <button className="text-gray-600" onClick={handlePrevMonth}>
          <LeftArrow />
        </button>
        <div className="text-center">
          <div className="font-bold text-md">
            {currentDate.toLocaleString("default", { month: "long" })}
          </div>
          <div className="text-secondary text-xs">
            {currentDate.getFullYear()}
          </div>
        </div>
        <button
          className={`text-[#4d4d4d] ${
            currentDate.getFullYear() === today.getFullYear() &&
            currentDate.getMonth() === today.getMonth()
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={handleNextMonth}
          disabled={
            currentDate.getFullYear() === today.getFullYear() &&
            currentDate.getMonth() === today.getMonth()
          }
        >
          <RightArrow />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-center text-gray-600">
        {renderDays()}
      </div>
      <div className="mt-4 mx-2 border-t border-light">
        <button className="w-full mt-2 bg-active text-gray-700 py-1 rounded-full">
          Select
        </button>
      </div>
    </div>
  );
};

export default Calendar;
