import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as DropdownArrow } from "../../assets/icons/dropdownArrow.svg";

interface DropdownProps {
  label: string;
  options: string[];
  onSelect: (option: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ label, options, onSelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(label); // New state to track selected option
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelect = (option: string) => {
    setSelectedLabel(option); // Update the displayed label to the selected option
    onSelect(option); // Pass the selected option to the parent
    setDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button" // Prevent form submission
        className="mt-0 block w-full pl-3 pr-10 py-1 text-left text-base border border-default rounded-lg"
        onClick={toggleDropdown}
      >
        {/* Display the selected label */}
        <span className="text-secondary text-xs">{selectedLabel}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <span
            className={`transition-transform duration-300 ${
              dropdownOpen ? "rotate-180" : ""
            }`}
          >
            <DropdownArrow className="w-2 h-2" />
          </span>
        </span>
      </button>
      {dropdownOpen && (
        <div className="absolute mt-0 w-full rounded-md shadow-lg bg-white z-10 max-h-48 overflow-y-auto">
          {options.map((option, index) => (
            <button
              key={index}
              type="button" // Prevent form submission
              className="block w-full text-left px-4 py-2 text-xs text-secondary border-b-2 border-light"
              onClick={() => handleSelect(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
