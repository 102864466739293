import React from "react";

// eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
function NewsFeedIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.fill}
        d="M13.55 11.485a2.328 2.328 0 00-2.327 2.332c0 1.314 1.013 2.313 2.44 2.313l-.011.002c1.192 0 2.193-.983 2.193-2.28 0-1.349-1.001-2.367-2.294-2.367z"
      ></path>
      <path
        fill={props.fill}
        d="M5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5zm13.52 14.264c0 .74-.6 1.341-1.341 1.341h-1.028a.313.313 0 01-.313-.313c0-.277-.343-.426-.571-.268-.653.452-1.352.626-2.215.626-1.142 0-2.124-.371-2.947-1.114-1.086-.956-1.648-2.227-1.648-3.701 0-1.351.524-2.561 1.507-3.506.878-.859 1.946-1.298 3.139-1.298.986 0 2.647-.837 2.647-1.823v-.193c0-.522.367-.973.879-1.08l1.891-.391s0-.001 0 0c.001 0 0 0 0 0v11.72z"
      ></path>
    </svg>
  );
}

export default NewsFeedIcon;
