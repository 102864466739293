import React from "react";
import { getBoardByBoardId } from "../../app/api/admin/board";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteContentById,
  getContentByBoard,
} from "../../app/api/admin/content";
import { Content } from "../../interface/ContentInterface";

interface deleteModalProps {
  onClose: () => void;
  contentId: string;
  boardId?: string;
  setContentData?: React.Dispatch<React.SetStateAction<Content[]>>;
  closeExpandView?: () => void;
}
const DeleteModal: React.FC<deleteModalProps> = ({
  onClose,
  boardId,
  contentId,
  setContentData,
  closeExpandView,
}) => {
  const handleRemoveContent = async () => {
    try {
      await deleteContentById(contentId || "");
      await toast.success("Successfully deleted the content", {
        hideProgressBar: true,
      });
      onClose();
      closeExpandView?.();
      getBoardData(boardId);
    } catch (e) {
      toast.error("Error while deleting the content", {
        hideProgressBar: true,
      });
    }
  };

  const getBoardData = async (boardId: string = "") => {
    await getBoardByBoardId(boardId || "");
    const contentResponse = await getContentByBoard(boardId || "", 1);
    setContentData?.(contentResponse.data.data.contents);
  };
  return (
    <div className="flex items-center justify-center  bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">
          Are you sure you want to remove?
        </h2>

        <div className="flex justify-between">
          <button
            className="bg-white text-gray-600 border border-gray-300 rounded-full px-6 py-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            className="bg-red-600 text-white rounded-full px-6 py-2"
            onClick={handleRemoveContent}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
