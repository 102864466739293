import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CollapseIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={props.fill}
        d="M11 21.5C5.2 21.5.5 16.8.5 11S5.2.5 11 .5 21.5 5.2 21.5 11 16.8 21.5 11 21.5zm0-13.65H6.8v2.1h9.45L11 4.7v3.15zm-5.25 4.2L11 17.3v-3.15h4.2v-2.1H5.75z"
      ></path>
    </svg>
  );
}

export default CollapseIcon;
