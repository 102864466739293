import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ContentIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill={props?.fill}
      viewBox="0 0 18 18"
    >
      <path d="M3.25 0A3.25 3.25 0 000 3.25v11.5A3.25 3.25 0 003.25 18h11.5A3.25 3.25 0 0018 14.75V3.25A3.25 3.25 0 0014.75 0H3.25zM3 5.25c0-.966.784-1.75 1.75-1.75h8.5c.966 0 1.75.784 1.75 1.75v1.5a1.75 1.75 0 01-1.75 1.75h-8.5A1.75 1.75 0 013 6.75v-1.5zM4.75 5a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h8.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-8.5zm-1 5h4.5a.75.75 0 110 1.5h-4.5a.75.75 0 110-1.5zM3 13.75a.75.75 0 01.75-.75h4.5a.75.75 0 110 1.5h-4.5a.75.75 0 01-.75-.75zM12.25 10h1c.966 0 1.75.784 1.75 1.75v1a1.75 1.75 0 01-1.75 1.75h-1a1.75 1.75 0 01-1.75-1.75v-1c0-.966.784-1.75 1.75-1.75zM12 11.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1z"></path>
    </svg>
  );
}

export default ContentIcon;
