import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DesignerPageIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill={props.fill}
      viewBox="0 0 24 24"
    >
      <path
        fill={props.fill}
        d="M16.498 18.75h1.688a2.812 2.812 0 002.812-2.812V14.25h-4.5v4.5zm0-5.625v-4.5h4.5v4.5h-4.5zm-1.125 0H11.71a5.06 5.06 0 00-.837-1.495V8.625h4.5v4.5zm-3.869 3.87l1.755 1.755h2.114v-4.5h-3.405c.02.185.03.372.03.563 0 .781-.176 1.522-.494 2.182zM6.936 9.75c1.04 0 2.008.315 2.812.853V8.625h-4.5v1.413a5.053 5.053 0 011.688-.288zm9.562-2.25h4.5V5.813A2.813 2.813 0 0018.186 3h-1.688v4.5zM15.373 3v4.5h-4.5V3h4.5zM9.748 3v4.5h-4.5V5.813A2.813 2.813 0 018.061 3h1.687z"
      ></path>
      <path
        fill={props.fill}
        d="M6.936 18.75a3.92 3.92 0 002.358-.784l2.87 2.869a.563.563 0 00.796-.796l-2.869-2.868a3.938 3.938 0 10-3.155 1.58zm0-1.125a2.812 2.812 0 110-5.625 2.812 2.812 0 010 5.625z"
      ></path>
    </svg>
  );
}

export default DesignerPageIcon;
