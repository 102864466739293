interface Props {
  title?: string;
  description?: string;
  url?: string;
}

//Function to copy text to clipboard
export const copyToClipboard = async ({
  title,
  description,
  url,
}: Props): Promise<string> => {
  const htmlContent = `
      <div style="font-family: 'Times New Roman', serif;">
        <strong>${title}</strong><br>
        ${description}<br>
        <a href="${url}" target="_blank">${url}</a>
      </div>
    `;

  const plainTextContent = `${title}\n${description}\n${url}`;

  const data = [
    new ClipboardItem({
      "text/html": new Blob([htmlContent], { type: "text/html" }),
      "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
    }),
  ];

  try {
    await navigator.clipboard.write(data);
    return "success";
  } catch (error) {
    console.error("Clipboard error:", error);
    return "error";
  }
};
