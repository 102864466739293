import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
const NotFoundPage: React.FC = () => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const x = e.clientX;
      const y = e.clientY;

      if (imageContainerRef.current) {
        imageContainerRef.current.style.filter = "brightness(1)";
        imageContainerRef.current.style.maskImage = `radial-gradient(circle at ${x}px ${y}px, rgba(255,255,255,1) 150px, rgba(0,0,0,0) 250px)`;
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="relative w-full h-screen bg-black overflow-hidden flex items-center justify-center">
      <div className="absolute z-10 text-center text-white">
        <h1 className="text-4xl font-bold mb-4">Lost in Space?</h1>
        <p className="text-xl mb-8">
          It seems you've drifted too far off course. Let's get you back to
          safety.
        </p>
        <Link
          to="/"
          className="inline-block px-6 py-3 text-lg font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Go Back to Earth
        </Link>
      </div>

      <div
        ref={imageContainerRef}
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1484950763426-56b5bf172dbb?w=1600&dpr=1&auto=format&fit=crop&q=60')",
        }}
      />
    </div>
  );
};

export default NotFoundPage;
