import React from "react";
import { useFormik } from "formik";
import {
  TargetInterface,
  targetValidationSchema,
} from "../../interface/TargetInterface";
import { deleteTarget } from "../../app/api/admin/target";
import { toast } from "react-toastify";
import Dropdown from "../Common/Dropdown";
interface EditTargetProps {
  target: TargetInterface;
  onClose: () => void;
  onSave: (updatedTarget: TargetInterface) => void;
  fetchTargets: () => void;
  genreOptions: { _id: string; name: string }[];
  sourceOptions: { _id: string; name: string }[];
  regionOptions: { _id: string; name: string }[];
}

const EditTarget: React.FC<EditTargetProps> = ({
  target,
  onClose,
  onSave,
  fetchTargets,
  genreOptions,
  sourceOptions,
  regionOptions,
}) => {
  const formik = useFormik({
    initialValues: {
      brandName: target.brandName,
      socialHandle: target.socialHandle,
      instagramUsername: target.userName,
      description: target.description,
      genre: genreOptions.find(g => g.name === target.genre)?.name || "",
      brandWeightage: target.brandWeightage,
      source: sourceOptions.find(s => s.name === target.source)?.name || "",
      region: regionOptions.find(r => r.name === target.region)?.name || "",
      status: target.status,
    },
    validationSchema: targetValidationSchema,
    onSubmit: async values => {
      try {
        const updatedTarget = {
          ...target,
          brandName: values.brandName,
          socialHandle: values.socialHandle,
          userName: values.instagramUsername,
          description: values.description,
          genre: genreOptions.find(g => g.name === values.genre)?._id || "",
          brandWeightage: values.brandWeightage,
          source: sourceOptions.find(s => s.name === values.source)?._id || "",
          status: values.status,
        };

        if (values.genre === "Fashion") {
          updatedTarget.region =
            regionOptions.find(r => r.name === values.region)?._id ||
            regionOptions[0]._id;
        } else {
          delete updatedTarget.region;
        }

        delete updatedTarget.createdAt;
        delete updatedTarget.updatedAt;
        onSave(updatedTarget);
        onClose();
      } catch (error) {
        toast.error("Error while updating target", {
          hideProgressBar: true,
        });
      }
    },
  });

  const handleDelete = async () => {
    try {
      if (target._id) {
        const response = await deleteTarget(target._id);
        toast.success(response.data.message, {
          hideProgressBar: true,
        });
        onClose();
        fetchTargets();
      }
    } catch (error) {
      toast.error("Error while deleting target", {
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-white rounded-lg shadow-lg relative w-1/2">
        <div className="flex w-full items-center justify-between p-3 shadow-md">
          <h2 className="text-lg font-bold text-primary">Edit Source</h2>
          <button onClick={onClose} className="text-3xl">
            &times;
          </button>
        </div>

        <form
          className="flex shadow-md pt-8 pb-8"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col w-1/2 pl-8 pr-8">
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">Brand</label>
              <input
                type="text"
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                value={formik.values.brandName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="brand"
              />
              {formik.touched.brandName && formik.errors.brandName ? (
                <div className="text-negative text-sm">
                  {formik.errors.brandName}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Social Handle
              </label>
              <input
                type="text"
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                value={formik.values.socialHandle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="socialHandle"
              />
              {formik.touched.socialHandle && formik.errors.socialHandle ? (
                <div className="text-negative text-sm">
                  {formik.errors.socialHandle}
                </div>
              ) : null}
            </div>

            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Instagram Username
              </label>
              <input
                type="text"
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                value={formik.values.instagramUsername}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="instagramUsername"
              />
              {formik.touched.instagramUsername &&
              formik.errors.instagramUsername ? (
                <div className="text-negative text-sm">
                  {formik.errors.instagramUsername}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Description
              </label>
              <textarea
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm resize-none text-secondary"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="description"
                maxLength={250}
              />
              <p className="text-xs ml-2 text-gray-500 text-tertiary">
                {formik.values.description.length}/250 character limit
              </p>
              {formik.touched.description && formik.errors.description ? (
                <div className="text-negative text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col w-1/2 border-l-2 pl-8 pr-8">
            <div className="mb-4 flex flex-col">
              <label className="block text-sm mb-2 text-primary">
                Brand Weightage
              </label>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue(
                      "brandWeightage",
                      Math.max(formik.values.brandWeightage - 1, 1)
                    )
                  }
                  className="px-2 py-1 border rounded-lg"
                >
                  &minus;
                </button>
                <span className="mx-2 text-primary">
                  {formik.values.brandWeightage}
                </span>
                <button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue(
                      "brandWeightage",
                      Math.min(formik.values.brandWeightage + 1, 10)
                    )
                  }
                  className="px-2 py-1 border rounded-lg"
                >
                  +
                </button>
              </div>
              {formik.touched.brandWeightage && formik.errors.brandWeightage ? (
                <div className="text-negative text-sm">
                  {formik.errors.brandWeightage}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Select Genre
              </label>
              <select
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                value={formik.values.genre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="genre"
              >
                {genreOptions.map(g => (
                  <option key={g._id} value={g.name} className="text-sm py-2">
                    {g.name}
                  </option>
                ))}
              </select>
              {formik.touched.genre && formik.errors.genre ? (
                <div className="text-negative text-sm">
                  {formik.errors.genre}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">Sources</label>
              <select
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                value={formik.values.source}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="source"
              >
                {sourceOptions.map(s => (
                  <option key={s._id} value={s.name} className="text-sm py-2">
                    {s.name}
                  </option>
                ))}
              </select>
              {formik.touched.source && formik.errors.source ? (
                <div className="text-negative text-sm">
                  {formik.errors.source}
                </div>
              ) : null}
            </div>
            {formik.values.genre === "Fashion" && (
              <div className="mb-4">
                <label className="block text-sm mb-2 text-primary">
                  Region
                </label>
                <select
                  className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                  value={formik.values.region}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="region"
                >
                  {regionOptions.map(r => (
                    <option key={r._id} value={r.name} className="text-sm py-2">
                      {r.name}
                    </option>
                  ))}
                </select>
                {formik.touched.region && formik.errors.region ? (
                  <div className="text-negative text-sm">
                    {formik.errors.region}
                  </div>
                ) : null}
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">Status</label>
              <span className="flex text-secondary">
                <label className="w-1/2">
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={formik.values.status === "active"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />{" "}
                  Active
                </label>
                <label>
                  <input
                    type="radio"
                    name="status"
                    value="inactive"
                    checked={formik.values.status === "inactive"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />{" "}
                  Inactive
                </label>
              </span>
              {formik.touched.status && formik.errors.status ? (
                <div className="text-negative text-sm">
                  {formik.errors.status}
                </div>
              ) : null}
            </div>
          </div>
        </form>

        <div className="flex justify-around">
          <button
            className="w-1/3 font-bold text-sm px-6 py-3 rounded-full border-gray border-2 m-4"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            type="button"
            className="w-1/3 font-bold text-sm bg-black text-white px-6 py-3 rounded-full m-4"
            onClick={() => formik.handleSubmit()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTarget;
