import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  ClipboardEvent,
} from "react";
import { ReactComponent as ReturnArrow } from "../assets/icons/returnArrow.svg";
import { ReactComponent as HeartIcon } from "../assets/icons/heart.svg";
import { ReactComponent as Download } from "../assets/icons/download.svg";
import { ReactComponent as CopyToClipboard } from "../assets/icons/copyToClipboard.svg";
import { ReactComponent as ExpandSvg } from "../assets/icons/expand.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import Preferences from "../components/DesignerPage/Preferences";
import Modal from "../components/Common/Modal";
import ExpandView from "../components/DesignerPage/ExpandView";
import DownloadImage from "../components/DesignerPage/DownloadImage";
import SaveToBoard from "../components/DesignerPage/SaveToBoard";
import { toast } from "react-toastify";
import { toPng } from "html-to-image";
import {
  getBrandsData,
  getDesignerFocusPosts,
  getDropdownData,
  getProducts,
  searchDesignerFocusPosts,
} from "../app/api/designerPage";
import {
  FeedItem,
  GetPostParams,
  GetBrandsParams,
  DropdownData,
  SelectedPost,
  brandsParams,
} from "../interface/DesignerInterface";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  copyImageToClipboard,
  getFileName,
  getKeyForS3DesignerPage,
} from "../utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { getBoardsByUserId } from "../app/api/admin/board";
import { getUserId } from "../app/api/user/user";
import { Board } from "../interface/BoardsInterface";
import TrendsSidebar from "../components/DesignerPage/TrendsSidebar";
import CameraIcon from "../assets/icons/CameraIcon";
import { v4 as uuidv4 } from "uuid";
import { uploadImage } from "../app/api/uploadTos3";
import { Oval } from "react-loader-spinner";
import CrossIcon from "../assets/icons/CrossIcon";
import CustomizedModal from "../components/Common/CustomisedModal";
import { useDebounce } from "../app/hooks/targetHook";
import { throttle } from "lodash";

type Props = {
  isSidebarOpen: boolean;
};

const DesignerPage: React.FC<Props> = ({ isSidebarOpen }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [trendsOpen, setTrendsOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [posts, setPosts] = useState<FeedItem[]>([]);
  const [imageHandle, setImageHandle] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedPost, setSelectedPost] = useState<SelectedPost | null>(null);
  const [saveToBoardImage, setSaveToBoardImage] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const imageRef = useRef<HTMLInputElement>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const [genre, setGenre] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [category, setCategory] = useState<string>("");

  const [brands, setBrands] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [color, setColor] = useState<string>("");

  const [genres, setGenres] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);
  const [sources, setSources] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [source, setSource] = useState<string>("");
  const [brandsList, setBrandsList] = useState<{
    [key: string]: brandsParams[];
  }>({});
  const [productList, setProductList] = useState<string[]>([]);

  const user = useSelector((state: RootState) => state.user.userInfo);

  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [boardsList, setBoardsList] = useState<Board[]>([]);
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);

  const [image, setImage] = useState<string | null>(null);
  const [uploadImageUrl, setUploadImageUrl] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const loadingRef = useRef(false);
  const [isBrandsModalOpen, setBrandsModalOpen] = useState(false);
  const closeBrandsModal = () => setBrandsModalOpen(false);

  const controllerRef = useRef<null | AbortController>(null);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleTrendsToggle = () => {
    setTrendsOpen(!trendsOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target as Node)
    ) {
      setCalendarOpen(false);
    }
  };

  const getProductsData = async () => {
    try {
      const response = await getProducts();
      setProductList(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    getDropdownData().then(res => {
      setGenres(res.genres.map((i: DropdownData) => i.name));
      setRegions(res.regions.map((i: DropdownData) => i.name));
      setSources(res.sources.map((i: DropdownData) => i.name));
      setCategories(res.categories.map((i: DropdownData) => i.name));
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res?.data?.data[0]._id);
    }
  };

  const getBoardsByUser = async () => {
    if (userId) {
      const res = await getBoardsByUserId(userId);
      setPublicBoards(res?.data?.data.public.boards);
      setPrivateBoards(res?.data?.data.private.boards);
      setBoardsList(res?.data?.data);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoardsByUser();
  }, [userId]);

  const [isExpandViewOpen, setExpandViewOpen] = useState(false);
  const [isDownloadImageOpen, setDownloadImageOpen] = useState(false);
  const [isSaveToBoardOpen, setSaveToBoardOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const openExpandView = (post: FeedItem) => {
    setSelectedPost({
      imageUrl: `${process.env.REACT_APP_CLOUD_FRONT_URL}${post.image_link.substring(post.image_link.indexOf(".com/") + 5)}`,
      brandName: post.brand.brandName,
      description: post.description,
      source: post.handle,
      postId: post.postId,
      permalink: post.permalink,
      liveSince: post.postDate,
    });
    setExpandViewOpen(true);
  };

  const closeExpandView = () => setExpandViewOpen(false);

  const openDownloadImage = (imageUrl: string, source: string) => {
    setSelectedImage(imageUrl);
    setImageHandle(source);
    setDownloadImageOpen(true);
  };

  const closeDownloadImage = () => setDownloadImageOpen(false);
  const openSaveToBoard = (imageUrl: string) => {
    setSaveToBoardImage(imageUrl);
    setSaveToBoardOpen(true);
  };
  const handleOpenSaveToBoard = (imageUrl: string, post: FeedItem) => {
    setSaveToBoardImage(imageUrl);
    setSelectedPost({
      imageUrl: imageUrl,
      brandName: post.brand.brandName,
      description: post.description,
      source: post.handle,
      postId: post.postId,
      permalink: post.permalink,
      liveSince: post.postDate,
    });
    setSaveToBoardOpen(true);
  };

  const closeSaveToBoard = () => setSaveToBoardOpen(false);
  const closeDeleteModal = () => setIsDeleteOpen(false);

  const handleCopyImageToClipboard = async (
    imageUrl: string,
    postId: string
  ) => {
    await copyImageToClipboard(
      imageUrl,
      `IG/${posts.find(post => post.postId.toString() === postId)?.handle}`
    );
  };

  const fetchPosts = async (page: number, query: string = "") => {
    if (scrollRef.current && page === 1) {
      scrollRef.current.scrollTop = 0;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;

    loadingRef.current = true;
    const pageSize = 50;
    const params: GetPostParams = {
      page,
      pageSize,
    };

    if (startDate && endDate) {
      params.startDate = `${startDate} 00:00:00`;
      params.endDate = `${endDate} 23:59:59`;
    }

    if (gender) params.gender = gender;
    if (genre) params.genre = genre;
    if (product) params.product = product;
    if (region) params.region = region;
    if (category) params.category = category;
    if (searchQuery) {
      params.searchQuery = searchQuery;
      if (uploadImageUrl) {
        params.s3Key = uploadImageUrl || "";
        params.bucketName = bucketName;
      }
    }
    if (imagePreview) {
      params.s3Key = uploadImageUrl || "";
      params.bucketName = bucketName;
    }
    if (brands.length > 0) params.brands = brands.join(",");
    if (source) params.source = source;
    if (color) params.colors = color.toUpperCase();

    try {
      const response = await getDesignerFocusPosts(params, { signal });
      setTotalPages(
        Math.ceil(response.data.totalCount / response.data.pageSize)
      );
      return response?.posts;
    } catch (error) {
      if (
        (error as Error).name !== "AbortError" &&
        (error as Error).name !== "CanceledError"
      ) {
        toast.error("Error while fetching posts", {
          hideProgressBar: true,
        });
      }
      return [];
    } finally {
      if (!signal.aborted) {
        loadingRef.current = false;
        setLoading(false);
      }
    }
  };

  const loadMorePosts = async (page: number) => {
    const newPosts = await fetchPosts(page, searchQuery);

    if (newPosts?.length === 0 && page === 1) {
      setPosts([]);
    } else {
      setPosts(prevPosts =>
        page === 1 ? [...newPosts] : [...prevPosts, ...newPosts]
      );
    }
  };

  useEffect(() => {
    const initializePosts = async () => {
      setPosts([]);
      setLoading(true);
      setPage(1);
      const initialPosts = await fetchPosts(1, searchQuery);
      setPosts(initialPosts);
    };

    initializePosts();

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [
    startDate,
    endDate,
    gender,
    genre,
    product,
    region,
    brands,
    source,
    color,
    category,
    searchQuery,
    imagePreview,
  ]);

  useEffect(() => {
    const params: GetBrandsParams = {};
    if (gender) params.gender = gender;
    if (genre) params.genre = genre;
    if (source) params.source = source;
    if (region) params.region = region;
    if (product) params.product = product;
    if (category) params.category = category;
    getBrandsData(params).then(res => {
      setBrandsList(res);
    });
  }, [gender, genre, source, region, product, category]);

  useEffect(() => {
    if (brands.length !== 0) {
      setBrands([]);
    }
  }, [gender, genre, source, region, product]);

  useEffect(() => {
    getProductsData();
  }, []);

  const onScrollItems = throttle(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      const isNearBottom =
        Math.abs(scrollHeight - clientHeight - scrollTop) < 2000;

      if (isNearBottom && page < totalPages && !loadingRef.current) {
        loadMorePosts(page + 1);
        setPage(page + 1);
      }
    }
  }, 1000);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    // setSearchQuery(e.target.value);
    // setPage(1);
  };

  const formatDate = (date: Date) => {
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleButtonClick = () => {
    imageRef.current?.click?.();
  };

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setLoadingImage(true);
      const fileName = getFileName(file);
      const key = getKeyForS3DesignerPage(fileName);
      const res = await uploadImage(key, file);
      setLoadingImage(false);
      setImage(`${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`);
      setUploadImageUrl(res.key);
    }
  };

  const handleRemove = () => {
    setIsDeleteOpen(true);
  };

  const base64ToFile = (base64: string, filename: string): File => {
    const byteString = atob(base64.split(",")[1]);

    const mimeTypeMatch = base64.match(/:(.*?);/);
    const mimeType = mimeTypeMatch?.[1] || "image/png";

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    return new File([blob], filename, { type: mimeType });
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    const hasImage = Array.from(event.clipboardData.items).some(item =>
      item.type.includes("image")
    );

    if (!hasImage) return;
    setLoadingImage(true);

    const items = Array.from(event.clipboardData.items);

    for (const item of items) {
      if (item?.type?.includes("image")) {
        const file = item.getAsFile();

        if (file) {
          const reader = new FileReader();

          try {
            const base64String = await new Promise<string>(
              (resolve, reject) => {
                reader.onload = event => {
                  if (event.target && event.target.result) {
                    resolve(event.target.result as string);
                  } else {
                    reject("Failed to read the file");
                  }
                };
                reader.onerror = () => reject("Error reading the file");
                reader.readAsDataURL(file);
              }
            );

            const imageFile = base64ToFile(base64String, "pasted-image.png");

            if (imageFile) {
              const fileName = getFileName(file);
              const key = getKeyForS3DesignerPage(fileName);

              const res = await uploadImage(key, imageFile);
              setImage(`${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`);
              setUploadImageUrl(res.key);
            }
          } catch (error) {
            console.error("Image upload failed:", error);
          }

          setLoadingImage(false);
          break;
        }
      }
    }
  };
  return (
    <>
      <div
        className={`flex flex-col h-[calc(100vh-80px)] ${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} fixed ml-1 ${isBrandsModalOpen ? "z-10" : ""}`}
      >
        <div className="flex flex-1 overflow-hidden">
          <Preferences
            isCollapsed={sidebarOpen}
            onToggle={handleSidebarToggle}
            setGenre={setGenre}
            genre={genre}
            setProduct={setProduct}
            product={product}
            setGender={setGender}
            gender={gender}
            setBrands={setBrands}
            brands={brands}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setRegion={setRegion}
            region={region}
            genres={genres}
            regions={regions}
            sources={sources}
            source={source}
            setSource={setSource}
            brandsList={brandsList}
            productList={productList}
            color={color}
            setColor={setColor}
            categories={categories}
            category={category}
            setCategory={setCategory}
            isBrandsModalOpen={isBrandsModalOpen}
            setBrandsModalOpen={setBrandsModalOpen}
            closeBrandsModal={closeBrandsModal}
          />
          <div className="p-4 flex-1 bg-lightGray">
            <div className="flex flex-col md:flex-row justify-around items-center mb-2">
              <div className="flex bg-white w-full items-center border border-black text-center justify-center rounded-lg p-1">
                <Search
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    setSearchQuery(searchString);
                    if (uploadImageUrl && image) {
                      setImagePreview(true);
                    } else {
                      setImagePreview(false);
                    }
                  }}
                />
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full pl-3 text-md outline-none"
                  value={searchString}
                  onChange={handleSearchChange}
                  onPaste={handlePaste}
                />
                {!image ? (
                  <>
                    {loadingImage ? (
                      <>
                        <div className="flex justify-center items-center h-full">
                          <Oval
                            height={10}
                            width={10}
                            color="black"
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="black"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={handleButtonClick}
                        className="cursor-pointer"
                      >
                        <CameraIcon />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="relative">
                    <div
                      className="absolute top-[-3px] right-[-3px] bg-white w-[10px] h-[10px] rounded-xl cursor-pointer flex justify-center items-center"
                      onClick={handleRemove}
                    >
                      <CrossIcon width="6px" height="6px" />
                    </div>
                    <div>
                      <img
                        src={image || ""}
                        alt="Uploaded Preview"
                        className="object-cover w-[40px] h-[25px] rounded"
                      />
                    </div>
                  </div>
                )}
                <input
                  ref={imageRef}
                  hidden
                  multiple
                  id="accept-photo"
                  accept=".jpg, .jpeg, .png , .gif, .svg"
                  type="file"
                  onChange={handleImageUpload}
                />
              </div>
            </div>
            {/* Please Make sure to reduce the height from (100vh-140) to (100vh-200) below the commented component */}
            {/* <div className="w-full h-10 bg-white mb-2 rounded-lg flex justify-between items-center">
              <div className="flex">
                <button
                  className="px-3 font-bold text-xs disabled:text-gray-400"
                  disabled={true}
                >
                  Check Archive
                </button>
                <button className="px-3 font-bold text-xs disabled:text-gray-400">
                  Take Snapshot
                </button>
              </div>
              <button className="px-3 font-bold text-xs disabled:text-gray-400">
                Select All Trends
              </button>
            </div> */}
            <div
              className="h-[calc(100vh-140px)] overflow-auto bg-white p-2.5 rounded-lg"
              ref={scrollRef}
              onScroll={onScrollItems}
            >
              {loading && (
                <div className="flex flex-wrap justify-around space-x-4">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <div
                      key={index}
                      className="w-[240px] mt-4 p-0 pb-3 gap-2 border border-light rounded-2xl relative cursor-pointer"
                    >
                      <div className="relative w-full h-auto">
                        <Skeleton
                          height={200}
                          width={240}
                          className="rounded-t-2xl"
                        />
                        <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                          <Skeleton width={50} />
                        </div>
                        <div className="absolute bottom-2 right-2 flex space-x-1">
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                        </div>
                      </div>
                      <div className="p-2 space-y-0.5">
                        <Skeleton width={100} />
                        <Skeleton width={150} />
                        <Skeleton width={120} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {posts?.length === 0 && loading === false ? (
                <div className="text-center text-gray-500">No data found</div>
              ) : (
                <div className="flex flex-wrap justify-evenly gap-4">
                  {posts.map(item => {
                    return (
                      <div
                        key={item.postId}
                        className={`${trendsOpen ? "w-[158px]" : "w-[240px]"} ${trendsOpen ? "h-[260px]" : "h-[390px]"} p-0 pb-3 gap-2 border border-light rounded-2xl relative `}
                      >
                        <div
                          className={`relative w-full ${trendsOpen ? "h-[190px]" : "h-[300px]"}  hover:transition-all duration-100`}
                        >
                          <div
                            className={`relative w-full ${trendsOpen ? "h-[190px]" : "h-[300px]"}`}
                          >
                            <img
                              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}${item.image_link.substring(item.image_link.indexOf(".com/") + 5)}`}
                              alt={`Image ${item.postId}`}
                              className="w-full h-full object-cover rounded-t-2xl"
                            />
                          </div>
                          <div
                            className={`absolute top-0 right-0 bg-translucent text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl ${trendsOpen ? "px-2 py-1" : "px-3 py-1"} ${trendsOpen ? "text-[10px]" : "text-xs"}`}
                          >
                            IG/{item.handle}
                          </div>
                          <div className="absolute bottom-2 right-2 flex space-x-1">
                            <div
                              className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                              onClick={() => {
                                window.open(`${item?.permalink}`, "_blank");
                              }}
                            >
                              <ReturnArrow />
                            </div>
                            <div
                              className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                              onClick={() =>
                                // toast.success("This feature is under development it will be available soon...")
                                handleOpenSaveToBoard(
                                  `${process.env.REACT_APP_CLOUD_FRONT_URL}${item.image_link.substring(item.image_link.indexOf(".com/") + 5)}`,
                                  item
                                )
                              }
                            >
                              <HeartIcon />
                            </div>
                            <div
                              className="bg-white flex w-5 h-5 shadow-2xl shadow-gray-900 items-center justify-center rounded-full cursor-pointer"
                              onClick={async () =>
                                await openDownloadImage(
                                  `${process.env.REACT_APP_CLOUD_FRONT_URL}${item.image_link.substring(item.image_link.indexOf(".com/") + 5)}`,
                                  item?.handle
                                )
                              }
                            >
                              <Download />
                            </div>
                            <div
                              className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                              onClick={() =>
                                handleCopyImageToClipboard(
                                  `${process.env.REACT_APP_CLOUD_FRONT_URL}${item.image_link.substring(item.image_link.indexOf(".com/") + 5)}`,
                                  item?.postId.toString()
                                )
                              }
                            >
                              <CopyToClipboard />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`p-2 space-y-0.5 ${trendsOpen ? "leading-3" : ""}`}
                        >
                          <div
                            className={`${trendsOpen ? "text-[10px]" : "text-xs"} mt-1 text-primary font-medium truncate`}
                          >
                            Brand: {item.brand.brandName}
                          </div>
                          <div
                            className={`${trendsOpen ? "text-[10px]" : "text-xs"} text-primary`}
                          >
                            Date: {formatDate(new Date(item.postDate))}
                          </div>
                          <div
                            className={`flex justify-between items-center ${trendsOpen ? "text-[10px]" : "text-xs"} text-tertiary`}
                          >
                            <span
                              className={`${trendsOpen ? "line-clamp-1" : "line-clamp-2"}`}
                            >
                              {item.description}
                            </span>
                            <button
                              className={`${trendsOpen ? "mb-0" : "mb-[-9px]"} mr-2`}
                              onClick={() => openExpandView(item)}
                            >
                              <ExpandSvg className="w-2.5 h-2.5" />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* <TrendsSidebar
            isTrendOpen={trendsOpen}
            onTrendToggle={handleTrendsToggle}
          /> */}
        </div>
      </div>
      <Modal
        isOpen={isExpandViewOpen}
        onClose={closeExpandView}
        title="Expanded View"
      >
        {selectedPost && (
          <ExpandView
            imageUrl={selectedPost.imageUrl}
            brandName={selectedPost.brandName}
            description={selectedPost.description}
            source={selectedPost.source}
            openSaveToBoard={openSaveToBoard}
            openDownloadImage={openDownloadImage}
            postId={selectedPost.postId}
            permalink={selectedPost.permalink}
            liveSince={selectedPost.liveSince}
          />
        )}
      </Modal>
      <Modal
        isOpen={isDownloadImageOpen}
        onClose={closeDownloadImage}
        title="Download Image"
      >
        {selectedImage && (
          <DownloadImage
            imageUrl={selectedImage}
            source={imageHandle}
            brandName={selectedPost?.brandName}
          />
        )}
      </Modal>
      <Modal
        isOpen={isSaveToBoardOpen}
        onClose={closeSaveToBoard}
        title="Save to Board"
      >
        {saveToBoardImage && (
          <SaveToBoard
            imageUrl={saveToBoardImage}
            selectedPost={selectedPost}
            publicBoards={publicBoards}
            setPublicBoards={setPublicBoards}
            privateBoards={privateBoards}
            setPrivateBoards={setPrivateBoards}
            onClose={closeSaveToBoard}
            userId={userId}
          />
        )}
      </Modal>

      <CustomizedModal
        isOpen={isDeleteOpen}
        onClose={closeDeleteModal}
        title="Are you sure ?"
        width="400px"
      >
        <div className="flex justify-between space-x-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-tertiary font-bold border rounded-full"
            onClick={closeDeleteModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-black text-white font-bold rounded-full"
            onClick={() => {
              setImage("");
              setUploadImageUrl("");
              closeDeleteModal();
              setImagePreview(false);
            }}
          >
            Confirm
          </button>
        </div>
      </CustomizedModal>
    </>
  );
};

export default DesignerPage;
