import React, { useState, useRef, useEffect, useContext } from "react";
import Keyevents from "../../components/Feed/Keyevents";
import { ReactComponent as Search } from "../../assets/icons/searchIcon.svg";
import { ReactComponent as ExpandCard } from "../../assets/icons/expandIconDVF.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import ExpandView from "./newsExpandedView";
import Dropdown from "../../components/Common/Dropdown3";
import Modal from "../../components/Common/Modal";
import { SidebarContext } from "../../layouts/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { copyToClipboard } from "./utils";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Cookies from "js-cookie";
import SaveToBoard from "../../components/Feed/saveToBoard";
import NewsModal from "../../components/Feed/modal";
import moment from "moment";
import { ReactComponent as DropdownArrow } from "../../assets/icons/dropdownArrow.svg";
import Calendar from "../../components/Common/Calendar";
import { InnovationItem } from "../../interface/InnovationInterface";
import {
  getInnovationData,
  getInnovationDropdownData,
  searchByValue,
} from "../../app/api/innovationFeed";
import { Board } from "../../interface/BoardsInterface";
import { getUserId } from "../../app/api/user/user";
import { getBoardsByUserId } from "../../app/api/admin/board";
import { useDebounce } from "../../app/hooks/targetHook";
import InnovationSaveToBoard from "./InnovationSavetoBoard";
import Skeleton from "react-loading-skeleton";
import Masonry from "react-masonry-css";
import { formatDate } from "../../utils";

const breakpointColumnsObj = {
  default: 4,
  1300: 3,
  1000: 2,
};

interface SessionData {
  sessionStartDate: string;
  sessionEndDate: string;
  sessionGenre: string;
}

const InnovationFeed: React.FC = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const [selectedGenre, setSelectedGenre] = useState<string>("None");
  const [isCalendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [isExpandViewOpen, setIsExpandedViewOpen] = useState<boolean>(false);
  const [selectedNews, setSelectedNews] = useState<InnovationItem | null>(null);
  const [innovationItems, setInnovationItems] = useState<InnovationItem[]>([]);
  const loadingRef = useRef<boolean>(false);
  const hasMoreRef = useRef<boolean>(true);
  const isSidebarOpen = useContext(SidebarContext);
  const [dropdownData, setDropdownData] = useState([]);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const isAuthenticated = Cookies.get("isAuthenticated");
  const closeSaveToBoard = () => setSaveToBoardOpen(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isStartCalendarOpen, setStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setEndCalendarOpen] = useState(false);
  const dateButtonsWrapperRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isSaveToBoardOpen, setSaveToBoardOpen] = useState<boolean>(false);
  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);
  const [sessionLoadingCompleted, setSessionLoadingCompleted] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const toggleCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const closeCalendar = () => {
    setCalendarOpen(false);
  };

  const handleSidebarToggle = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const debounceSearchQuery = useDebounce(searchQuery, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handleSelectGenre = (option: React.SetStateAction<string>) => {
    setSelectedGenre(option);
  };

  const getRandomClampClass = () => {
    const randomClamp = Math.floor(Math.random() * 3) + 2;
    return `line-clamp-${randomClamp}`;
  };

  const handleStartDateChange = (value: string) => {
    const selectedStartDate = moment(value, "YYYY-MM-DD");
    const selectedEndDate = moment(endDate, "DD/MM/YYYY");
    if (selectedEndDate.isBefore(selectedStartDate)) {
      toast.error("End date cannot be earlier than the start date.", {
        hideProgressBar: true,
      });
      return;
    }
    setStartDate(selectedStartDate.format("DD/MM/YYYY"));
    setStartCalendarOpen(false);
  };

  const handleEndDateChange = (value: string) => {
    const selectedEndDate = moment(value, "YYYY-MM-DD");
    const selectedStartDate = moment(startDate, "DD/MM/YYYY");
    if (selectedEndDate.isBefore(selectedStartDate)) {
      toast.error("End date cannot be earlier than the start date.", {
        hideProgressBar: true,
      });
      return;
    }
    setEndDate(selectedEndDate.format("DD/MM/YYYY"));
    setEndCalendarOpen(false);
  };

  const handleExpandedViewOpen = (news: InnovationItem) => {
    setSelectedNews(news);
    setIsExpandedViewOpen(true);
  };

  const closeExpandView = () => setIsExpandedViewOpen(false);

  const fetchNews = async (pageNumber: number) => {
    if (debounceSearchQuery.trim().length > 0) return;
    if (!hasMoreRef.current) return;
    if (loadingRef.current) return;
    loadingRef.current = true;
    if (pageNumber === 1) setInnovationItems([]);
    setLoading(true);
    try {
      let response: { data: InnovationItem[] };
      if (selectedGenre === "None") {
        if (startDate && endDate) {
          response = await getInnovationData(
            {
              startDate,
              endDate,
              page: pageNumber,
              pageSize: 20,
            },
            isAuthenticated,
            user
          );
        } else {
          response = await getInnovationData(
            {
              page: pageNumber,
              pageSize: 20,
            },
            isAuthenticated,
            user
          );
        }
      } else {
        if (startDate && endDate) {
          response = await getInnovationData(
            {
              startDate,
              endDate,
              page: pageNumber,
              pageSize: 20,
              category: selectedGenre,
            },
            isAuthenticated,
            user
          );
        } else {
          response = await getInnovationData(
            {
              page: pageNumber,
              pageSize: 20,
              category: selectedGenre,
            },
            isAuthenticated,
            user
          );
        }
      }
      if (response.data.length === 0) {
        hasMoreRef.current = false;
      } else {
        setInnovationItems(prevItems => [...prevItems, ...response.data]);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  };

  const setSearchData = async () => {
    if (startDate && endDate) {
      const response = await searchByValue(
        { search: debounceSearchQuery, startDate, endDate },
        isAuthenticated,
        user
      );
      setInnovationItems(response.data);
    } else {
      const response = await searchByValue(
        { search: debounceSearchQuery },
        isAuthenticated,
        user
      );
      setInnovationItems(response.data);
    }
  };

  useEffect(() => {
    const handleSearchResult = async () => {
      if (debounceSearchQuery.trim()) {
        await setSearchData();
      } else if (sessionLoadingCompleted && startDate && endDate) {
        fetchNews(1);
        setPage(1);
      }
    };
    handleSearchResult();
  }, [startDate, endDate]);

  useEffect(() => {
    const handleSearchResult = async () => {
      if (debounceSearchQuery.trim()) {
        await setSearchData();
      } else if (sessionLoadingCompleted) {
        fetchNews(1);
        setPage(1);
      }
    };
    handleSearchResult();
  }, [debounceSearchQuery, isAuthenticated, user]);

  const getSessionData = (): SessionData | null => {
    const data = sessionStorage.getItem("sessionData");
    if (data) {
      return JSON.parse(data) as SessionData;
    }
    return null;
  };

  useEffect(() => {
    const sessionData = getSessionData();
    searchInputRef.current?.focus();

    if (sessionData !== null) {
      const { sessionStartDate, sessionEndDate, sessionGenre } = sessionData;

      setStartDate(sessionStartDate);
      setEndDate(sessionEndDate);
      setSelectedGenre(sessionGenre);
    }
    setSessionLoadingCompleted(true);
    const fetchData = async () => {
      const response = await getInnovationDropdownData(isAuthenticated, user);
      setDropdownData(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (sessionLoadingCompleted) {
      fetchNews(1);
      setPage(1);
    }
  }, [sessionLoadingCompleted]);

  useEffect(() => {
    const sessionData = getSessionData();
    if (
      !(
        (sessionData !== null &&
          sessionData.sessionStartDate === startDate &&
          sessionData.sessionEndDate === endDate) ||
        !(startDate && endDate)
      ) &&
      sessionLoadingCompleted
    ) {
      hasMoreRef.current = true;
      setPage(1);
      fetchNews(1);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const sessionData = getSessionData();
    if (
      sessionLoadingCompleted &&
      (sessionData === null || sessionData.sessionGenre !== selectedGenre)
    ) {
      hasMoreRef.current = true;
      fetchNews(1);
      setPage(1);
    }
  }, [selectedGenre]);

  useEffect(() => {
    if (sessionLoadingCompleted) {
      saveSessionData();
    }
  }, [selectedGenre, startDate, endDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dateButtonsWrapperRef.current &&
        !dateButtonsWrapperRef.current.contains(event.target as Node)
      ) {
        setStartCalendarOpen(false);
        setEndCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = () => {
    const scrollerDiv = scrollerRef.current;
    if (scrollerDiv) {
      if (
        scrollerDiv.scrollTop + scrollerDiv.clientHeight >=
        scrollerDiv.scrollHeight - 5
      ) {
        if (!loadingRef.current && hasMoreRef.current) {
          fetchNews(page + 1);
          setPage(prevPage => prevPage + 1);
        }
      }
    }
  };

  const saveSessionData = () => {
    const sessionData: SessionData = {
      sessionStartDate: startDate,
      sessionEndDate: endDate,
      sessionGenre: selectedGenre,
    };
    sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
  };

  //Calculating the width of the main center div
  const calculateWidth = (): string => {
    if (isSidebarOpen.isSidebarOpen && !isSidebarCollapsed) {
      return "calc(100vw - 24rem )";
    }
    if (isSidebarOpen.isSidebarOpen && isSidebarCollapsed) {
      return "calc(100vw - 10rem)";
    }
    if (!isSidebarOpen.isSidebarOpen && isSidebarCollapsed) {
      return "calc(100vw - 21rem)";
    }
    return "calc(100vw - 35rem)";
  };

  //Function to copy text to clipboard
  const handleCopyToClipboard = async (index: number) => {
    const newsToBeCopied = innovationItems[index];
    const response = await copyToClipboard({
      title: newsToBeCopied.title,
      description: newsToBeCopied.description,
      url: newsToBeCopied.url,
    });
    if (response == "success") {
      toast.success("Text Copied", {
        hideProgressBar: true,
      });
    } else if (response == "error") {
      toast.error("Error Copying Text", {
        hideProgressBar: true,
      });
    }
  };

  const handleImageError = async (
    source: string,
    url: string,
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ): Promise<void> => {
    //Check if favicon available or not
    const fetchFavicon = (): Promise<boolean> => {
      return new Promise(resolve => {
        const img = new Image();
        img.src = `${new URL(url).origin}/favicon.ico`;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };
    const isFaviconAvailable = await fetchFavicon();
    if (isFaviconAvailable) {
      (e.target as HTMLImageElement).src = `${new URL(url).origin}/favicon.ico`;
      return;
    }

    //If favicon not available
    const firstWord = source.split(" ")[0];
    const canvas = document.createElement("canvas");
    canvas.width = 70;
    canvas.height = 70;
    const context = canvas.getContext("2d");
    if (context) {
      context.fillStyle = "#000000";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#FFFFFF";
      context.font = "bold 20px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(firstWord, canvas.width / 2, canvas.height / 2);
      const imageUrl = canvas.toDataURL();
      (e.target as HTMLImageElement).src = imageUrl;
    }
  };

  // on scroll it will fetch news of next page
  useEffect(() => {
    const scrollerDiv = scrollerRef.current as HTMLDivElement;
    if (scrollerDiv) {
      scrollerDiv.addEventListener("scroll", handleScroll);
    }
    return () => scrollerDiv.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleSaveToBoard = (news: InnovationItem) => {
    setSaveToBoardOpen(true);
    setSelectedNews(news);
  };

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res?.data?.data[0]._id);
    }
  };

  const getBoardsByUser = async () => {
    if (userId) {
      const res = await getBoardsByUserId(userId);
      setPublicBoards(res?.data?.data.public.boards);
      setPrivateBoards(res?.data?.data.private.boards);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoardsByUser();
  }, [userId]);

  const handleClearDates = () => {
    hasMoreRef.current = true;
    setStartDate("");
    setEndDate("");
    setSelectedGenre("None");
    setSearchQuery("");
    setClearAll(true);
  };

  useEffect(() => {
    if (clearAll) {
      fetchNews(1);
      setPage(1);
      setClearAll(false);
    }
  }, [clearAll]);

  return (
    <>
      <div
        className={`flex fixed ml-4 h-[calc(100vh-95px)] ${isExpandViewOpen || isSaveToBoardOpen ? "z-10" : ""}`}
      >
        <div
          className={`flex flex-col items-center bg-white min-h-[75vh] w-full`}
          style={{ width: calculateWidth() }}
        >
          <div className="flex flex-col mb-2 w-full mt-2">
            <div className="flex bg-white w-[97%] m-auto items-center border border-black text-center justify-center rounded-lg p-2">
              <Search className="w-6 h-6" />
              <input
                type="text"
                placeholder="Search"
                className="w-full pl-3 text-md outline-none"
                value={searchQuery}
                onChange={handleSearchChange}
                ref={searchInputRef}
              />
            </div>
          </div>
          <div className="flex justify-between items-center w-[97%]">
            <div className="flex justify-start space-x-16">
              <div>
                <span className="font-poppins text-[12px] text-secondary">
                  Select Genre
                </span>
                <Dropdown
                  label={
                    selectedGenre === "None"
                      ? "Select Genre"
                      : selectedGenre === "ScienceAndTechnology"
                        ? "Science And Technology"
                        : selectedGenre
                  }
                  options={["None", ...dropdownData]}
                  onSelect={handleSelectGenre}
                  minWidth={175}
                  scroller={true}
                />
              </div>
            </div>
            <div className="flex justify-between flex-col relative mb-1">
              <div className="flex justify-between">
                <p className="font-poppins text-[12px] text-secondary">
                  Date Range
                </p>
                {(startDate || endDate || selectedGenre !== "None") && (
                  <p
                    className="font-poppins text-[12px]  mr-1 text-red-700 cursor-pointer"
                    onClick={handleClearDates}
                  >
                    Clear All
                  </p>
                )}
              </div>
              <div className="mt-[5px]">
                <div ref={dateButtonsWrapperRef} className="relative flex">
                  <div className="w-full relative mr-1">
                    <div className="flex w-full justify-between items-center pl-3 pr-2 py-2 text-left text-base border border-default rounded-lg">
                      <button
                        className="w-full items-center justify-between flex text-secondary text-xs rounded"
                        onClick={() => {
                          setStartCalendarOpen(!isStartCalendarOpen);
                          if (isEndCalendarOpen) setEndCalendarOpen(false);
                        }}
                      >
                        <div className="w-full font-poppins text-[12px] text-[#4d4d4d]">
                          {startDate || "Start Date"}
                        </div>
                        <DropdownArrow className="w-2 h-2 ml-1" />
                      </button>
                    </div>
                    {isStartCalendarOpen && (
                      <div
                        className="absolute z-50"
                        style={{
                          top: "100%",
                          left: "55%",
                          transform: "translateX(-50%)",
                          marginTop: "5px",
                        }}
                      >
                        {isStartCalendarOpen && (
                          <Calendar onChange={handleStartDateChange} />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="w-full relative">
                    <div className="flex w-full justify-between items-center pl-3 pr-2 py-2 text-left text-base border border-default rounded-lg">
                      <button
                        className="w-full flex  items-center justify-between text-secondary text-xs rounded"
                        onClick={() => {
                          setEndCalendarOpen(!isEndCalendarOpen);
                          if (isStartCalendarOpen) setStartCalendarOpen(false);
                        }}
                      >
                        <div className="w-full font-poppins text-[12px] text-[#4d4d4d]">
                          {endDate || "End Date"}
                        </div>
                        <DropdownArrow className="w-2 h-2 ml-1 mr-1" />
                      </button>
                    </div>
                    {isEndCalendarOpen && (
                      <div
                        className="absolute z-50"
                        style={{
                          top: "100%",
                          left: "55%",
                          transform: "translateX(-50%)",
                          marginTop: "5px",
                        }}
                      >
                        {isEndCalendarOpen && (
                          <Calendar onChange={handleEndDateChange} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="mt-2 p-4 bg-white w-full overflow-y-auto scroller h-[calc(100vh-200px)]"
            ref={scrollerRef}
          >
            {/* Innovation Data Rendering */}
            {loading && (
              <div className="flex flex-wrap justify-between">
                {Array.from({ length: 12 }).map((_, index) => (
                  <div
                    key={index}
                    className="max-w-[240px] p-0 pb-5 gap-2 border border-light rounded-2xl relative shadow-sm shadow-gray-300 my-4"
                  >
                    <div className="relative w-full h-auto">
                      <Skeleton
                        height={200}
                        width={240}
                        className="rounded-t-2xl"
                      />
                      <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                        <Skeleton width={50} />
                      </div>
                      <div className="absolute bottom-2 right-2 flex space-x-1">
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                      </div>
                    </div>
                    <div className="p-2 space-y-0.5">
                      <Skeleton width={100} />
                      <Skeleton width={150} />
                      <Skeleton width={120} />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {innovationItems.length > 0 ? (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="flex w-auto space-x-2"
                columnClassName="bg-clip-padding"
              >
                {innovationItems.map((item: InnovationItem, index: number) => {
                  return (
                    <div
                      key={index}
                      className="max-w-[100%] p-0 pb-5 gap-2 border border-light rounded-2xl relative shadow-sm shadow-gray-300 my-4"
                    >
                      <div className="flex justify-between items-center bg-white rounded-2xl">
                        <div className=" p-2 pl-2 pr-2 pt-2">
                          <div className="font-poppins text-[12px] text-left pl-2">
                            {item.source === "Bing News"
                              ? item.provider && item.provider.length > 0
                                ? item.provider[0]
                                : "Bing News"
                              : item.source}
                          </div>
                          <div className="font-poppins text-[10px] pl-2">
                            {formatDate(item.datePublished!)}
                          </div>
                        </div>
                        <div>
                          <img
                            src={item.image}
                            alt="something"
                            className="w-[70px] h-[70px] rounded-tr-2xl object-content"
                            onError={e =>
                              handleImageError(item.source, item.url, e)
                            }
                          />
                        </div>
                      </div>
                      <div className="px-2 flex-grow">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`underline line-clamp-3 text-primary font-bold`}
                        >
                          {item.title}
                        </a>
                        <p
                          className={`text-[12px] overflow-hidden font-poppins line-clamp-8 text-gray-400 mb-1`}
                        >
                          {item.description}
                        </p>
                      </div>
                      <div className="flex space-x-1 absolute right-2 bottom-2">
                        <div
                          className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                          onClick={() => handleSaveToBoard(item)}
                        >
                          <HeartIcon />
                        </div>
                        <div
                          className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                          onClick={() => handleCopyToClipboard(index)}
                        >
                          <CopyToClipboard />
                        </div>
                        <div
                          className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                          onClick={() => handleExpandedViewOpen(item)}
                        >
                          <ExpandCard />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            ) : (
              !loading && (
                <div className="w-full text-center py-8">
                  <p className="text-lg font-poppins text-gray-500 ">
                    No data found
                  </p>
                </div>
              )
            )}
          </div>
        </div>

        {/* Sidebar Component */}
        <Keyevents
          isCollapsed={isSidebarCollapsed}
          onToggle={handleSidebarToggle}
        />
        <Modal
          isOpen={isExpandViewOpen}
          onClose={closeExpandView}
          title="Expanded View"
        >
          <div className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer absolute right-0 top-0">
            <Close />
          </div>
          {selectedNews && (
            <ExpandView
              url={selectedNews.url}
              image={selectedNews.image}
              description={selectedNews.description}
              title={selectedNews.title}
              source={selectedNews.source}
              date={selectedNews.datePublished}
              provider={selectedNews.provider}
              handleImageError={handleImageError}
              isSaveToBoardOpen={isSaveToBoardOpen}
              setSaveToBoardOpen={setSaveToBoardOpen}
              closeSaveToBoard={closeSaveToBoard}
            />
          )}
        </Modal>
        <NewsModal
          isOpen={isSaveToBoardOpen}
          onClose={closeSaveToBoard}
          title="Save to Board"
        >
          {
            <InnovationSaveToBoard
              selectedNews={selectedNews!}
              onClose={closeSaveToBoard}
              userId={userId}
              handleImageError={handleImageError}
              publicBoards={publicBoards || []}
              setPublicBoards={setPublicBoards}
              privateBoards={privateBoards || []}
              setPrivateBoards={setPrivateBoards}
            />
          }
        </NewsModal>
      </div>
    </>
  );
};

export default InnovationFeed;
