import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  ChangeEvent,
} from "react";
import Keyevents from "../../components/Feed/Keyevents";
import { ReactComponent as Search } from "../../assets/icons/searchIcon.svg";
import { ReactComponent as LeftPagination } from "../../assets/icons/paginationLeftDVF.svg";
import { ReactComponent as RightPagination } from "../../assets/icons/paginationRightDVF.svg";
import { ReactComponent as ExpandCard } from "../../assets/icons/expandIconDVF.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import Dropdown from "../../components/Common/Dropdown3";
import Calendar from "./calendarDailyViralFeed";
import Modal from "../../components/Common/Modal";
import {
  getDailyNews,
  getDropdownData,
  searchByValue,
} from "../../app/api/viralFeed";
import { SidebarContext } from "../../layouts/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { copyToClipboard } from "./utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../app/store";
import Cookies from "js-cookie";
import SaveToBoard from "../../components/Feed/saveToBoard";
import { NewsItem } from "../../interface/NewsInterface";
import NewsModal from "../../components/Feed/modal";
import { getUserId } from "../../app/api/user/user";
import { getBoardsByUserId } from "../../app/api/admin/board";
import { Board } from "../../interface/BoardsInterface";
import DailyExpandView from "./newsExpandedView";
import { formatDate } from "../../utils";
import { useDebounce } from "../../app/hooks/targetHook";
import { throttle } from "lodash";
import { DateColumnStatisticsData } from "aws-sdk/clients/glue";
import Skeleton from "react-loading-skeleton";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 4,
  1300: 3,
  1000: 2,
};

interface NewsItemsData {
  newsItems: NewsItem[];
  pageNumber: number;
}

interface NewsState {
  newsItemsArray: {
    [date: string]: NewsItemsData;
  };
  loading: boolean;
  error: string | null;
  genre: string | null;
}

const DailyViralFeed: React.FC = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedGenre, setSelectedGenre] = useState<string>("None");
  const [isCalendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [isExpandViewOpen, setIsExpandedViewOpen] = useState<boolean>(false);
  const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [visibleDate, setVisibleDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const isSidebarOpen = useContext(SidebarContext);
  const [dropdownData, setDropdownData] = useState([]);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const isAuthenticated = Cookies.get("isAuthenticated");
  const [isSaveToBoardOpen, setSaveToBoardOpen] = useState<boolean>(false);
  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);
  const isScrolledToBottomRef = useRef<boolean>(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [newsItemsArray, setNewsItemsArray] = useState<{
    [date: string]: NewsItem[];
  }>({});
  const [searchedNews, setSearchedNews] = useState<NewsItem[] | undefined>(
    undefined
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [isCalenderButtonClicked, setIsCalenderButtonClicked] = useState(false);
  const dateRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const debounceSearchQuery = useDebounce(searchQuery, 500);
  const closeSaveToBoard = () => {
    setSaveToBoardOpen(false);
  };

  const toggleCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const closeCalendar = () => {
    setCalendarOpen(false);
  };

  const handleSidebarToggle = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  useEffect(() => {
    const handleSearchResult = async () => {
      if (debounceSearchQuery.trim().length !== 0) {
        closeCalendar();
        setIsCalenderButtonClicked(true);
        setNewsItemsArray({});
        setPageNumber(1);
        const today = new Date().toISOString().split("T")[0];
        handleDateChange(today);
        handleVisibleDateChange(today);
        let response: { data: NewsItem[] };
        setLoading(true);
        if (selectedGenre === "None") {
          response = await searchByValue(
            { search: debounceSearchQuery },
            isAuthenticated,
            user
          );
        } else {
          response = await searchByValue(
            { search: debounceSearchQuery, category: selectedGenre },
            isAuthenticated,
            user
          );
        }
        if (response.data?.length > 0) {
          setSearchedNews(response.data);
        } else {
          setSearchedNews(undefined);
        }
        setLoading(false);
      } else {
        setSearchedNews(undefined);
      }
    };
    handleSearchResult();
  }, [debounceSearchQuery, selectedGenre, isAuthenticated, user]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectGenre = (option: string) => {
    if (option !== selectedGenre) {
      setIsCalenderButtonClicked(true);
      setNewsItemsArray({});
      setPageNumber(1);
      handleDateChange(visibleDate);
      setSelectedGenre(option);
    }
  };
  const handleDateChangeForCalenderSelection = (value: string) => {
    setIsCalenderButtonClicked(true);
    setNewsItemsArray({});
    setPageNumber(1);
    handleDateChange(value);
    handleVisibleDateChange(value);
  };

  const formatVisibleDate = () => {
    const date = new Date(visibleDate);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month}`;
  };

  const handleVisibleDateChange = (value: string | number) => {
    const formattedDate = new Date(value).toISOString().split("T")[0];
    setVisibleDate(formattedDate);
  };

  const handleDateChange = (value: string) => {
    const formattedDate = new Date(value).toISOString().split("T")[0];
    setSelectedDate(formattedDate);
    closeCalendar();
  };

  const handleExpandedViewOpen = (news: NewsItem) => {
    setSelectedNews(news);
    setIsExpandedViewOpen(true);
  };

  const closeExpandView = () => setIsExpandedViewOpen(false);

  const fetchNews = async () => {
    if (!selectedDate) return;
    if (searchQuery.length !== 0 || (searchedNews && searchedNews.length > 0))
      return;
    setLoading(true);
    try {
      let response: { data: NewsItem[] };

      if (selectedGenre === "None") {
        response = await getDailyNews(
          { date: selectedDate, page: pageNumber, pageSize: 20 },
          isAuthenticated,
          user
        );
      } else {
        response = await getDailyNews(
          {
            date: selectedDate,
            page: pageNumber,
            pageSize: 20,
            category: selectedGenre,
          },
          isAuthenticated,
          user
        );
      }

      if (response.data === null) {
        if (!Object.keys(newsItemsArray).includes(selectedDate)) {
          appendNewsItems(selectedDate, [], 1);
        }
        if (new Date(selectedDate) > new Date("2024-09-30")) {
          const currentDate = new Date(selectedDate);
          const newDate = currentDate.setDate(currentDate.getDate() - 1);
          handleDateChange(new Date(newDate).toISOString().split("T")[0]);
          setPageNumber(1);
        }
      } else {
        appendNewsItems(selectedDate, response.data, pageNumber + 1);
      }
    } catch (error) {
      setError("Error fetching news");
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0 && searchedNews === undefined) {
      fetchNews();
    }
  }, [searchQuery, searchedNews]);

  useEffect(() => {
    searchInputRef.current?.focus();
    const fetchData = async () => {
      const response = await getDropdownData(isAuthenticated, user);
      setDropdownData(response.data);
    };
    fetchData();

    const today = new Date().toISOString().split("T")[0];
    handleDateChange(today);
    handleVisibleDateChange(today);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchNews();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedGenre) {
      fetchNews();
    }
  }, [selectedGenre]);

  const handleScroll = throttle(async () => {
    if (searchedNews === undefined && searchQuery.length === 0) {
      const scrollableDiv = scrollableRef.current;
      if (scrollableDiv) {
        if (
          scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
          scrollableDiv.scrollHeight - 10
        ) {
          if (!loading) {
            isScrolledToBottomRef.current = true;
            await fetchNews();
          }
        } else {
          isScrolledToBottomRef.current = false;
        }
      }
    }
  }, 100);

  //Calculating the width of the main center div
  const calculateWidth = (): string => {
    if (isSidebarOpen.isSidebarOpen && !isSidebarCollapsed) {
      return "calc(100vw - 24rem )";
    }
    if (isSidebarOpen.isSidebarOpen && isSidebarCollapsed) {
      return "calc(100vw - 10rem)";
    }
    if (!isSidebarOpen.isSidebarOpen && isSidebarCollapsed) {
      return "calc(100vw - 21rem)";
    }
    return "calc(100vw - 35rem)";
  };

  const prevDate = async () => {
    if (new Date(visibleDate) > new Date("2024-09-30")) {
      setIsCalenderButtonClicked(true);
      setNewsItemsArray({});
      setPageNumber(1);
      const currentDate = new Date(visibleDate);
      const newDate = currentDate.setDate(currentDate.getDate() - 1);
      handleDateChange(new Date(newDate).toISOString().split("T")[0]);
      handleVisibleDateChange(newDate);
    }
  };

  const nextDate = async () => {
    //check if it`s today date. If yes next date will not traverse
    if (
      new Date(Date.now()).toISOString().split("T")[0] !==
      new Date(visibleDate).toISOString().split("T")[0]
    ) {
      setIsCalenderButtonClicked(true);
      setNewsItemsArray({});
      setPageNumber(1);
      const currentDate = new Date(visibleDate);
      const newDate = currentDate.setDate(currentDate.getDate() + 1);
      handleDateChange(new Date(newDate).toISOString().split("T")[0]);
      handleVisibleDateChange(newDate);
    }
  };

  //Function to copy text to clipboard
  const handleCopyToClipboard = async (index: number, date?: string) => {
    let newsToBeCopied = null;
    if (date) {
      newsToBeCopied = newsItemsArray?.[date]?.[index];
    } else {
      if (searchedNews) {
        newsToBeCopied = searchedNews[index];
      }
    }

    if (!newsToBeCopied) {
      toast.error("News item not found", {
        hideProgressBar: true,
      });
      return;
    }

    const response = await copyToClipboard({
      title: newsToBeCopied.title,
      description: newsToBeCopied.description,
      url: newsToBeCopied.url,
    });

    if (response === "success") {
      toast.success("Text Copied", {
        hideProgressBar: true,
      });
    } else if (response === "error") {
      toast.error("Error Copying Text", {
        hideProgressBar: true,
      });
    }
  };

  const handleImageError = async (
    source: string,
    url: string,
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ): Promise<void> => {
    //Check if favicon available or not
    const fetchFavicon = (): Promise<boolean> => {
      return new Promise(resolve => {
        const img = new Image();
        img.src = `${new URL(url).origin}/favicon.ico`;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };
    const isFaviconAvailable = await fetchFavicon();
    if (isFaviconAvailable) {
      (e.target as HTMLImageElement).src = `${new URL(url).origin}/favicon.ico`;
      return;
    }

    //If favicon not available
    const firstWord = source.split(" ")[0];
    const canvas = document.createElement("canvas");
    canvas.width = 70;
    canvas.height = 70;
    const context = canvas.getContext("2d");
    if (context) {
      context.fillStyle = "#000000";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#FFFFFF";
      context.font = "bold 20px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(firstWord, canvas.width / 2, canvas.height / 2);
      const imageUrl = canvas.toDataURL();
      (e.target as HTMLImageElement).src = imageUrl;
    }
  };

  const handleSaveToBoard = (news: NewsItem) => {
    setSaveToBoardOpen(true);
    setSelectedNews(news);
  };

  // on scroll it will fetch news of next page
  useEffect(() => {
    const scrollableDiv = scrollableRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res?.data?.data[0]._id);
    }
  };

  const getBoardsByUser = async () => {
    if (userId) {
      const res = await getBoardsByUserId(userId);
      setPublicBoards(res?.data?.data.public.boards);
      setPrivateBoards(res?.data?.data.private.boards);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoardsByUser();
  }, [userId]);

  const appendNewsItems = (
    date: string,
    newsItems: NewsItem[],
    newPageNumber?: number
  ) => {
    const currentData = newsItemsArray?.[date] || [];
    setNewsItemsArray(prev => ({
      ...prev,
      [date]: [...currentData, ...newsItems],
    }));
    if (newPageNumber) {
      setPageNumber(newPageNumber);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(undefined);
    }
  }, [error]);

  useEffect(() => {
    const scrollableDiv = scrollableRef.current;
    if (!scrollableDiv) return;
    if (isCalenderButtonClicked === true) {
      scrollableDiv.scrollTop = 0;
      setIsCalenderButtonClicked(false);
    }
  }, [isCalenderButtonClicked]);

  const trackTopDateScroll = () => {
    if (Object.keys(newsItemsArray).length !== 0) {
      let topVisibleDate = new Date(Object.keys(newsItemsArray)[0])
        .toISOString()
        .split("T")[0];
      let closestTopDistance = Infinity;

      const scrollableContainer = scrollableRef.current; // Ensure you have a ref to the scrollable container

      if (scrollableContainer) {
        const containerTopOffset =
          scrollableContainer.getBoundingClientRect().top;

        for (const [date, ref] of Object.entries(dateRefs.current)) {
          if (ref) {
            const elementTop =
              ref.getBoundingClientRect().top - containerTopOffset;
            if (elementTop >= 0 && elementTop < closestTopDistance) {
              closestTopDistance = elementTop;
              topVisibleDate = date;
            }
          }
        }
      }
      handleVisibleDateChange(topVisibleDate);
    }
  };

  // useEffect for the new scroll listener to track the top date
  useEffect(() => {
    const scrollableDiv = scrollableRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", trackTopDateScroll);
    }
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", trackTopDateScroll);
      }
    };
  }, [trackTopDateScroll]);

  return (
    <>
      <div
        className={`flex fixed justify-between ml-4 h-[calc(100vh-80px)] ${isExpandViewOpen || isSaveToBoardOpen ? "z-10" : ""}`}
      >
        <div
          style={{ width: calculateWidth() }}
          className="px-3 calc(100vh-80px)"
        >
          <div className="z-10 flex justify-between items-center flex-col w-full pt-2">
            <div className="flex bg-white w-full m-auto items-center border border-black text-center justify-center rounded-lg p-2">
              <Search className="w-6 h-6" />
              <input
                type="text"
                ref={searchInputRef}
                placeholder="Search"
                className="w-full pl-3 text-md outline-none"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div className="flex justify-between w-full mt-3 items-center">
              <div className="flex justify-start space-x-16">
                <div>
                  <span className="font-poppins text-[12px] text-secondary">
                    Select Genre
                  </span>
                  <Dropdown
                    label={
                      selectedGenre === "None"
                        ? "Select Genre"
                        : selectedGenre === "ScienceAndTechnology"
                          ? "Science And Technology"
                          : selectedGenre
                    }
                    options={["None", ...dropdownData]}
                    onSelect={handleSelectGenre}
                    minWidth={175}
                    scroller={true}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-3  relative">
                <div>
                  <LeftPagination
                    onClick={
                      searchedNews !== undefined || searchQuery.length !== 0
                        ? undefined
                        : prevDate
                    }
                  />
                </div>
                <div className="bg-[#d3c0b6]/20 h-8 text-[0.875rem] text-[#4d4d4d] font-poppins flex items-center justify-center">
                  <button
                    onClick={toggleCalendar}
                    className="px-6"
                    disabled={
                      searchedNews !== undefined || searchQuery.length !== 0
                    }
                  >
                    {formatVisibleDate()}
                  </button>
                  {isCalendarOpen && (
                    <div
                      ref={calendarRef}
                      className="absolute top-10 left-0 z-10"
                    >
                      <Calendar
                        onChange={handleDateChangeForCalenderSelection}
                        closeCalendar={closeCalendar}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <RightPagination
                    onClick={
                      searchedNews !== undefined || searchQuery.length !== 0
                        ? undefined
                        : nextDate
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="overflow-y-scroll scroller bg-white h-[calc(100vh-200px)] flex flex-col pb-2 mt-2"
            ref={scrollableRef}
          >
            <div className="min-h-[calc(100vh-200px)]">
              {/*Search query results*/}
              {loading && Object.keys(newsItemsArray).length === 0 && (
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="flex w-auto space-x-2"
                  columnClassName="bg-clip-padding"
                >
                  {Array.from({ length: 12 }).map((_, index) => (
                    <div
                      key={index}
                      className="max-w-[100%] p-0 pb-5 gap-2 border border-light rounded-2xl relative shadow-sm shadow-gray-300 my-4"
                    >
                      <div className="relative w-full h-auto">
                        <Skeleton
                          height={200}
                          width={240}
                          className="rounded-t-2xl"
                        />
                        <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                          <Skeleton width={50} />
                        </div>
                        <div className="absolute bottom-2 right-2 flex space-x-1">
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                        </div>
                      </div>
                      <div className="p-2 space-y-0.5">
                        <Skeleton width={100} />
                        <Skeleton width={150} />
                        <Skeleton width={120} />
                      </div>
                    </div>
                  ))}
                </Masonry>
              )}
              {searchedNews ? (
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="flex w-auto space-x-2"
                  columnClassName="bg-clip-padding"
                >
                  {searchedNews.map((news: NewsItem, index: number) => {
                    return (
                      <div
                        key={index}
                        className="max-w-[100%] p-2 pb-5 gap-2 border border-light rounded-2xl relative shadow-sm shadow-gray-300"
                      >
                        <div className="flex justify-between bg-white rounded-2xl">
                          <div className="gap-y-2 p-2 pl-2 pr-2 pt-2">
                            <div className="font-poppins text-[12px] flex text-center justify-center">
                              {news.source === "Bing News"
                                ? news.provider
                                  ? news.provider[0]
                                  : "Bing News"
                                : news.source}
                            </div>
                            <div className="font-poppins text-[12px] flex text-center justify-center font-bold">
                              {formatDate(news.datePublished!)}
                            </div>
                          </div>
                          <div>
                            <img
                              src={news.image}
                              alt="something"
                              className="w-[70px] h-[70px] rounded-tr-2xl object-content"
                              onError={e =>
                                handleImageError(news.source, news.url, e)
                              }
                            />
                          </div>
                        </div>
                        <div className="px-2 flex-grow">
                          <a
                            href={news.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`underline line-clamp-3 text-primary font-bold`}
                          >
                            {news.title}
                          </a>
                          <p
                            className={`text-[12px] overflow-hidden font-poppins line-clamp-8 text-gray-400 mb-1`}
                          >
                            {news.description}
                          </p>
                        </div>
                        <div className="flex space-x-1 absolute right-2 bottom-2">
                          <div
                            className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                            onClick={() => handleSaveToBoard(news)}
                          >
                            <HeartIcon />
                          </div>
                          <div
                            className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                            onClick={() => handleCopyToClipboard(index)}
                          >
                            <CopyToClipboard />
                          </div>
                          <div
                            className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                            onClick={() => handleExpandedViewOpen(news)}
                          >
                            <ExpandCard />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Masonry>
              ) : searchQuery.length === 0 ? (
                <></>
              ) : (
                !loading &&
                searchQuery.length > 0 && (
                  <div className="w-full text-center">
                    <p className="text-lg font-poppins text-gray-500 py-8 min-h-[calc(100vh-180px)] ">
                      No data found
                    </p>
                  </div>
                )
              )}
              {/* News Data Rendering */}
              {searchedNews === undefined &&
                searchQuery.length === 0 &&
                newsItemsArray &&
                Object.entries(newsItemsArray).map(
                  ([date, newsItems], index) => (
                    <div key={index}>
                      <div
                        className="bg-lightest flex items-center sticky top-0 z-[9] "
                        ref={el => (dateRefs.current[date] = el)}
                      >
                        <div className="flex-grow border-t border-[#828e94]"></div>
                        <span className="text-[#393e41] text-xs font-semibold mx-4 py-1">
                          {formatDate(date)}
                        </span>
                        <div className="flex-grow border-t border-[#828e94]"></div>
                      </div>

                      {newsItems.length > 0 ? (
                        <Masonry
                          breakpointCols={breakpointColumnsObj}
                          className="flex w-auto space-x-2"
                          columnClassName="bg-clip-padding"
                        >
                          {newsItems.map((news: NewsItem, index: number) => {
                            return (
                              <div
                                key={index}
                                className="max-w-[100%] p-0 pb-5 gap-2 border border-light rounded-2xl relative shadow-sm shadow-gray-300 my-4"
                              >
                                <div className="flex justify-between items-center bg-white rounded-2xl">
                                  <div className=" p-2 pl-2 pr-2 pt-2">
                                    <div className="font-poppins text-[12px] text-left pl-2">
                                      {news.source === "Bing News"
                                        ? news.provider &&
                                          news.provider.length > 0
                                          ? news.provider[0]
                                          : "Bing News"
                                        : news.source}
                                    </div>
                                    <div className="font-poppins text-[10px] pl-2">
                                      {formatDate(news.datePublished!)}
                                    </div>
                                  </div>
                                  <div>
                                    <img
                                      src={news.image}
                                      alt="something"
                                      className="w-[70px] h-[70px] rounded-tr-2xl object-content"
                                      onError={e =>
                                        handleImageError(
                                          news.source,
                                          news.url,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="px-2 flex-grow">
                                  <a
                                    href={news.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`underline line-clamp-3 text-primary font-bold`}
                                  >
                                    {news.title}
                                  </a>
                                  <p
                                    className={`text-[12px] overflow-hidden font-poppins line-clamp-8 text-gray-400 mb-1`}
                                  >
                                    {news.description}
                                  </p>
                                </div>
                                <div className="flex space-x-1 absolute right-2 bottom-2">
                                  <div
                                    className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                                    onClick={() => handleSaveToBoard(news)}
                                  >
                                    <HeartIcon />
                                  </div>
                                  <div
                                    className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                                    onClick={() =>
                                      handleCopyToClipboard(index, date)
                                    }
                                  >
                                    <CopyToClipboard />
                                  </div>
                                  <div
                                    className="flex w-[16px] h-4 items-center justify-center cursor-pointer p-[2px] bg-white rounded-full shadow-sm"
                                    onClick={() => handleExpandedViewOpen(news)}
                                  >
                                    <ExpandCard />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Masonry>
                      ) : (
                        <div className="w-ful text-center">
                          <p className="text-xs font-poppins text-gray-500 py-2">
                            No data found
                          </p>
                        </div>
                      )}
                    </div>
                  )
                )}
            </div>
          </div>
          <Modal
            isOpen={isExpandViewOpen}
            onClose={closeExpandView}
            title="Expanded View"
          >
            <div className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer absolute right-0 top-0">
              <Close />
            </div>
            {selectedNews && (
              <DailyExpandView
                url={selectedNews.url}
                image={selectedNews.image}
                description={selectedNews.description}
                provider={selectedNews.provider}
                title={selectedNews.title}
                source={selectedNews.source}
                handleImageError={handleImageError}
                isSaveToBoardOpen={isSaveToBoardOpen}
                setSaveToBoardOpen={setSaveToBoardOpen}
                closeSaveToBoard={closeSaveToBoard}
                date={selectedNews.datePublished}
              />
            )}
          </Modal>
          <NewsModal
            isOpen={isSaveToBoardOpen}
            onClose={closeSaveToBoard}
            title="Save to Board"
          >
            {
              <SaveToBoard
                selectedNews={selectedNews!}
                onClose={closeSaveToBoard}
                userId={userId}
                handleImageError={handleImageError}
                publicBoards={publicBoards || []}
                setPublicBoards={setPublicBoards}
                privateBoards={privateBoards || []}
                setPrivateBoards={setPrivateBoards}
              />
            }
          </NewsModal>
        </div>
        {/* Sidebar Component */}
        <Keyevents
          isCollapsed={isSidebarCollapsed}
          onToggle={handleSidebarToggle}
        />
      </div>
    </>
  );
};

export default DailyViralFeed;
