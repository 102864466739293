import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function GenerativeIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill={props.fill}
      viewBox="0 0 21 20"
    >
      <path
        fill={props.fill}
        d="M17.3 6.9l3.2 3.2-1.4 1.4-3.2-3.2c-.35.2-.725.367-1.125.5S13.95 9 13.5 9c-1.25 0-2.312-.437-3.187-1.312S9.001 5.751 9 4.5c0-1.25.437-2.313 1.313-3.187C11.189.439 12.251.001 13.5 0c1.249-.001 2.311.436 3.188 1.313.877.877 1.314 1.939 1.312 3.187 0 .45-.067.875-.2 1.275-.133.4-.3.775-.5 1.125zm-3.8.1c.7 0 1.292-.242 1.775-.725C15.758 5.792 16 5.2 16 4.5s-.242-1.292-.725-1.775C14.792 2.242 14.2 2 13.5 2s-1.292.242-1.775.725C11.242 3.208 11 3.8 11 4.5s.242 1.292.725 1.775C12.208 6.758 12.8 7 13.5 7zM2 20c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 010 18V4c0-.55.196-1.02.588-1.412A1.93 1.93 0 012 2h5.5a5.06 5.06 0 00-.375 1.288c-.067.442-.1.88-.1 1.312 0 1.817.642 3.333 1.925 4.55 1.283 1.217 2.808 1.825 4.575 1.825.317 0 .633-.021.95-.063.317-.042.642-.113.975-.212L18 13.25V18c0 .55-.196 1.021-.587 1.413A1.92 1.92 0 0116 20H2z"
      ></path>
    </svg>
  );
}

export default GenerativeIcon;
