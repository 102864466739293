import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

interface RoleBasedRouteProps {
  roles: string[];
  element: React.ReactElement;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ roles, element }) => {
  const user = useSelector((state: RootState) => state.user.userInfo);

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (!roles.includes(user.group)) {
    return <Navigate to="/" />;
  }

  return element;
};

export default RoleBasedRoute;
