import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ReturnArrow } from "../../assets/icons/returnArrow.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as BlackHeartIcon } from "../../assets/icons/heartWithBlack.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { ReactComponent as ExpandSvg } from "../../assets/icons/expand.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/downArrow.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import Modal from "../../components/Common/Modal";
import ShareBoard, { CollaboratorOb } from "../../components/Boards/ShareBoard";
import {
  deleteBoardById,
  getBoardByBoardId,
  getBoardsByAccessLevel,
  getBoardsByAccessLevelAsOwner,
} from "../../app/api/admin/board";
import {
  deleteContentById,
  getContentByBoard,
} from "../../app/api/admin/content";
import { Content } from "../../interface/ContentInterface";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getUserId } from "../../app/api/user/user";
import { Board } from "../../interface/BoardsInterface";
import UploadToBoard from "../../components/Boards/UploadToBoard";
import { copyImageToClipboard } from "../../utils";
import DownloadImage from "../../components/DesignerPage/DownloadImage";
import { SelectedPost } from "../../interface/DesignerInterface";
import ExpandView from "../../components/DesignerPage/ExpandView";
import DeleteBoard from "../../components/Boards/DeleteBoard";
import MultiSelect from "../../components/Boards/MultiSelect";
import { deleteCollaborator } from "../../app/api/admin/collaborator";
import RemoveCollaborator from "./RemoveCollaborator";
import { copyToClipboard } from "../Feed/utils";
import DailyExpandView from "../Feed/newsExpandedView";
import DeleteModal from "../../components/Boards/DeleteModal";
import DesignerExpandView from "../../components/DesignerPage/DesignerExpandView";
import NewsModal from "../../components/Feed/modal";
import Skeleton from "react-loading-skeleton";

export interface boardSelection {
  type: string;
  board_name: string;
  id: string;
}

function BoardImages() {
  const [contentData, setContentData] = useState<Content[]>([]);
  const [boardsList, setBoardList] = useState<boardSelection[]>([]);
  const [tabs, setTabs] = useState<boardSelection[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<boardSelection>();
  const [page, setPage] = useState<string>("");
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const [privateOwnerBoards, setPrivateOwnerBoards] = useState<Board[]>([]);
  const [publicOwnerBoards, setPublicOwnerBoards] = useState<Board[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imageHandle, setImageHandle] = useState<string>("");
  const [uploadImageHandle, setUploadImageHandle] = useState<string>("");
  const [isDownloadImageOpen, setDownloadImageOpen] = useState(false);
  const closeDownloadImage = () => setDownloadImageOpen(false);

  const [privacy, setPrivacy] = useState("all");
  const [selectedPost, setSelectedPost] = useState<SelectedPost>();
  const [isExpandViewOpen, setExpandViewOpen] = useState(false);
  const [saveToBoardImage, setSaveToBoardImage] = useState<string>("");
  const [isSaveToBoardOpen, setSaveToBoardOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [removeContent, setRemoveContent] = useState(false);
  const [openDesignerExpandView, setOpenDesignerExpandView] = useState(false);
  const [collaboratorInitials, setCollaboratorInitials] = useState<string[]>(
    []
  );
  const [collaboratorCount, setCollaboratorCount] = useState<number>(0);

  const [collaboratorModalOpen, setCollaboratorModalOpen] = useState(false);

  const [selectedNews, setSelectedNews] = useState<Content>();
  const [isDailyExpandViewOpen, setIsDailyExpandedViewOpen] =
    useState<boolean>(false);

  const [removeCollaborator, setRemoveCollaborator] =
    useState<CollaboratorOb | null>(null);

  const [pageSize, setPageSize] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState("");

  const onClose = () => setIsShareModalOpen(false);
  const onCloseCollaboratorModal = () => setCollaboratorModalOpen(false);
  const navigate = useNavigate();
  const { boardId } = useParams();

  const user = useSelector((state: RootState) => state.user.userInfo);

  const userEmail = user?.email;
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const getBoardData = async (boardId: string = "") => {
    setLoading(true);
    const activeBoardRes = await getBoardByBoardId(boardId || "");
    setCollaboratorInitials(activeBoardRes.data.data.collaborator_initials);
    setCollaboratorCount(activeBoardRes.data.data.collaborator_count);
    const contentResponse = await getContentByBoard(boardId || "", pageSize);
    setContentData(contentResponse.data.data.contents);
    setLoading(false);
  };

  const getBoardsList = async (canSetTab: boolean = false) => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res.data.data[0]._id);
      const user_Id = res.data.data[0]._id;

      const response = await getBoardsByAccessLevel(user_Id);
      const boardsResponseByOwner =
        await getBoardsByAccessLevelAsOwner(user_Id);
      setPrivateOwnerBoards(boardsResponseByOwner.data.data.private.boards);
      setPublicOwnerBoards(boardsResponseByOwner.data.data.public.boards);
      const boards: boardSelection[] = [];
      ["public", "private"].forEach((type: string) => {
        response.data.data[type].boards.forEach((board: boardSelection) => {
          boards.push({
            type: board.type,
            board_name:
              board.board_name.charAt(0).toUpperCase() +
              board.board_name.slice(1),
            id: board.id,
          });
        });
      });
      setBoardList(boards);
      if (boards && canSetTab) {
        const currentBoard = boards.find(b => b.id === boardId);
        setActiveTab(currentBoard);
        setTabs(currentBoard ? [currentBoard] : []);
      }
    }
  };

  useEffect(() => {
    getBoardData(boardId);
    getBoardsList(true);
  }, [userEmail]);

  useEffect(() => {
    getBoardData(activeTab?.id || boardId);
  }, [activeTab]);

  const addTab = (board: boardSelection) => {
    if (tabs.length > 10) {
      toast.error("You can't add more than 10 tabs", {
        hideProgressBar: true,
      });
      return;
    }

    if (
      !tabs
        .map((item: boardSelection) => item.board_name)
        .includes(board.board_name)
    ) {
      setTabs([...tabs, board]);
      setActiveTab(board);
    }
  };

  const removeTab = (board: boardSelection) => {
    if (tabs.length === 1) {
      navigate(-1);
    }
    setTabs(tabs.filter(tab => tab.id !== board.id));
  };

  const filteredData =
    privacy === "all"
      ? boardsList
      : boardsList.filter(item => item.type === privacy);

  const ownerBoardIds = (
    activeTab?.type === "private" ? privateOwnerBoards : publicOwnerBoards
  ).map(board => board.id);

  const truncateDescription = (description: string) => {
    return description?.length > 55
      ? description.slice(0, 55) + "..."
      : description;
  };

  const handleChange = (selectedPrivacy: string) => {
    if (selectedPrivacy === "private" && privacy !== "private") {
      setPrivacy("private");
    } else if (selectedPrivacy === "public" && privacy !== "public") {
      setPrivacy("public");
    } else {
      setPrivacy("all");
    }
  };

  const pageData = contentData.filter(item =>
    page ? item.category_name.toLocaleLowerCase() === page : true
  );

  const handleButtonClick = () => {
    setIsUploadOpen(true);
  };

  const handleClose = () => {
    setIsUploadOpen(false);
  };

  const handleRemoveContent = async (item: Content) => {
    try {
      await deleteContentById(item.id || "");
      toast.success("Successfully deleted the content", {
        hideProgressBar: true,
      });
      getBoardData(activeTab?.id);
    } catch (e) {
      toast.error("Error while deleting the content", {
        hideProgressBar: true,
      });
    }
  };

  const handleCopyImageToClipboard = async (
    imageUrl: string,
    postId: string
  ) => {
    const post = pageData.find(
      post => post?.postId === postId || post?.id === postId
    );

    if (post) {
      const handle = post?.handle;
      const brand = post?.brand;
      await copyImageToClipboard(imageUrl, handle ? `IG/${handle}` : brand);
    }
  };

  const openDownloadImage = (imageUrl: string, source: string) => {
    setSelectedImage(imageUrl);
    setImageHandle(source);
    setDownloadImageOpen(true);
  };

  const openExpandView = (post: Content) => {
    setSelectedPost({
      imageUrl:
        `${process.env.REACT_APP_CLOUD_FRONT_URL}${post?.media_urls.split(".com/")[1]}` ||
        " ",
      brandName: post?.brand,
      description: post?.description,
      source: post?.handle ?? "",
      postId: post?.postId ?? "",
      permalink: post?.permalink ?? "",
      id: post?.id ?? "",
      liveSince: post?.postDate ?? "",
    });
    setExpandViewOpen(true);
  };

  const designerExpandView = (post: Content) => {
    setSelectedPost({
      imageUrl:
        `${process.env.REACT_APP_CLOUD_FRONT_URL}${post?.media_urls.split(".com/")[1]}` ||
        " ",
      brandName: post?.brand,
      description: post?.description,
      source: post?.handle ?? "",
      postId: post?.postId ?? "",
      permalink: post?.permalink ?? "",
      id: post?.id ?? "",
      date: post?.date ?? "",
      notes: post?.notes ?? "",
      liveSince: post?.postDate ?? "",
    });
    setOpenDesignerExpandView(true);
  };

  const closeExpandView = () => setExpandViewOpen(false);
  const closeDesignerExpandView = () => setOpenDesignerExpandView(false);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const closeRemoveModal = () => setRemoveContent(false);

  const openSaveToBoard = (imageUrl: string) => {
    setSaveToBoardImage(imageUrl);
    setSaveToBoardOpen(true);
  };

  const onClickRemoveCollaborator = (collaborator: CollaboratorOb) => {
    setRemoveCollaborator(collaborator);
    setIsShareModalOpen(false);
    getBoardsList(true);
  };

  const confirmDeleteCollaborator = () => {
    if (!removeCollaborator?.id) return;

    return deleteCollaborator(removeCollaborator.id).then(() => {
      cancelDeleteCollaborator();
      setIsShareModalOpen(false);
      getBoardsList(true);
    });
  };

  const cancelDeleteCollaborator = () => {
    setRemoveCollaborator(null);
    setIsShareModalOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCopyToClipboard = async (index: number) => {
    const newsToBeCopied = pageData[index];
    const response = await copyToClipboard({
      title: newsToBeCopied?.title,
      description: newsToBeCopied?.description,
      url: newsToBeCopied?.url,
    });
    if (response === "success") {
      toast.success("Text Copied", {
        hideProgressBar: true,
      });
    } else if (response === "error") {
      toast.error("Error Copying Text", {
        hideProgressBar: true,
      });
    }
  };

  const handleExpandedViewOpen = (news: Content) => {
    setSelectedNews(news);
    setIsDailyExpandedViewOpen(true);
  };

  const closeDailyExpandView = () => setIsDailyExpandedViewOpen(false);

  const closeSaveToBoard = () => {
    setSaveToBoardOpen(false);
  };

  const handleImageError = async (
    source: string | " ",
    url: string,
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ): Promise<void> => {
    //Check if favicon available or not
    const fetchFavicon = (): Promise<boolean> => {
      return new Promise(resolve => {
        const img = new Image();
        img.src = `${new URL(url).origin}/favicon.ico`;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };
    const isFaviconAvailable = await fetchFavicon();
    if (isFaviconAvailable) {
      (e.target as HTMLImageElement).src = `${new URL(url).origin}/favicon.ico`;
      return;
    }

    //If favicon not available
    const firstWord = source.split(" ")[0];
    const canvas = document.createElement("canvas");
    canvas.width = 70;
    canvas.height = 70;
    const context = canvas.getContext("2d");
    if (context) {
      context.fillStyle = "#000000";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#FFFFFF";
      context.font = "bold 20px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(firstWord, canvas.width / 2, canvas.height / 2);
      const imageUrl = canvas.toDataURL();
      (e.target as HTMLImageElement).src = imageUrl;
    }
  };

  const loadMorePosts = async () => {
    const contentResponse = await getContentByBoard(boardId || "", pageSize);
    if (contentResponse.data.data.contents?.length === 0 && pageSize === 1) {
      setContentData([]);
    } else {
      setTotalPages(
        Math.ceil(
          contentResponse.data.data.count / contentResponse.data.data.limit
        )
      );
      setContentData(prevPosts =>
        pageSize === 1
          ? [...contentResponse.data.data.contents]
          : [...prevPosts, ...contentResponse.data.data.contents]
      );
    }
  };

  useEffect(() => {
    loadMorePosts();
  }, [pageSize]);

  const onScrollItems = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      const isNearBottom =
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1;

      if (isNearBottom && pageSize < totalPages) {
        setPageSize(prevPage => prevPage + 1);
      }
    }
  };

  return (
    <div className="">
      <div className="flex flex-col  h-[calc(100vh-80px)] w-[calc(100vw-260px)] overflow-hidden fixed">
        <div className="flex items-center bg-white">
          <div className="flex items-center border-b border-gray-200">
            <div className="border-[#f1f5f9] border-l">
              <button
                className="flex pl-4 items-center space-x-4 p-4 text-gray-700"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <span>Boards</span>
                <DownArrow className="w-5 h-5" />
              </button>
              {dropdownOpen && (
                <div className="absolute z-10 pt-3  w-64    bg-white border border-gray-200 rounded shadow-lg">
                  <div className="flex justify-around mb-4 ">
                    <label className="flex items-center font-bold cursor-pointer">
                      <input
                        type="checkBox"
                        name="privacy"
                        className="mr-2 mb-1 cursor-pointer"
                        checked={privacy === "private"}
                        onChange={() => handleChange("private")}
                      />
                      Private
                    </label>
                    <label className="flex items-center font-bold cursor-pointer">
                      <input
                        type="checkBox"
                        name="privacy"
                        className="mr-2 mb-1 cursor-pointer"
                        checked={privacy === "public"}
                        onChange={() => handleChange("public")}
                      />
                      Public
                    </label>
                  </div>
                  <ul className="space-y-2 max-h-[calc(100vh-200px)] overflow-hidden overflow-y-scroll">
                    {filteredData.map((item, index) => (
                      <li
                        key={index}
                        className="bg-gray-100 p-2 rounded border-b-2 border-light cursor-pointer"
                        onClick={() => addTab(item)}
                      >
                        {item.board_name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex items-center ml-4">
              {tabs.map(tab => (
                <div
                  key={tab.id}
                  className={`flex items-center px-4 py-3.5 border-x p-2 cursor-pointer ${
                    activeTab === tab ? "bg-dashboard" : "bg-white"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  <span className="font-bold text-black">{tab.board_name}</span>
                  <button
                    className="ml-2 text-gray-500"
                    onClick={() => removeTab(tab)}
                  >
                    {activeTab === tab && (
                      <div className="text-xl">&times;</div>
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg border-[16px] border-[#f1f5f9] border-r border-r ">
          <div className="flex justify-between  space-x-4 p-4 items-center w-full">
            <div className="flex justify-center items-center space-x-4">
              <button
                className="flex bg-gray-200 mr-2"
                onClick={() => navigate(-1)}
              >
                <LeftArrow className="text-lg" />
              </button>
              <div className="flex items-center space-x-4 text-xs">
                <button
                  className={`border border-gray-400 rounded-full px-3 py-1.5 ${page === "daily" ? "bg-light" : ""}`}
                  onClick={() => setPage("daily")}
                >
                  Daily
                </button>
                <button
                  className={`border border-gray-400 rounded-full px-3 py-1.5 ${page === "innovation" ? "bg-light" : ""}`}
                  onClick={() => setPage("innovation")}
                >
                  Innovation
                </button>
                <button
                  className={`border border-gray-400 rounded-full px-3 py-1.5 ${page === "designer" ? "bg-light" : ""}`}
                  onClick={() => setPage("designer")}
                >
                  Designer
                </button>
                <button
                  className={`border border-gray-400 rounded-full px-3 py-1.5 ${page === "uploaded" ? "bg-light" : ""}`}
                  onClick={() => setPage("uploaded")}
                >
                  Uploaded
                </button>
              </div>
            </div>
            <div className="flex gap-2 justify-center items-center ">
              {activeTab?.type === "private" && (
                <div
                  className=" space-x-[-18px] flex cursor-pointer"
                  onClick={() => setCollaboratorModalOpen(true)}
                >
                  {collaboratorInitials.slice(0, 2).map((initial, index) => (
                    <div
                      key={index}
                      className="bg-[#A64B2A] text-white rounded-xl w-8 h-8 flex items-center justify-center text-lg border-2 border-white"
                    >
                      {initial}
                    </div>
                  ))}

                  {collaboratorCount > 2 && (
                    <div className="bg-[#A64B2A] text-white rounded-xl w-8 h-8 flex items-center justify-center text-lg border-2 border-white">
                      {`+${collaboratorCount - 2}`}
                    </div>
                  )}
                </div>
              )}
              {ownerBoardIds.includes(activeTab?.id || "") && (
                <>
                  <div className="flex flex-row gap-4">
                    <Share
                      className="cursor-pointer"
                      onClick={() => setIsShareModalOpen(true)}
                    />

                    <Delete
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="cursor-pointer"
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className=" h-[calc(100vh-220px)] overflow-scroll px-4"
            ref={scrollRef}
            onScroll={onScrollItems}
          >
            {loading && (
              <div className="flex flex-wrap justify-between space-x-4">
                {Array.from({ length: 12 }).map((_, index) => (
                  <div
                    key={index}
                    className="w-[240px] mt-4 p-0 pb-3 gap-2 border border-light rounded-2xl relative cursor-pointer"
                  >
                    <div className="relative w-full h-auto">
                      <Skeleton
                        height={210}
                        width={240}
                        className="rounded-t-2xl"
                      />
                      <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                        <Skeleton width={50} />
                      </div>
                      <div className="absolute bottom-2 right-2 flex space-x-1">
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                      </div>
                    </div>
                    <div className="p-2 space-y-0.5">
                      <Skeleton width={100} />
                      <Skeleton width={150} />
                      <Skeleton width={120} />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {pageData.length > 0 ? (
              <div className="columns-2 md:columns-3 lg:columns-4 gap-6 space-y-6 ">
                {pageData.map((item: Content, index: number) => {
                  const imageUrl = `${process.env.REACT_APP_CLOUD_FRONT_URL}${item.media_urls.split(".com/")[1]}`;

                  return (
                    <div
                      key={index}
                      className="max-w-[100%] break-inside-avoid  pb-5 gap-2 border border-light rounded-2xl relative shadow-sm shadow-gray-300 relative"
                    >
                      {(item.category_name === "Designer" ||
                        item.category_name === "Uploaded") && (
                        <>
                          <div className="relative w-full h-[310px] hover:transition-all duration-100">
                            <div className="relative w-full h-[310px]">
                              <img
                                src={imageUrl}
                                alt={`Image ${item.id}`}
                                className="w-full h-full object-cover rounded-t-2xl"
                              />
                            </div>

                            <div className="absolute bottom-2 right-2 flex space-x-1">
                              {item.category_name !== "Uploaded" && (
                                <div
                                  className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                                  onClick={() => {
                                    window.open(`${item?.permalink}`, "_blank");
                                  }}
                                >
                                  <ReturnArrow />
                                </div>
                              )}
                              <div
                                className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                                onClick={() => {
                                  setRemoveContent(true);
                                  setSelectedNews(item);
                                }}
                              >
                                <BlackHeartIcon />
                              </div>
                              <div
                                className="bg-white flex w-5 h-5 shadow-2xl shadow-gray-900 items-center justify-center rounded-full cursor-pointer"
                                onClick={async () => {
                                  setSelectedImage(imageUrl);

                                  if (item.category_name === "Designer") {
                                    setImageHandle(item.handle as string);
                                  } else {
                                    setUploadImageHandle(item.brand as string);
                                  }

                                  setDownloadImageOpen(true);
                                }}
                              >
                                <Download />
                              </div>
                              <div
                                className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                                onClick={() =>
                                  handleCopyImageToClipboard(
                                    imageUrl,
                                    item?.postId
                                      ? (item?.postId as string)
                                      : (item?.id as string)
                                  )
                                }
                              >
                                <CopyToClipboard />
                              </div>
                            </div>
                          </div>
                          <div className="p-2 space-y-0.5">
                            <div className="text-xs mt-1 text-primary font-medium">
                              Brand: {item.brand}
                            </div>
                            <div className="text-xs text-primary">
                              Date: {moment(item.date).format("DD/MM/YYYY")}
                            </div>
                            <div className="flex justify-between items-center text-xs text-tertiary">
                              <span>
                                {truncateDescription(item.description)}
                              </span>
                              <button
                                className=" mr-2"
                                onClick={() => {
                                  if (item.category_name === "Uploaded") {
                                    openExpandView(item);
                                  }
                                  if (item.category_name === "Designer") {
                                    designerExpandView(item);
                                  }
                                }}
                              >
                                <ExpandSvg className="w-2.5 h-2.5" />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {(item.category_name === "Daily" ||
                        item.category_name === "Innovation") && (
                        <>
                          <div className="flex justify-between bg-white ml-2">
                            <div className="gap-y-2  pl-2 pr-2 pt-2">
                              <div className="font-poppins text-[12px] flex text-center justify-center">
                                {item.source === "Bing News"
                                  ? item.provider
                                    ? item.provider[0]
                                    : "Bing News"
                                  : item.source}
                              </div>
                              <div className="font-poppins text-[12px] flex text-center justify-center font-bold">
                                {moment(item.date).format("DD/MM/YYYY")}
                              </div>
                            </div>
                            <div>
                              <img
                                src={item.media_urls}
                                alt="something"
                                className="w-[70px] h-[70px] rounded-tr-xl object-content"
                                onError={e =>
                                  handleImageError(
                                    item?.source as string,
                                    item.url as string,
                                    e
                                  )
                                }
                              />

                              <div className="flex absolute top-[50px] right-3 space-x-1">
                                <div
                                  className="bg-white flex w-[14px] h-4 items-center justify-center shadow-md rounded-full cursor-pointer p-[2px]"
                                  onClick={() => {
                                    setRemoveContent(true);
                                    setSelectedNews(item);
                                  }}
                                >
                                  <BlackHeartIcon />
                                </div>
                                <div
                                  className="bg-white flex w-[14px] h-4 items-center justify-center shadow-md rounded-full cursor-pointer p-[2px] "
                                  onClick={() => handleCopyToClipboard(index)}
                                >
                                  <CopyToClipboard />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-2 flex-grow">
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline line-clamp-3 text-primary font-bold"
                            >
                              {item.title}
                            </a>
                            <p
                              className={`text-[12px] overflow-hidden font-poppins text-gray-400 line-clamp-6 mb-1`}
                            >
                              {item.description}
                            </p>
                          </div>
                          <div
                            className="absolute right-1 bottom-1 cursor-pointer"
                            onClick={() => handleExpandedViewOpen(item)}
                          >
                            <ExpandSvg className="w-2.5 h-2.5" />
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No data found</div>
            )}
            <div className="fixed bottom-8 right-14">
              <button
                className="bg-primary hover:bg-secondary text-brandPrimary py-2 px-4 rounded-full text-4xl"
                onClick={handleButtonClick}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isShareModalOpen} onClose={onClose} title="">
        {activeTab?.type === "private" ? (
          <>
            <MultiSelect
              isShareModalOpen={isShareModalOpen}
              onShareClose={onClose}
              boardId={activeTab?.id || ""}
              boardName={activeTab?.board_name}
              getBoardsList={getBoardsList}
            />
          </>
        ) : (
          <ShareBoard
            boardId={activeTab?.id || boardId || ""}
            onClickRemoveCollaborator={onClickRemoveCollaborator}
          />
        )}
      </Modal>

      {collaboratorModalOpen && (
        <Modal
          isOpen={collaboratorModalOpen}
          onClose={onCloseCollaboratorModal}
          title=""
        >
          <ShareBoard
            boardId={activeTab?.id || boardId || ""}
            onClickRemoveCollaborator={onClickRemoveCollaborator}
            fromPrivateBoard={true}
            onClose={onCloseCollaboratorModal}
            getBoardsList={getBoardsList}
            userId={userId}
          />
        </Modal>
      )}

      <Modal
        isOpen={Boolean(removeCollaborator)}
        onClose={cancelDeleteCollaborator}
        title=""
      >
        <RemoveCollaborator
          removeCollaborator={removeCollaborator}
          onRemove={confirmDeleteCollaborator}
          onCancel={cancelDeleteCollaborator}
        />
      </Modal>

      {isUploadOpen && (
        <Modal isOpen={isUploadOpen} onClose={onClose} title={""}>
          <UploadToBoard
            activeTab={activeTab}
            onClose={handleClose}
            refresh={getBoardData}
          />
        </Modal>
      )}

      <NewsModal
        isOpen={openDesignerExpandView}
        onClose={closeDesignerExpandView}
        title="Saved"
      >
        {selectedPost && (
          <DesignerExpandView
            imageUrl={selectedPost?.imageUrl}
            brandName={selectedPost.brandName}
            description={selectedPost.description}
            source={selectedPost.source}
            openSaveToBoard={openSaveToBoard}
            openDownloadImage={openDownloadImage}
            postId={selectedPost.postId}
            permalink={selectedPost.permalink}
            view={true}
            boardId={selectedPost.id}
            date={selectedPost.date}
            closeExpandView={closeExpandView}
            notes={selectedPost.notes}
          />
        )}
      </NewsModal>

      <Modal
        isOpen={isExpandViewOpen}
        onClose={closeExpandView}
        title="Expanded View"
      >
        {selectedPost && (
          <ExpandView
            imageUrl={selectedPost?.imageUrl}
            brandName={selectedPost.brandName}
            description={selectedPost.description}
            source={selectedPost.source}
            openSaveToBoard={openSaveToBoard}
            openDownloadImage={openDownloadImage}
            postId={selectedPost.postId}
            permalink={selectedPost.permalink}
            view={true}
            boardId={selectedPost.id}
            closeExpandView={closeExpandView}
            liveSince=""
          />
        )}
      </Modal>

      <Modal
        isOpen={isDownloadImageOpen}
        onClose={closeDownloadImage}
        title="Download Image"
      >
        {selectedImage && (
          <DownloadImage
            imageUrl={selectedImage}
            source={imageHandle}
            brandName={uploadImageHandle}
          />
        )}
      </Modal>

      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} title="">
          <DeleteBoard
            onClose={closeDeleteModal}
            boardName={activeTab?.board_name as string}
            boardId={activeTab?.id as string}
          />
        </Modal>
      )}

      {removeContent && (
        <Modal isOpen={removeContent} onClose={closeRemoveModal} title="">
          <DeleteModal
            onClose={closeRemoveModal}
            contentId={selectedNews?.id as string}
            boardId={activeTab?.id as string}
            setContentData={setContentData}
          />
        </Modal>
      )}

      <Modal
        isOpen={isDailyExpandViewOpen}
        onClose={closeDailyExpandView}
        title="Expanded View"
      >
        {selectedNews && (
          <DailyExpandView
            url={selectedNews.url || ""}
            image={selectedNews?.media_urls}
            description={selectedNews.description}
            title={selectedNews.title || ""}
            source={selectedNews.source || ""}
            handleImageError={() => {}}
            isSaveToBoardOpen={isSaveToBoardOpen}
            setSaveToBoardOpen={setSaveToBoardOpen}
            closeSaveToBoard={closeSaveToBoard}
            fromBoard={true}
            id={selectedNews.id}
            boardId={selectedNews.board_ids[0]}
            getBoardData={getBoardData}
            closeDailyExpandView={closeDailyExpandView}
            date={moment(selectedNews.date).format("YYYY-MM-DD")}
            notes={selectedNews.notes}
          />
        )}
      </Modal>
    </div>
  );
}

export default BoardImages;
