import React, { useState } from "react";

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  status: string;
}

interface EditMemberProps {
  user: User;
  onClose: () => void;
  onSave: (updatedUser: User) => void;
  onDelete: (userId: number) => void;
}

const EditMember: React.FC<EditMemberProps> = ({
  user,
  onClose,
  onSave,
  onDelete,
}) => {
  const [name, setName] = useState(user.name);
  const [role, setRole] = useState(user.role);
  const [status, setStatus] = useState(user.status);

  const handleSave = () => {
    onSave({ ...user, name, role, status });
    onClose();
  };

  const handleDelete = () => {
    onDelete(user.id);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-white rounded-lg  relative w-96">
        <div className="flex w-full items-center justify-between p-3">
          <h2 className="text-lg font-bold">Edit Member</h2>
          <button onClick={onClose} className="text-3xl">
            &times;
          </button>
        </div>

        <form className="pl-12 pr-12 pt-8 pb-4 ">
          <div className="mb-4">
            <label className="block text-sm mb-2">Name</label>
            <input
              type="text"
              className="w-full px-4 py-3 border rounded-lg text-sm"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-2">Email</label>
            <input
              type="email"
              className="w-full px-4 py-3 border rounded-lg text-sm focus:outline-none bg-gray"
              value={user.email}
              readOnly
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-2">Role</label>
            <span className="flex">
              <label className="w-1/2">
                <input
                  type="radio"
                  name="role"
                  value="admin"
                  checked={role === "admin"}
                  onChange={e => setRole(e.target.value)}
                />{" "}
                Admin
              </label>
              <label>
                <input
                  type="radio"
                  name="role"
                  value="forecaster"
                  checked={role === "forecaster"}
                  onChange={e => setRole(e.target.value)}
                />{" "}
                Forecaster
              </label>
            </span>
          </div>
          {user.status !== "Pending" && (
            <div className="mb-4">
              <label className="block text-sm mb-2">Status</label>
              <select
                className="w-full px-4 py-3 border rounded-lg text-sm appearance-none bg-white focus:outline-none"
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </select>
            </div>
          )}
        </form>

        <div className="flex justify-around shadow-top-md">
          <button
            className="w-1/3 font-bold text-sm px-6 py-3 rounded-full border-gray border-2 m-4"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            className="w-1/3 font-bold text-sm bg-black text-white px-6 py-3 rounded-full m-4"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMember;
