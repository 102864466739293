//https://api.dev.kreedatesting.in/api/v1/innovationFeeds?startDate=2024-09-18T00%3A00%3A00.000Z&endDate=2024-09-19T23%3A59%3A59.000Z&category=Finance&page=1&pageSize=1

import moment from "moment";
import { User } from "../../types";
import { admin, forecaster } from "./axios";

type GenerateParams = {
  startDate?: string;
  endDate?: string;
  page: number;
  pageSize: number;
  category?: string;
};

type SearchParams = {
  search: string;
  startDate?: string;
  endDate?: string;
};

export const getInnovationData = async (
  parameter: GenerateParams,
  isAuthenticated: string | undefined,
  user: User | null
) => {
  // Convert them to ISO 8601 format with time component

  const params: GenerateParams = {
    page: parameter.page,
    pageSize: parameter.pageSize,
  };

  if (parameter.category) {
    params.category = parameter.category;
  }

  if (parameter.startDate && parameter.endDate) {
    params.startDate = moment(parameter.startDate, "DD/MM/YYYY").format(
      "YYYY-MM-DDT00:00:00"
    );
    params.endDate = moment(parameter.endDate, "DD/MM/YYYY").format(
      "YYYY-MM-DDT23:59:59"
    );
  }
  if (isAuthenticated === "false" || isAuthenticated === undefined)
    throw new Error("Not Authenticated");
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("innovationFeeds", {
          params,
        });
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get("innovationFeeds", {
          params,
        });
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};

export const getInnovationDropdownData = async (
  isAuthenticated: string | undefined,
  user: User | null
) => {
  if (isAuthenticated === "false" || isAuthenticated === undefined)
    throw new Error("Not Authenticated");
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("daily-viralfeed/categories");
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get("daily-viralfeed/categories");
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};

export const searchByValue = async (
  parameter: SearchParams,
  isAuthenticated: string | undefined,
  user: User | null
) => {
  const params: SearchParams = {
    search: parameter.search,
  };

  if (isAuthenticated === "false" || isAuthenticated === undefined)
    throw new Error("Not Authenticated");
  if (user === null) {
    throw new Error("User is not available");
  }

  if (parameter.startDate && parameter.endDate) {
    // Convert to ISO 8601 format with time component
    params.startDate = moment(parameter.startDate, "DD/MM/YYYY").format(
      "YYYY-MM-DDT00:00:00"
    );
    params.endDate = moment(parameter.endDate, "DD/MM/YYYY").format(
      "YYYY-MM-DDT23:59:59"
    );
  }

  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("innovationFeeds/global-search", {
          params,
        });
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get("innovationFeeds/global-search", {
          params,
        });
        return response.data;
      }
    } catch (error) {
      console.error("Error performing search:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};
