import { admin, forecaster } from "./axios";
import { User } from "../../types";
import moment from "moment";

type GenerateParams1 = {
  date: string;
  page: number;
  pageSize: number;
  category?: string;
};

export const getDailyNews = async (
  params: GenerateParams1,
  isAuthenticated: string | undefined,
  user: User | null
) => {
  if (isAuthenticated === "false" || isAuthenticated === undefined)
    throw new Error("Not Authenticated");
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("daily-viralfeed", {
          params,
        });
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get("daily-viralfeed", {
          params,
        });
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};

export const getDropdownData = async (
  isAuthenticated: string | undefined,
  user: User | null
) => {
  if (isAuthenticated === "false" || isAuthenticated === undefined)
    throw new Error("Not Authenticated");
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("daily-viralfeed/categories");
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get("daily-viralfeed/categories");
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};

type SearchParams = {
  search: string;
  category?: string;
  startDate?: string;
  endDate?: string;
};

export const searchByValue = async (
  params: SearchParams,
  isAuthenticated: string | undefined,
  user: User | null
) => {
  if (isAuthenticated === "false" || isAuthenticated === undefined)
    throw new Error("Not Authenticated");

  if (user === null) {
    throw new Error("User is not available");
  }
  params.endDate = moment().format("YYYY-MM-DDT00:00:00");
  params.startDate = moment()
    .subtract(3, "months")
    .format("YYYY-MM-DDT00:00:00");

  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("/daily-viralfeed/global-search", {
          params,
        });
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get(
          "/daily-viralfeed/global-search",
          {
            params,
          }
        );
        return response.data;
      }
    } catch (error) {
      console.error("Error searching by value:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};
