import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../slices/userSlice";
import { admin } from "../api/axios";

export function useLoadingWithRefresh() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        const { data } = await admin.get("auth/me");
        dispatch(setUser(data.data));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  return { loading };
}
