import { admin, user } from "../axios";

interface boardPayload {
  board_name: string;
  owner_id: string;
  owner_name: string | undefined;
  type: string;
  images?: string[];
}

export const boardNameAvailable = async (boardName: string) => {
  const response = await user.get(
    `boards/isBoardNameAvailable?board_name=${boardName}`
  );
  return response;
};

export const createBoard = async (data: boardPayload) => {
  const response = await user.post("boards", data);
  return response;
};

export const getAllBoards = async () => {
  const response = await user.get("boards");
  return response;
};

export const getBoardByBoardId = async (boardId: string) => {
  const response = await user.get(`boards/${boardId}`);
  return response;
};

export const updateBoard = async (boardId: string) => {
  const response = await user.put(`boards/${boardId}`);
  return response;
};

export const getBoardsByUserId = async (userId: string) => {
  const response = await user.get(
    `boards/user/${userId}?access_level=owner,edit`
  );
  return response;
};

export const getBoardsByAccessLevel = async (userId: string) => {
  const response = await user.get(
    `boards/user/${userId}?access_level=owner,edit,view`
  );
  return response;
};

export const getBoardsByAccessLevelAsOwner = async (userId: string) => {
  const response = await user.get(`boards/user/${userId}?access_level=owner`);
  return response;
};

export const getBoardsSearchByAccessLevel = async (
  userId: string,
  search: string
) => {
  const response = await user.get(
    `boards/user/${userId}?access_level=owner,edit&search=${search}`
  );
  return response;
};

export const deleteBoardById = async (boardId: string) => {
  const response = await user.delete(`boards/${boardId}`);
  return response;
};
