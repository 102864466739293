import React from "react";

// eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
function BoardsIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={props.fill ? props.fill : "#4d4d4d"}
      viewBox="0 0 24 24"
    >
      <path d="M12 11.25H4.5a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5H12a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5zM20.25 3h-7.5a1.5 1.5 0 00-1.5 1.5v3.75a1.5 1.5 0 001.5 1.5h7.5a1.5 1.5 0 001.5-1.5V4.5a1.5 1.5 0 00-1.5-1.5zm0 8.25H16.5a1.5 1.5 0 00-1.5 1.5v3.75a1.5 1.5 0 001.5 1.5h3.75a1.5 1.5 0 001.5-1.5v-3.75a1.5 1.5 0 00-1.5-1.5zM8.25 3H4.5A1.5 1.5 0 003 4.5v3.75a1.5 1.5 0 001.5 1.5h3.75a1.5 1.5 0 001.5-1.5V4.5A1.5 1.5 0 008.25 3z"></path>
    </svg>
  );
}

export default BoardsIcon;
