/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */

import axios from "axios";
import Cookies from "js-cookie";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { clearUser } from "../slices/userSlice";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const admin = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const user = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const forecaster = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

admin.interceptors.request.use(
  config => {
    const token = Cookies.get("adminToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

forecaster.interceptors.request.use(
  config => {
    const token = Cookies.get("forecasterToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

user.interceptors.request.use(
  config => {
    const token = Cookies.get("forecasterToken") || Cookies.get("adminToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

admin.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 ||
      (error.response?.status === 403 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = Cookies.get("adminRefreshToken");
        const response = await admin.post(`${BASE_URL}auth/refresh-tokens`, {
          refreshToken,
        });
        const { accessToken } = response.data.data;
        Cookies.set("adminToken", accessToken, {
          expires: 1,
          sameSite: "strict",
          secure: true,
        });
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return admin(originalRequest);
      } catch (err) {
        const dispatch: AppDispatch = useDispatch();
        dispatch(clearUser());
        Cookies.remove("adminToken");
        Cookies.remove("adminRefreshToken");
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

forecaster.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 ||
      (error.response?.status === 403 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = Cookies.get("forecasterRefreshToken");

        const response = await forecaster.post(
          `${BASE_URL}auth/refresh-tokens`,
          { refreshToken }
        );
        const { accessToken } = response.data.data;
        Cookies.set("forecasterToken", accessToken);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return forecaster(originalRequest);
      } catch (err) {
        const dispatch: AppDispatch = useDispatch();
        dispatch(clearUser());
        Cookies.remove("forecasterToken");
        Cookies.remove("forecasterRefreshToken");
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

user.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 ||
      (error.response?.status === 403 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken =
          Cookies.get("forecasterRefreshToken") || Cookies.get("adminToken");

        const response = await forecaster.post(
          `${BASE_URL}auth/refresh-tokens`,
          { refreshToken }
        );
        const { accessToken } = response.data.data;
        Cookies.set("forecasterToken", accessToken);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return forecaster(originalRequest);
      } catch (err) {
        const dispatch: AppDispatch = useDispatch();
        dispatch(clearUser());
        Cookies.remove("forecasterToken");
        Cookies.remove("forecasterRefreshToken");
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

const guest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export { admin, forecaster, guest, user };
