import React, { FC, useEffect, useState } from "react";
import { ReactComponent as Share } from "../assets/icons/share.svg";
import { useNavigate } from "react-router-dom";
import CreateBoard from "../components/Boards/CreateBoard";
import Modal from "../components/Common/Modal";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { getUserId } from "../app/api/user/user";
import { getBoardsByAccessLevel } from "../app/api/admin/board";
import { Board } from "../interface/BoardsInterface";
import MultiSelect from "../components/Boards/MultiSelect";
import LockIcon from "../assets/icons/LockIcon";
import ShareBoard, { CollaboratorOb } from "../components/Boards/ShareBoard";
import { deleteCollaborator } from "../app/api/admin/collaborator";
import RemoveCollaborator from "./Boards/RemoveCollaborator";
import Skeleton from "react-loading-skeleton";

interface privateBoard {
  id: string;
  board_name: string;
  owner_id: string;
  owner_name: string;
  type: string;
  post_count: number;
  images: string[];
  collaborator_count: number;
  collaborator_initials: string[];
  created_at: string;
  updated_at: string;
}

interface BoardsCardProps {
  board: Board;
  index: number;
  boardType: string;
}

const Boards = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const user = useSelector((state: RootState) => state.user.userInfo);

  const userEmail = user?.email;

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userId, setUserId] = useState("");
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [boardData, setBoardData] = useState<privateBoard>();
  const [collaboratorModalOpen, setCollaboratorModalOpen] = useState(false);
  const [boardId, setBoardId] = useState("");
  const [removeCollaborator, setRemoveCollaborator] =
    useState<CollaboratorOb | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const onCloseCollaboratorModal = () => setCollaboratorModalOpen(false);
  const onClose = () => setIsShareModalOpen(false);

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res.data.data[0]._id);
    }
  };

  const getBoards = async () => {
    if (userId) {
      setLoading(true);
      const response = await getBoardsByAccessLevel(userId);
      setPublicBoards(response.data.data.public.boards);
      setPrivateBoards(response.data.data.private.boards);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoards();
  }, [userId]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const BoardsCard: FC<BoardsCardProps> = ({ board, index, boardType }) => {
    // Map over the board images if they exist
    const boardImages = board?.images.map(image =>
      image.startsWith(`${process.env.REACT_APP_S3_DOMAIN}`)
        ? `${process.env.REACT_APP_CLOUD_FRONT_URL}${image.split(".com/")[1]}`
        : image
    );

    // Dummy image sources from environment variables
    const addIcon = process.env.REACT_APP_ADD_ICON;
    const inspireIcon = process.env.REACT_APP_INSPIRE_ICON;

    return (
      <div
        key={index}
        className=" md:basis-1/5 sm:basis-1/3 px-8 py-3 relative"
      >
        <div className="overflow-hidden rounded-t-2xl bg-white  border border-[1px] rounded-2xl ">
          <div
            className="h-32 w-full  flex flex-row rounded-2xl hover:cursor-pointer bg-[#f2f2f2]"
            onClick={() => {
              navigate(`/boards/${board?.id}`);
            }}
          >
            {/* First Image */}
            <img
              src={
                boardImages && boardImages.length >= 2
                  ? boardImages[0]
                  : boardImages && boardImages.length === 1
                    ? boardImages[0]
                    : addIcon
              }
              alt={`${board.board_name} board image`}
              className="object-cover w-[50%] rounded-bl-2xl"
            />

            {/* Second Image */}
            <img
              src={
                boardImages && boardImages.length >= 2
                  ? boardImages[1]
                  : inspireIcon
              }
              alt={`${board.board_name} board image`}
              className="object-cover w-[50%] rounded-r-2xl"
            />
          </div>
          <div className="pt-2 pl-3 mb-1">
            <h2 className=" font-poppins font-light">
              {board.board_name.charAt(0).toUpperCase() +
                board.board_name.slice(1)}
            </h2>
            <p className="font-sans text-xs text-neutral-500">
              {board.post_count ? board.post_count : 0} Posts |{" "}
              {board.owner_name ? board.owner_name : ""}
            </p>
          </div>
        </div>
        {boardType === "private" && (
          <>
            <div className="absolute top-5 left-10 bg-white p-1 rounded-full ">
              <LockIcon />
            </div>
            <div
              className="absolute bottom-16 right-12 space-x-[-12px] flex cursor-pointer"
              onClick={event => {
                event.stopPropagation();
                setCollaboratorModalOpen(true);
                setBoardId(board?.id);
              }}
            >
              {board.collaborator_initials.slice(0, 2).map((initial, index) => (
                <div
                  key={index}
                  className="bg-[#A64B2A] text-white rounded-lg w-7 h-6 flex items-center justify-center text-sm border-2 border-white"
                >
                  {initial}
                </div>
              ))}

              {board.collaborator_initials.length > 2 && (
                <div className="bg-[#A64B2A] text-white rounded-lg w-7 h-6 flex items-center justify-center text-sm border-2 border-white">
                  {`+${board.collaborator_count - 2}`}
                </div>
              )}
            </div>
            <div className="absolute bottom-7 right-10">
              <Share
                className="text-gray-600 hover:cursor-pointer"
                onClick={event => {
                  event.stopPropagation();
                  setBoardData(board);
                  setIsShareModalOpen(true);
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const onClickRemoveCollaborator = (collaborator: CollaboratorOb) => {
    setRemoveCollaborator(collaborator);
    setIsShareModalOpen(false);
  };

  const cancelDeleteCollaborator = () => {
    setRemoveCollaborator(null);
    setIsShareModalOpen(false);
    setCollaboratorModalOpen(false);
  };

  const confirmDeleteCollaborator = () => {
    if (!removeCollaborator?.id) return;

    return deleteCollaborator(removeCollaborator?.id).then(() => {
      cancelDeleteCollaborator();
      setIsShareModalOpen(false);
      setCollaboratorModalOpen(false);
      getBoards();
    });
  };

  return (
    <>
      <div
        className={`${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} bg-lightGray pl-4 pt-4 fixed `}
      >
        {loading && (
          <div className="flex flex-wrap justify-between space-x-4">
            {Array.from({ length: 12 }).map((_, index) => (
              <div
                key={index}
                className="w-[240px] mt-4 p-0 pb-3 gap-2 border border-light rounded-2xl relative cursor-pointer"
              >
                <div className="relative w-full h-auto">
                  <Skeleton
                    height={140}
                    width={240}
                    className="rounded-t-2xl"
                  />
                  <div className="absolute bottom-2 right-2 flex space-x-1">
                    <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                      <Skeleton circle={true} height={20} width={20} />
                    </div>
                    <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                      <Skeleton circle={true} height={20} width={20} />
                    </div>
                  </div>
                </div>
                <div className="p-2 space-y-0.5">
                  <Skeleton width={100} />
                  <Skeleton width={150} />
                  <Skeleton width={120} />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="bg-white rounded-tl-lg overflow-y-scroll h-[calc(100vh-95px)]">
          {publicBoards.length === 0 && privateBoards.length === 0 ? (
            <div className="text-center text-gray-500"> No boards added</div>
          ) : (
            <div>
              {publicBoards.length === 0 ? (
                <div className="text-center text-gray-500">
                  No Public boards added
                </div>
              ) : (
                <div>
                  <div className="pt-5 pl-[31px]">
                    <h2 className="text-lg font-semibold text-gray-900 font-poppins">
                      Public
                    </h2>
                  </div>
                  <div className=" flex align-top flex-wrap mt-4">
                    {publicBoards?.map((board, index) => (
                      <BoardsCard
                        board={board}
                        index={index}
                        boardType="public"
                      />
                    ))}
                  </div>
                  <hr className="my-2 mx-6" />
                </div>
              )}
              {privateBoards.length === 0 ? (
                <div className="text-center text-gray-500">
                  No Private boards added
                </div>
              ) : (
                <div className="mb-6">
                  <div className="pt-3 pl-[31px]">
                    <h2 className="text-lg font-semibold text-gray-900 font-poppins">
                      Private
                    </h2>
                  </div>
                  <div className="flex  flex-wrap mt-4">
                    {privateBoards?.map((board, index) => {
                      return (
                        <BoardsCard
                          board={board}
                          index={index}
                          boardType="private"
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="fixed bottom-8 right-8 text-center text-white">
            <button
              className="bg-primary hover:bg-secondary py-2 px-4 rounded-full text-4xl hover:bg-lightGray border border-2 border-black bottom-2 hover:text-black"
              onClick={() => setIsModalOpen(true)}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} title="Create Board">
        <CreateBoard onClose={closeModal} userId={userId} refresh={getBoards} />
      </Modal>

      {isShareModalOpen && (
        <Modal isOpen={isShareModalOpen} onClose={onClose} title="">
          <MultiSelect
            isShareModalOpen={isShareModalOpen}
            onShareClose={onClose}
            boardId={boardData?.id || ""}
            boardName={boardData?.board_name}
            getBoards={getBoards}
          />
        </Modal>
      )}

      {collaboratorModalOpen && (
        <Modal
          isOpen={collaboratorModalOpen}
          onClose={onCloseCollaboratorModal}
          title=""
        >
          <ShareBoard
            boardId={boardId || ""}
            onClickRemoveCollaborator={onClickRemoveCollaborator}
            fromPrivateBoard={true}
            onClose={onCloseCollaboratorModal}
            userId={userId}
          />
        </Modal>
      )}

      <Modal
        isOpen={Boolean(removeCollaborator)}
        onClose={cancelDeleteCollaborator}
        title=""
      >
        <RemoveCollaborator
          removeCollaborator={removeCollaborator}
          onRemove={confirmDeleteCollaborator}
          onCancel={cancelDeleteCollaborator}
        />
      </Modal>
    </>
  );
};

export default Boards;
