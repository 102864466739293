import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { saveAs } from "file-saver";

type Props = {
  imageUrl: string;
  source: string;
  brandName?: string;
};

export const downloadImageWithTag = async (
  imageUrl: string,
  sourceTag: string,
  brandName = "",
  tagWidthPercentage = 10,
  tagHeightPercentage = 5,
  offsetXPercentage = 1
) => {
  try {
    await document.fonts.ready;
    const warmUpCanvas = document.createElement("canvas");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const warmUpCtx: any = warmUpCanvas.getContext("2d");
    warmUpCtx.font = "10px Calibri";
    warmUpCtx.fillText("Test", 0, 0);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const img = document.createElement("img");
    img.src = URL.createObjectURL(blob);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("Failed to get canvas context");
      }

      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      ctx.drawImage(img, 0, 0);

      const tagWidth = (canvas.width * tagWidthPercentage) / 100;
      const tagHeight = (canvas.height * tagHeightPercentage) / 100;
      const fontSize = tagHeight * 0.6;

      ctx.font = `${fontSize}px Calibri`;
      ctx.textAlign = "right";
      ctx.textBaseline = "middle";

      const paddingX = 10;
      const text = sourceTag ? `IG/${sourceTag}` : brandName;
      const textWidth = ctx.measureText(text).width;
      const adjustedTagWidth = Math.max(tagWidth, textWidth + paddingX * 3);
      const offsetX = (canvas.width * offsetXPercentage) / 100;
      const rectX = canvas.width - adjustedTagWidth - paddingX + offsetX;
      const rectY = 0;
      const cornerRadius = 20;
      ctx.fillStyle = "#6B72807F";
      ctx.beginPath();
      ctx.moveTo(rectX + adjustedTagWidth, rectY);
      ctx.lineTo(rectX + adjustedTagWidth, rectY + tagHeight);
      ctx.lineTo(rectX + cornerRadius, rectY + tagHeight);
      ctx.quadraticCurveTo(
        rectX,
        rectY + tagHeight,
        rectX,
        rectY + tagHeight - cornerRadius
      );
      ctx.lineTo(rectX, rectY);
      ctx.lineTo(rectX + adjustedTagWidth, rectY);
      ctx.closePath();
      ctx.fill();

      ctx.fillStyle = "white";
      ctx.fillText(
        text,
        canvas.width - paddingX * 2 + offsetX,
        rectY + tagHeight / 2
      );

      canvas.toBlob(taggedBlob => {
        if (taggedBlob) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(taggedBlob);
          link.download = `${sourceTag}_${imageUrl.split("/").pop()}`;
          link.click();
          URL.revokeObjectURL(link.href);
          toast.success("Image with source tag downloaded successfully!", {
            hideProgressBar: true,
          });
        } else {
          throw new Error("Failed to create a blob from the canvas");
        }
      }, "image/png");
    };
  } catch (error) {
    toast.error("Failed to download image with source tag.", {
      hideProgressBar: true,
    });
  }
};

export const downloadImageWithoutTag = async (
  imageUrl: string,
  source: string
) => {
  try {
    const response = await axios.get(imageUrl, { responseType: "blob" });

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const fileName = `${source}_${imageUrl.split("/").pop()}`;
    saveAs(blob, fileName);
    toast.success("Image downloaded successfully without source tag.", {
      hideProgressBar: true,
    });
  } catch (error) {
    toast.error("Failed to download image without source tag.", {
      hideProgressBar: true,
    });
  }
};

const DownloadImage: React.FC<Props> = ({ imageUrl, source, brandName }) => {
  const [includeSource, setIncludeSource] = useState(false);
  const handleDownload = () => {
    if (includeSource) {
      downloadImageWithTag(imageUrl, source, brandName);
    } else {
      downloadImageWithoutTag(imageUrl, source);
    }
  };

  return (
    <div className="flex justify-center items-center h-[60%] bg-gray-100">
      <div>
        <div className="relative">
          <img
            src={imageUrl}
            alt="Selected"
            className="w-full rounded-lg mb-4"
          />
          {includeSource && (
            <div className="absolute top-0 right-0 bg-translucent text-white rounded-bl-lg rounded-tr-lg px-3 py-1 text-sm">
              {source ? `IG/${source}` : brandName}
            </div>
          )}
        </div>
        <div className="flex items-center justify-between mb-4">
          <div>
            <input
              type="checkbox"
              id="sourceTag"
              className="mr-2"
              checked={includeSource}
              onChange={() => setIncludeSource(!includeSource)}
            />
            <label htmlFor="sourceTag" className="text-gray-700">
              Include Source Tag
            </label>
          </div>
          <button
            className="bg-black text-sm font-bold text-white px-3.5 py-2 rounded-3xl hover:bg-gray-800"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadImage;
