import { admin } from "../axios";

interface ticketPayload {
  image: string;
  source: string;
  issueType: string;
  liveSince: string;
  createdBy: string;
  priority: string;
  email: string;
  reason: string;
  status: string;
  postId: string;
  userId: string;
  ticketMarked: string;
}

interface updateData {
  id: string;
  postId: string;
  adminAction: boolean;
}
export const getAllTickets = async () => {
  const getTickets = await admin.get("/tickets");
  return getTickets.data.data;
};

export const createTicket = async (data: ticketPayload) => {
  return admin.post("/tickets", data);
};

export const getTicketById = async (id: string) => {
  try {
    const response = await admin.get(`/tickets/ticket/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching ticket by ID:", error);
    throw error;
  }
};

export const updateStatus = async (
  data: updateData
): Promise<number | undefined> => {
  try {
    const response = await admin.put(`/tickets/ticket/${data.id}`, data);
    return response.status;
  } catch (error) {
    console.error("Error updating post status:", error);
    return undefined; // Return undefined in case of error
  }
};

export const dropDownData = async () => {
  try {
    const response = await admin.get(`/tickets/ticket-reason`);
    return response.data.data;
  } catch (error) {
    console.error("Unable to fetch issues");
  }
};
