import { admin, user } from "../axios";

interface contentPayload {
  board_ids: string[];
  category_name: string;
  postId: string;
  notes?: string;
}

interface dailyContent {
  board_ids: string[];
  category_name: string;
  date: string;
  description: string;
  media_urls: string;
  title: string;
  dailyNewsId: string | undefined;
  url: string;
  source: string;
}

interface innovationContent {
  board_ids: string[];
  category_name: string;
  date: string;
  description: string;
  media_urls: string;
  title: string;
  innovationFeedId: string | undefined;
  url: string;
  source: string;
  notes?: string;
}

interface uploadContentPayload {
  board_ids: string[];
  category_name: string;
  description: string;
  media_urls: string | null;
  brand: string;
}

export const addContent = async (data: contentPayload) => {
  const response = await user.post("contents", data);
  return response;
};

export const addContentUpload = async (data: uploadContentPayload) => {
  const response = await admin.post("contents", data);
  return response;
};

export const getAllContent = async () => {
  const response = await admin.get("contents");
  return response;
};

export const getContentByBoard = async (boardIds: string, pageSize: number) => {
  const response = await admin.get(
    `contents?board_ids=${boardIds}&page=${pageSize}&limit=30`
  );
  return response;
};

export const getContentByCategory = async (
  categoryName: string,
  boardIds: string
) => {
  const response = await admin.get(
    `contents?category_name=${categoryName}&board_ids=${boardIds}`
  );
  return response;
};

export const deleteContentById = async (boardId: string) => {
  const response = await admin.delete(`contents/${boardId}`);
  return response;
};

export const addContentToDaily = async (data: dailyContent) => {
  const response = await user.post("contents", data);
  return response;
};

export const addContentToInnovation = async (data: innovationContent) => {
  const response = await user.post("contents", data);
  return response;
};
