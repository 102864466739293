import { createContext, ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Dashboard/Sidebar";
import TopNav from "../components/Dashboard/TopNav";
type Props = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
};

//Creating the context for sidenav
type SidebarContextType = {
  isSidebarOpen: boolean;
};

const defaultValue: SidebarContextType = {
  isSidebarOpen: false,
};
export const SidebarContext = createContext(defaultValue);

type SidebarProviderProps = {
  children: ReactNode;
  isSidebarOpen: boolean;
};

//provider of context
const SidebarContextProvider: React.FC<SidebarProviderProps> = ({
  children,
  isSidebarOpen,
}) => (
  <SidebarContext.Provider value={{ isSidebarOpen }}>
    {children}
  </SidebarContext.Provider>
);

const DashboardLayout = ({ isSidebarOpen, setIsSidebarOpen }: Props) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
    setIsSidebarOpen(!isSidebarCollapsed);
  };

  return (
    <div className="font-avenir flex ">
      <div
        className={`fixed top-0 left-0 h-screen bg-white shadow-md transition-all duration-500 ease-in-out ${isSidebarCollapsed ? "w-24" : "w-64"} `}
      >
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          onToggle={handleSidebarToggle}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div
        className={`flex-grow transition-all duration-500 ease-in-out ${isSidebarCollapsed ? "ml-24" : "ml-64"} h-full`}
      >
        <div
          className={`${isSidebarCollapsed ? "w-[calc(100vw-96px)]" : "w-[calc(100vw-260px)]"} h-[80px] z-10 bg-white fixed`}
        >
          <TopNav />
        </div>
        <SidebarContextProvider isSidebarOpen={isSidebarOpen}>
          <main className="bg-slate-100 mt-[80px]">
            <Outlet />
          </main>
        </SidebarContextProvider>
      </div>
    </div>
  );
};

export default DashboardLayout;
