import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: "v4",
});

const s3 = new AWS.S3();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadImage = async (key: string, file: any) => {
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const awsRegion = process.env.REACT_APP_AWS_REGION;

  // Check if the environment variables are defined
  if (!bucketName) {
    throw new Error("Bucket name is undefined.");
  }

  if (!awsRegion) {
    throw new Error("AWS region is undefined.");
  }

  const params = {
    Bucket: bucketName,
    Key: key,
    Body: file,
    ContentType: file.type,
  };

  await s3.putObject(params).promise();

  return {
    key,
    url: `https://${bucketName}.s3.${awsRegion}.amazonaws.com/${key}`,
  };
};
