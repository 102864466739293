import { useEffect, useState, createRef } from "react";
import {
  getCollaboratorByBoardId,
  updateCollaborator,
} from "../../app/api/admin/collaborator";
import Dropdown from "../Common/Dropdown";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";

export interface CollaboratorOb {
  id: string;
  board_id: string;
  user_id: string;
  user_name: string;
  initials: string;
  access_level: string;
  email?: string;
}
interface ShareBoardProps {
  boardId: string;
  onClickRemoveCollaborator: (ob: CollaboratorOb) => void;
  fromPrivateBoard?: boolean;
  onClose?: () => void | undefined;
  getBoardsList?: (option: boolean) => void;
  userId?: string;
}

function ShareBoard(props: ShareBoardProps) {
  const { boardId, fromPrivateBoard, onClose, getBoardsList, userId } = props;
  const AccessOptions = ["can view", "can edit"];

  if (fromPrivateBoard) {
    AccessOptions.push("remove");
  }

  const [collaborators, setCollaborators] = useState<CollaboratorOb[]>([]);
  const dropdownRefs = collaborators.map(() => createRef<HTMLDivElement>());
  const dropdownContainerRef = createRef<HTMLDivElement>();

  const getCollaboratorsData = () => {
    getCollaboratorByBoardId(boardId).then(res => {
      setCollaborators(res.data.data.collaborators);
    });
  };

  useEffect(() => {
    if (boardId) {
      getCollaboratorsData();
    }
  }, [boardId]);

  const handleAccessChange = (
    access_level: string,
    collaborator: CollaboratorOb
  ) => {
    access_level =
      access_level === "can view"
        ? "view"
        : access_level === "can edit"
          ? "edit"
          : access_level;

    if (collaborator.access_level === access_level) return;

    if (access_level === "remove") {
      props.onClickRemoveCollaborator(collaborator);
      getBoardsList?.(true);
      onClose?.();
    } else {
      return updateCollaborator(collaborator.id, { access_level }).then(() =>
        getCollaboratorsData()
      );
    }
  };

  const ownerOb = collaborators.find(i => i.access_level === "owner");
  const canChangePermissions = ownerOb ? ownerOb.user_id === userId : false;

  const handleDropdownOpen = (index: number) => {
    const dropdownRef = dropdownRefs[index]?.current;
    const containerRef = dropdownContainerRef?.current;

    if (containerRef && dropdownRef) {
      const isLastItem = index === collaborators.length - 1;

      if (isLastItem) {
        setTimeout(() => {
          containerRef.scrollTo({
            top: containerRef.scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      } else {
        const dropdownBottom = dropdownRef.offsetTop + dropdownRef.offsetHeight;
        const containerBottom =
          containerRef.scrollTop + containerRef.offsetHeight;

        if (dropdownBottom > containerBottom) {
          containerRef.scrollTo({
            top: dropdownBottom - containerRef.offsetHeight + 20,
            behavior: "smooth",
          });
        } else if (dropdownRef.offsetTop < containerRef.scrollTop) {
          containerRef.scrollTo({
            top: dropdownRef.offsetTop,
            behavior: "smooth",
          });
        }
      }
    }
  };

  return (
    <div>
      <div className="bg-white rounded-lg shadow-lg">
        {fromPrivateBoard && (
          <div className="flex flex-row items-center justify-between">
            <div className="gap-4 flex flex-row">
              <div className="mt-1.5 cursor-pointer">
                <LeftArrow onClick={() => onClose?.()} />
              </div>
              <p className="text-primary text-base mb-2">
                {collaborators.length} Collaborators in this file
              </p>
            </div>
            <button onClick={() => onClose?.()} className="text-3xl">
              &times;
            </button>
          </div>
        )}
        <div className="overflow-y-auto h-[300px]" ref={dropdownContainerRef}>
          {collaborators.map((collaborator, index) => {
            const isOwner = collaborator.access_level === "owner";
            const accessLevel = AccessOptions.find(item =>
              item.includes(collaborator.access_level)
            );

            return (
              <div
                key={index}
                className="flex flex-wrap items-center mb-2 flex justify-between"
                ref={dropdownRefs[index]} // Assign ref to each collaborator row
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 bg-gray-200 rounded-full flex items-center text-sm border-2 justify-center text-gray-700 font-normal mr-4">
                    {collaborator.initials}
                  </div>
                  <div>
                    <div className="flex-grow text-base">
                      {collaborator.user_name}
                    </div>
                    <div className="flex-grow text-xs text-secondary">
                      {collaborator?.email}
                    </div>
                  </div>
                </div>

                <div>
                  {isOwner ? (
                    <span className="text-xs text-red-500 bg-[#B71C1C26] font-bold border rounded-lg px-1.5 py-0.5 ml-2">
                      Owner
                    </span>
                  ) : canChangePermissions ? (
                    <div className="w-[110px]">
                      <Dropdown
                        label={accessLevel || "Select Access"}
                        options={AccessOptions}
                        onSelect={value =>
                          handleAccessChange(value, collaborator)
                        }
                        onOpen={() => handleDropdownOpen(index)}
                      />
                    </div>
                  ) : (
                    <div
                      className={`w-[110px] mt-0 block pl-3 pr-5 py-1 text-center text-base border border-default rounded-lg mt-1`}
                    >
                      {accessLevel?.replace(/\b\w/g, char =>
                        char.toUpperCase()
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ShareBoard;
