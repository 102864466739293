import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prettier/prettier
function CrossIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#4D4D4D"
        fillRule="evenodd"
        d="M6.007 7.615l4.041 4.037A1.144 1.144 0 0012 10.844c0-.303-.12-.593-.335-.807L7.622 6l4.042-4.037A1.142 1.142 0 1010.047.35l-4.04 4.037L1.966.349A1.143 1.143 0 10.349 1.963L4.39 6 .35 10.037a1.142 1.142 0 101.617 1.614l4.04-4.036z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CrossIcon;
