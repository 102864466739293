import React, { useState } from "react";
import { ReactComponent as DropdownArrow } from "../../assets/icons/dropdownArrow.svg";

interface DropdownProps {
  label: string;
  options: string[];
  onSelect: (option: string) => void;
}

const Dropdown2: React.FC<DropdownProps> = ({ label, options, onSelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelect = (option: string) => {
    onSelect(option);
    setDropdownOpen(false);
  };

  return (
    <div className="relative w-[180px] h-[32px]">
      <button
        className="mt-1 block w-full pl-3 pr-10  text-left text-base border border-gray-300 rounded-lg"
        onClick={toggleDropdown}
      >
        <span className="text-secondary text-xs">{label}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <span
            className={`transition-transform duration-300 ${
              dropdownOpen ? "rotate-180" : ""
            }`}
          >
            <DropdownArrow className="w-2 h-2" />
          </span>
        </span>
      </button>
      {dropdownOpen && (
        <div className="absolute bottom-full mb-1 w-full rounded-md shadow-lg bg-white z-10">
          <div className="py-1">
            {options.map((option, index) => (
              <button
                key={index}
                className={`block w-full text-left px-4 py-2 text-xs text-secondary  capitalize  ${index !== options.length - 1 ? "border-b-2 border-light" : ""}`}
                onClick={() => handleSelect(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown2;
