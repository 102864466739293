import React, { useState, useEffect } from "react";
import { ReactComponent as DownloadImage } from "../../assets/icons/downloadImageSentiment.svg";
import { ReactComponent as ExpandImage } from "../../assets/icons/expandImageSentiment.svg";
import { copyImageToClipboard } from "copy-image-clipboard";
import { toast } from "react-toastify";
import ExpandView2 from "./ExpandView";
import NoPhoto from "../../assets/icons/noPhoto.png";
import { ColorRing } from "react-loader-spinner";

interface ImageSectionProps {
  images: string[];
  generated: boolean;
}

const ImageSection: React.FC<ImageSectionProps> = ({ images, generated }) => {
  const [isExpandViewOpen, setExpandViewOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [validImages, setValidImages] = useState<string[]>([]); // State for valid images
  const [validUrlLoading, setValidUrlLoading] = useState(false);

  useEffect(() => {
    setValidUrlLoading(true);
    const checkImages = async () => {
      setValidUrlLoading(true);
      const validUrls = [];

      for (const imageUrl of images) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_PROXY_URL}${imageUrl}`
          );
          if (!response.ok) throw new Error("Image not accessible");

          const blob = await response.blob();
          if (
            blob.size > 0 &&
            (blob.type === "image/jpeg" || blob.type === "image/png")
          ) {
            validUrls.push(imageUrl);
          }
        } catch (error) {
          // Image Error
        }
      }
      setValidImages(validUrls);
      setValidUrlLoading(false);
    };
    checkImages();
  }, [images]);

  const handleCopyImageToClipboard = async (imageUrl: string) => {
    try {
      await copyImageToClipboard(
        `${process.env.REACT_APP_PROXY_URL}${imageUrl}`
      );
      toast.success("Image copied", {
        hideProgressBar: true,
      });
    } catch (error) {
      toast.error("Error copying image", {
        hideProgressBar: true,
      });
    }
  };

  const handleExpandedView = (index: number) => {
    setSelectedImageUrl(validImages[index]);
    setSelectedIndex(index);
    setExpandViewOpen(true);
  };

  const closeExpandedView = () => {
    setExpandViewOpen(false);
    setSelectedImageUrl(null);
  };

  const handleImageError =
    (index: number) => (event: React.SyntheticEvent<HTMLImageElement>) => {
      event.currentTarget.src = NoPhoto; // Set the fallback image on error=>To not show in expanded view
      event.currentTarget.style.display = "none"; // Hide the image
      images.splice(index, 1);
    };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closeExpandedView();
    }
  };

  if (validImages.length === 0 && generated && validUrlLoading === false) {
    return (
      <div className="h-full flex justify-center items-center">
        <span className="text-gray-400 ml-4">No Images Found</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-[160px]">
      <div className="mb-4 flex flex-col items-center gap-[14px] overflow-y-scroll h-[calc(100vh-248px)] scroller">
        {validUrlLoading ? (
          <div className="flex justify-center items-center h-full">
            <ColorRing
              visible={true}
              height="60"
              width="60"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#212121", "#4d4d4d", "#D3C0B6", "#9e9e9e", "#f8f6f4"]}
            />
          </div>
        ) : (
          validImages.map((url, index) => (
            <div className="relative image-container group" key={index}>
              <img
                src={url}
                alt={`image-${index}`}
                className="w-[156px] h-[120px] object-cover rounded-lg shadow-md transition transform ease-in-out hover:scale-110 hover:opacity-80"
                onError={handleImageError(index)}
              />
              {url !== NoPhoto && ( // Only show buttons if it's not the fallback image
                <div className="absolute bottom-2 right-1 flex space-x-1 cursor-pointer">
                  <div
                    className="button-container hidden group-hover:flex"
                    onClick={() => handleCopyImageToClipboard(url)}
                  >
                    <DownloadImage />
                  </div>
                  <div
                    className="button-container hidden group-hover:flex"
                    onClick={() => handleExpandedView(index)}
                  >
                    <ExpandImage />
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {isExpandViewOpen && selectedImageUrl && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center"
          onClick={handleOverlayClick}
        >
          <ExpandView2
            imageUrl={selectedImageUrl}
            images={validImages} // Pass valid images to the expand view
            selectedIndex={selectedIndex}
          />
        </div>
      )}
    </div>
  );
};

export default ImageSection;
