import { toast } from "react-toastify";
import moment from "moment";
import { AxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";

export const stringShortener = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

export const validateEmail = (email: string) => {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const capitalizeFirstLetter = (str: string) => {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const copyImageToClipboard = async (
  imageUrl: string,
  sourceTag: string,
  tagWidthPercentage = 10,
  tagHeightPercentage = 5,
  offsetXPercentage = 1
) => {
  try {
    await document.fonts.ready;
    const warmUpCanvas = document.createElement("canvas");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const warmUpCtx: any = warmUpCanvas.getContext("2d");
    warmUpCtx.font = `10px Calibri`;
    warmUpCtx.fillText("Test", 0, 0);

    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const img = document.createElement("img");
    img.src = URL.createObjectURL(blob);

    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("Failed to get canvas context");
      }

      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      ctx.drawImage(img, 0, 0);

      const tagWidth = (canvas.width * tagWidthPercentage) / 100;
      const tagHeight = (canvas.height * tagHeightPercentage) / 100;
      const fontSize = tagHeight * 0.6;
      ctx.font = `${fontSize}px Calibri`;
      ctx.textAlign = "right";
      ctx.textBaseline = "middle";

      const paddingX = 10;
      const paddingY = 8;

      const textWidth = ctx.measureText(sourceTag).width;

      const adjustedTagWidth = Math.max(tagWidth, textWidth + paddingX * 4);
      const offsetX = (canvas.width * offsetXPercentage) / 100;

      const rectX = canvas.width - adjustedTagWidth - paddingX + offsetX;
      const rectY = 0;

      const cornerRadius = 20;
      ctx.fillStyle = "#6B72807F";
      ctx.beginPath();
      ctx.moveTo(rectX + adjustedTagWidth, rectY);
      ctx.lineTo(rectX + adjustedTagWidth, rectY + tagHeight);
      ctx.lineTo(rectX + cornerRadius, rectY + tagHeight);
      ctx.quadraticCurveTo(
        rectX,
        rectY + tagHeight,
        rectX,
        rectY + tagHeight - cornerRadius
      );
      ctx.lineTo(rectX, rectY);
      ctx.lineTo(rectX + adjustedTagWidth, rectY);
      ctx.closePath();
      ctx.fill();

      ctx.fillStyle = "white";
      ctx.fillText(
        sourceTag,
        canvas.width - paddingX * 4 + offsetX,
        rectY + tagHeight / 2
      );

      canvas.toBlob(async taggedBlob => {
        if (taggedBlob) {
          await navigator.clipboard.write([
            new ClipboardItem({
              [taggedBlob.type]: taggedBlob,
            }),
          ]);
          toast.success("Image with source tag copied to clipboard!", {
            hideProgressBar: true,
          });
        } else {
          throw new Error("Failed to create a blob from the canvas");
        }
      }, "image/png");
    };
  } catch (error) {
    toast.error("Failed to copy image to clipboard.", {
      hideProgressBar: true,
    });
  }
};

export const formatDate = (inputDate: string | undefined) => {
  if (inputDate === undefined) {
    return "Unavaiable";
  } else {
    const date = new Date(inputDate);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }
};
export const formatDateTimeToReadable = (isoString: string): string => {
  const date = new Date(isoString);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours() % 12 || 12; // Convert to 12-hour format
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = date.getUTCHours() >= 12 ? "PM" : "AM";

  return `${day} ${month} ${year}  ,  ${hours}:${minutes} ${ampm}`;
};

export const formatDateWithTime = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();
  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day}-${month}-${year} , ${hours}:${minutes} ${ampm}`;
};
export const handleError = (error: unknown) => {
  if (error instanceof AxiosError) {
    toast.error(error.response?.data?.message || "Error inviting user", {
      hideProgressBar: true,
    });
  } else if (error instanceof Error) {
    toast.error(error.message || "Unknown error occurred", {
      hideProgressBar: true,
    });
  } else {
    toast.error("An unexpected error occurred", {
      hideProgressBar: true,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFileName = (file: any) => {
  const fileExtension = file.name.split(".").pop();
  const fileName = `${uuidv4()}.${fileExtension}`;

  return fileName;
};

export const getKeyForS3DesignerPage = (fileName: string) => {
  return `embeddings/searchEmbeddings/${fileName}`;
};
export const getKeyForS3GenerativeFeature = (fileName: string) => {
  return `generative-features/searchEmbeddings/${fileName}`;
};
export const compressImage = async (file: File): Promise<File> => {
  const options = {
    maxSizeKB: 300,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    return await imageCompression(file, options);
  } catch (error) {
    console.error("Error compressing image:", error);
    return file;
  }
};

export const convertMarkdownToText = (markdown: string) => {
  return markdown.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, "$1");
};
