import * as Yup from "yup";

export const targetValidationSchema = Yup.object().shape({
  brandName: Yup.string().trim().required("Brand Name is required"),
  socialHandle: Yup.string().trim().required("Social Handle is required"),
  instagramUsername: Yup.string()
    .trim()
    .required("Instagram Username is required"),
  description: Yup.string()
    .trim()
    .max(250, "Description must be 250 characters or less")
    .required("Description is required"),
  genre: Yup.string(),
  brandWeightage: Yup.number()
    .min(1, "Brand weightage must be at least 1")
    .max(10, "Brand weightage must be 10 or less")
    .required("Brand Weightage is required"),
  source: Yup.string().required("Source is required"),
  region: Yup.string(),
});

export interface TargetInterface {
  _id?: string;
  brandName: string;
  socialHandle: string;
  userName: string;
  description: string;
  genre: string;
  brandWeightage: number;
  source: string;
  region?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: string;
}
