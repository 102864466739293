import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { ReactComponent as Triangle } from "../../assets/icons/triangle.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/api/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, AppDispatch } from "../../app/store";
import { clearUser } from "../../app/slices/userSlice";
import { capitalizeFirstLetter, stringShortener } from "../../utils";
import { getDropdownData } from "../../app/api/viralFeed";
import { useNotifications } from "../../app/hooks/GetNotication";
import Cookies from "js-cookie";
import { INotification } from "../../interface/INotification";
import NotificationModal from "../Notification/NotificationModal";

type Props = {};

const TopNav = (props: Props) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState<
    INotification[]
  >([]);
  const notificationRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const bellIconRef = useRef<HTMLDivElement>(null);
  const logoutDropdownArrowRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.userInfo);
  const notifications = useNotifications();

  const { name, group } = user || {};

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      dispatch(clearUser());
      Cookies.remove("isAuthenticated");
      navigate("/auth/login");
    } catch (error) {
      toast.error("Error logging out", {
        hideProgressBar: true,
      });
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !logoutDropdownArrowRef.current?.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node) &&
      !bellIconRef.current?.contains(event.target as Node)
    ) {
      setNotificationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    setUnreadNotifications(
      notifications.filter(notification => !notification.isRead)
    );
  }, [notifications]);
  return (
    <div className="flex justify-end items-center p-4 bg-white border-b border-l border-gray-200 top-0">
      <div className="flex items-center space-x-4">
        <div
          className="relative bg-yellow-100 p-2 rounded-xl cursor-pointer"
          onClick={e => {
            e.stopPropagation();
            setNotificationOpen(!isNotificationOpen);
          }}
          ref={bellIconRef}
        >
          <BellIcon className="w-6 h-6" />
          {/* Triangle Pointer */}
          {unreadNotifications.length > 0 && (
            <div className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
              {unreadNotifications.length}
            </div>
          )}

          {isNotificationOpen && (
            <div className="absolute top-10 left-1 w-full h-full">
              <Triangle />
            </div>
          )}
        </div>
        <div className="flex items-center space-x-2 pl-4">
          <div className="bg-active text-center text-black font-bold rounded-xl w-10 h-10 flex items-center justify-center">
            {user?.name
              ? user.name
                  .toUpperCase()
                  .split(" ")
                  .map(name => name.charAt(0))
                  .join("")
              : "?"}
          </div>
          <div className="text-left pl-2">
            <div className="text-black font-medium">
              {stringShortener(name || "", 10) || "NA"}
            </div>
            <div className="text-gray-500 text-sm">
              {group ? capitalizeFirstLetter(group) : "NA"}
            </div>
          </div>
          <div ref={logoutDropdownArrowRef}>
            <ArrowIcon
              className={`w-4 h-4 transition-transform duration-300 ${isDropdownOpen ? "rotate-180" : ""}`}
              onClick={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
            />
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute z-20 right-4 top-16 bg-white border border-gray-200 rounded-lg shadow-lg w-48 p-4 transition-opacity duration-300 opacity-100"
        >
          <button
            className="bg-red-500 text-white font-bold text-sm py-2 px-4 rounded-full w-full focus:outline-none hover:bg-red-600"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}
      {isNotificationOpen && (
        <div
          className="absolute z-100 right-20 top-20 bg-white border border-gray-200 rounded-lg shadow-lg w-64 transition-opacity duration-300 opacity-100"
          ref={notificationRef}
        >
          {/* Triangle Pointer above Notification Box */}
          <NotificationModal notifications={notifications} />
        </div>
      )}
    </div>
  );
};

export default TopNav;
