import React from "react";
import { deleteBoardById } from "../../app/api/admin/board";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface deleteBoardProps {
  onClose: () => void;
  boardName: string;
  boardId: string;
}
const DeleteBoard: React.FC<deleteBoardProps> = ({
  onClose,
  boardId,
  boardName,
}) => {
  const navigate = useNavigate();

  const handleDeleteBoard = async () => {
    try {
      await deleteBoardById(boardId);
      await toast.success("Successfully deleted the board", {
        hideProgressBar: true,
      });
      navigate("/boards");
    } catch (e) {
      toast.error("Error while deleting the board", {
        hideProgressBar: true,
      });
    }
  };
  return (
    <div className="flex items-center justify-center  bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">
          Are you sure you want to delete {boardName}?
        </h2>
        <p className="text-gray-600 mb-6">
          Once removed the content will be lost and cannot be recovered.
        </p>
        <div className="flex justify-between">
          <button
            className="bg-white text-gray-600 border border-gray-300 rounded-full px-6 py-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            className="bg-red-600 text-white rounded-full px-6 py-2"
            onClick={handleDeleteBoard}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBoard;
