import React, { ChangeEvent, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { boardSelection } from "../../pages/Boards/BoardImages";
import { addContentUpload } from "../../app/api/admin/content";
import { uploadImage } from "../../app/api/uploadTos3";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

interface uploadBoardProps {
  activeTab: boardSelection | undefined;
  onClose: () => void;
  refresh: (a: string) => void;
}

const UploadToBoard: React.FC<uploadBoardProps> = ({
  activeTab,
  onClose,
  refresh,
}) => {
  const [brandName, setBrandName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const imageRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFileName = (file: any) => {
    const fileExtension = file.name.split(".").pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    return fileName;
  };

  const getKeyForS3 = (boardId: string, fileName: string) => {
    return `boards/${boardId}/${fileName}`;
  };

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setLoading(true);
      const fileName = getFileName(file);
      const key = getKeyForS3(activeTab?.id as string, fileName);
      const res = await uploadImage(key, file);
      setLoading(false);
      setImage(`${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`);
      setImageUrl(res.url);
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        board_ids: [activeTab?.id || ""],
        category_name: "Uploaded",
        description: description,
        media_urls: imageUrl,
        brand: brandName,
      };

      await addContentUpload(payload);
      onClose();
      refresh(activeTab?.id as string);
      toast.success("Uploaded content successfully", {
        hideProgressBar: true,
      });
    } catch (e) {
      toast.error("Failed to upload the content.", {
        hideProgressBar: true,
      });
    }
  };

  const handleImageButtonClick = () => {
    imageRef.current?.click?.();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-1/3 p-6 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            Upload to Board - {activeTab?.board_name}
          </h2>
          <button className="text-gray-500 hover:text-gray-700">
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="flex">
          <input
            type="file"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            ref={imageRef}
            hidden
            id="accept-photo"
            accept=".jpg, .jpeg, .png , .gif, .svg"
          />
          <div
            className="w-1/2 flex items-center justify-center border border-gray-300 rounded-[10px] bg-gray-100 h-48 cursor-pointer"
            onClick={handleImageButtonClick}
          >
            {!image ? (
              <>
                {loading ? (
                  <div className="flex justify-center items-center h-full">
                    <Oval
                      height={50}
                      width={50}
                      color="black"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="black"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : (
                  <span className="text-gray-500">+ Upload Image</span>
                )}
              </>
            ) : (
              <div>
                <img
                  src={image}
                  alt="Uploaded Preview"
                  className="object-cover w-full h-48"
                />
              </div>
            )}
          </div>
          <div className="w-1/2 pl-4">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                value={brandName}
                className="mt-1 block w-full border border-gray-300 p-1 pl-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={e => setBrandName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                className="mt-1 block w-full border border-gray-300  p-1 pl-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={e => setDescription(e.target.value)}
                maxLength={250}
                value={description}
              ></textarea>
              <p className="text-sm text-gray-500 mt-1">
                {description.length}/250 character limit
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <button
            className="bg-gray-200 text-gray-700 rounded-full px-6 py-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            className="bg-black text-white rounded-full px-6 py-2"
            onClick={handleSubmit}
          >
            Add to Board
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadToBoard;
