import { admin, forecaster, guest } from "./axios";
import Cookies from "js-cookie";
import { setUser, clearUser } from "../slices/userSlice";
import { AppDispatch, RootState } from "../store";
import { useSelector } from "react-redux";

export const inviteUser = async (
  fullName: string,
  email: string,
  groupName: string
) => {
  try {
    const response = await admin.post(`admin/users/invite`, {
      fullName,
      email,
      groupName,
    });
    return response;
  } catch (error: unknown) {
    console.error("Error inviting user", error);
    throw error;
  }
};

export const acceptInvite = async (
  email: string,
  tempPassword: string,
  newPassword: string
) => {
  const response = await admin.post(`auth/verify-temp-password`, {
    email,
    tempPassword,
    newPassword,
  });
  return response;
};

export const forgotPassword = async (email: string) => {
  const response = await admin.post(`auth/forgot-password`, {
    email,
  });
  return response;
};

export const resetPassword = async (
  email: string,
  confirmCode: string,
  newPassword: string
) => {
  const response = await admin.post(`auth/confirm-forgot-password`, {
    email,
    confirmCode,
    newPassword,
  });
  return response;
};

export const isAuthenticated = () => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const isAuthenticated = Cookies.get("isAuthenticated");
  if (user && isAuthenticated) {
    return true;
  }
  return false;
};

export const UserDetail = async () => {
  const response = await forecaster.get(`auth/me`);
  return response;
};

export const userLogin =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    const response = await guest.post(`auth/login`, {
      email,
      password,
    });
    const { user } = response.data.data;
    dispatch(setUser(user));
    Cookies.set("isAuthenticated", "true", { expires: 30 });
    return response;
  };

export const logout = () => async (dispatch: AppDispatch) => {
  await forecaster.get(`auth/signout`);
  dispatch(clearUser());
  return true;
};

export const disableUser = async (userId: string) => {
  const response = await admin.put(`admin/users/${userId}/disable`);
  return response;
};

export const enableUser = async (userId: string) => {
  const response = await admin.put(`admin/users/${userId}/enable`);
  return response;
};
