import React from "react";
import { TargetInterface } from "../../interface/TargetInterface";

interface TargetRowProps {
  rowId: number;
  target: TargetInterface;
  onClick: (target: TargetInterface) => void;
  genreOptions: { _id: string; name: string }[];
  genre: string;
}

const TargetRow: React.FC<TargetRowProps> = ({
  rowId,
  target,
  onClick,
  genreOptions,
  genre,
}) => {
  const formattedDate = target.createdAt
    ? new Date(target.createdAt)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(",", "")
    : "N/A";

  const stringGenre =
    genreOptions.find(g => g._id === target.genre)?.name || "";

  return (
    <div
      className="flex items-stretch bg-white hover:bg-brandPrimarySubtle hover:bg-opacity-15 cursor-pointer border-b-2 border-light"
      onClick={() => onClick(target)}
    >
      <div className="w-[15%] border-r-2 border-light px-6 py-2 text-sm text-primary">
        {rowId}
      </div>
      <div className="w-[30%] border-r-2 border-light px-6 py-2 text-sm text-primary">
        {target.brandName}
      </div>
      <div className="w-[17.5%] border-r-2 border-light px-6 py-2 text-sm text-primary">
        {genre}
      </div>
      <div className="w-[17.5%] border-r-2 border-light px-6 py-2 text-sm text-primary">
        {target.brandWeightage}
      </div>
      <div className="w-[20%] border-r-2 border-light px-6 py-2 text-sm text-primary">
        {formattedDate}
      </div>
    </div>
  );
};

export default TargetRow;
