import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { acceptInvite } from "../../app/api/auth";
import { ReactComponent as ICHNextLogo } from "../../assets/icons/ichNextLogo.svg";
import { ReactComponent as Eyebutton } from "../../assets/icons/EyeButton.svg";

type Props = {};

const AcceptInvitation = (props: Props) => {
  const navigate = useNavigate();
  const [acceptInviteError, setAcceptInviteError] = useState(false);

  const [showTempPassword, setShowTempPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    tempPassword: Yup.string().required("Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .max(64, "Password must be at most 64 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*]/,
        "Password must contain at least one special character (!@#$%^&*)"
      )
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Required"),
  });

  const initialValues = {
    email: "",
    tempPassword: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await acceptInvite(values.email, values.tempPassword, values.password);
      navigate("/auth/login");
      toast.success("Password set successfully. Please login.", {
        hideProgressBar: true,
      });
    } catch (error) {
      setAcceptInviteError(true);
      toast.error("Error setting your password. Please retry.", {
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="p-8 rounded-lg w-96">
        <ICHNextLogo className="mx-auto mb-12" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mb-8 ">
              <label
                className="block text-sm font-semibold text-gray-500 mb-2"
                htmlFor="email"
              >
                Enter Email
              </label>
              <Field
                className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-xs"
              />
            </div>
            <div className="mb-6 relative">
              <label
                className="block text-sm font-semibold text-gray-500 mb-2"
                htmlFor="tempPassword"
              >
                Enter Temporary Password
              </label>
              <Field
                className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                id="tempPassword"
                name="tempPassword"
                type={showTempPassword ? "text" : "password"}
                placeholder="******"
              />
              <ErrorMessage
                name="tempPassword"
                component="div"
                className="text-red-500 text-xs"
              />
              <div
                className="absolute top-9 right-3 cursor-pointer"
                onClick={() => setShowTempPassword(!showTempPassword)}
              >
                <div className="relative inline-block">
                  <Eyebutton />
                  {showTempPassword && (
                    <span
                      className="absolute left-1 bottom-1 inset-0 flex items-center justify-center text-xl font-bold"
                      style={{ transform: "rotate(30deg)" }}
                    >
                      /
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-6 relative">
              <label
                className="block text-sm font-semibold text-gray-500 mb-2"
                htmlFor="password"
              >
                Enter New Password
              </label>
              <Field
                className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500 text-xs"
              />
              <div
                className="absolute top-9 right-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="relative inline-block">
                  <Eyebutton />
                  {showPassword && (
                    <span
                      className="absolute left-1 bottom-1 inset-0 flex items-center justify-center text-xl font-bold"
                      style={{ transform: "rotate(30deg)" }}
                    >
                      /
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-6 relative">
              <label
                className="block text-sm font-semibold text-gray-500 mb-2"
                htmlFor="confirmPassword"
              >
                Confirm New Password
              </label>
              <Field
                className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                id="confirmPassword"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-500 text-xs"
              />
              <div
                className="absolute top-9 right-3 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <div className="relative inline-block">
                  <Eyebutton />
                  {showConfirmPassword && (
                    <span
                      className="absolute left-1 bottom-1 inset-0 flex items-center justify-center text-xl font-bold"
                      style={{ transform: "rotate(30deg)" }}
                    >
                      /
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center mt-12">
              <button
                className="w-full bg-black text-white font-bold text-sm py-3 px-4 rounded-full focus:outline-none"
                type="submit"
              >
                Register
              </button>
            </div>
          </Form>
        </Formik>
        {acceptInviteError && (
          <div className="mt-4 text-sm text-red-500 text-center font-bold">
            Error setting your password. Please retry.
          </div>
        )}
      </div>
    </div>
  );
};

export default AcceptInvitation;
