import React from "react";
import { INotification } from "../../interface/INotification";
import LogoMapping from "./LogoMapping";
import moment from "moment";

interface NotificationItemProps {
  notification: INotification;
  onClick: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClick,
}) => {
  const IconComponent =
    LogoMapping[notification.title as keyof typeof LogoMapping];

  const notificationClass = notification.isRead
    ? "text-gray-400"
    : "text-[#4d4d4d]";
  const containerClass = notification.isRead
    ? "bg-white hover:bg-gray cursor-pointer"
    : "bg-white hover:bg-gray cursor-pointer";

  const getExtractedTitle = (title: string) => {
    const words = title.split("_");
    if (words[0] === "Reports") {
      return "Tickets";
    }
    return words[0];
  };

  return (
    <div
      onClick={onClick}
      className={`relative flex items-start py-3 ${containerClass} border-b border-gray-200`}
    >
      <div className="mr-3">
        <div
          className={`ml-2 rounded-full flex items-center justify-center ${notification.title === "Reports_content-reported" ? "opacity-70" : ""}`}
        >
          {IconComponent && <IconComponent />}
        </div>
      </div>
      <div className="flex-1">
        <div className={`flex flex-col ${notificationClass}`}>
          <div className="flex items-center justify-between">
            <span className="font-semibold text-gray-600">
              {getExtractedTitle(notification.title)}
            </span>
            {!notification.isRead && (
              <span className="w-2 h-2 bg-red-500 rounded-full mr-4" />
            )}
          </div>
          <div className="text-sm  text-gray-600 mt-1 line-clamp-3">
            {notification.message}
          </div>
          <span className="text-xs text-gray-500 mt-1">
            {moment(notification.timestamp.seconds * 1000).fromNow()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
