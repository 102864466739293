import React from "react";
import { useFormik } from "formik";
import {
  TargetInterface,
  targetValidationSchema,
} from "../../interface/TargetInterface";
import { addTarget } from "../../app/api/admin/target";
import { toast } from "react-toastify";

interface AddTargetProps {
  onClose: () => void;
  fetchTargets: () => void;
  genreOptions: { _id: string; name: string }[];
  sourceOptions: { _id: string; name: string }[];
  regionOptions: { _id: string; name: string }[];
}

const AddTarget: React.FC<AddTargetProps> = ({
  onClose,
  fetchTargets,
  genreOptions,
  sourceOptions,
  regionOptions,
}) => {
  const initialValues = {
    brandName: "",
    socialHandle: "",
    instagramUsername: "",
    description: "",
    genre: genreOptions[0].name,
    brandWeightage: 5,
    source: sourceOptions[0].name,
    region: regionOptions[0].name,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: targetValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let newTarget: TargetInterface = {
          brandName: values.brandName,
          socialHandle: values.socialHandle,
          userName: values.instagramUsername,
          description: values.description,
          genre: genreOptions.find(g => g.name === values.genre)?._id || "",
          brandWeightage: values.brandWeightage,
          source: sourceOptions.find(s => s.name === values.source)?._id || "",
        };

        if (values.genre === "Fashion") {
          newTarget = {
            ...newTarget,
            region:
              regionOptions.find(r => r.name === values.region)?._id || "",
          };
        }
        await addTarget(newTarget);
        toast.success("New target added successfully", {
          hideProgressBar: true,
        });
        fetchTargets();
        resetForm();
      } catch (error) {
        toast.error("Error while adding target", {
          hideProgressBar: true,
        });
      }
    },
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-white rounded-lg shadow-lg relative w-1/2">
        <div className="flex w-full items-center justify-between p-3 shadow-md">
          <h2 className="text-lg font-bold text-primary">Add Target</h2>
          <button onClick={onClose} className="text-3xl">
            &times;
          </button>
        </div>

        <form
          className="flex shadow-md pt-8 pb-8"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col w-1/2 pl-8 pr-8">
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Brand Name
              </label>
              <input
                type="text"
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                placeholder="Enter Brand Name"
                {...formik.getFieldProps("brandName")}
              />
              {formik.touched.brandName && formik.errors.brandName ? (
                <div className="text-negative text-sm">
                  {formik.errors.brandName}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Social Handle
              </label>
              <input
                type="text"
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                placeholder="Enter Social Handle"
                {...formik.getFieldProps("socialHandle")}
              />
              {formik.touched.socialHandle && formik.errors.socialHandle ? (
                <div className="text-negative text-sm">
                  {formik.errors.socialHandle}
                </div>
              ) : null}
            </div>

            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Instagram Username
              </label>
              <input
                type="text"
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                placeholder="Enter Instagram Username"
                {...formik.getFieldProps("instagramUsername")}
              />
              {formik.touched.instagramUsername &&
              formik.errors.instagramUsername ? (
                <div className="text-negative text-sm">
                  {formik.errors.instagramUsername}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Description
              </label>
              <textarea
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm resize-none text-secondary"
                placeholder="Enter Details"
                {...formik.getFieldProps("description")}
                maxLength={250}
              />
              <p className="text-xs ml-2 text-tertiary">
                {formik.values.description.length}/250 character limit
              </p>
              {formik.touched.description && formik.errors.description ? (
                <div className="text-negative text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col w-1/2 border-l-2 pl-8 pr-8">
            <div className="mb-4 flex flex-col">
              <label className="block text-sm mb-2 text-primary">
                Brand Weightage
              </label>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue(
                      "brandWeightage",
                      Math.max(formik.values.brandWeightage - 1, 1)
                    )
                  }
                  className="px-2 py-1 border rounded-lg"
                >
                  &minus;
                </button>
                <span className="mx-2 text-primary">
                  {formik.values.brandWeightage}
                </span>
                <button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue(
                      "brandWeightage",
                      Math.min(formik.values.brandWeightage + 1, 10)
                    )
                  }
                  className="px-2 py-1 border rounded-lg"
                >
                  +
                </button>
              </div>
              {formik.touched.brandWeightage && formik.errors.brandWeightage ? (
                <div className="text-negative text-sm">
                  {formik.errors.brandWeightage}
                </div>
              ) : null}
            </div>

            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">
                Select Genre
              </label>
              <select
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                {...formik.getFieldProps("genre")}
                onChange={e => {
                  formik.handleChange(e);
                  if (e.target.value === "Fashion") {
                    formik.setFieldValue("region", regionOptions[0].name);
                  }
                }}
              >
                {genreOptions.map(g => (
                  <option key={g._id} value={g.name} className="text-sm py-2">
                    {g.name}
                  </option>
                ))}
              </select>
              {formik.touched.genre && formik.errors.genre ? (
                <div className="text-negative text-sm">
                  {formik.errors.genre}
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-primary">Sources</label>
              <select
                className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                {...formik.getFieldProps("source")}
              >
                {sourceOptions.map(s => (
                  <option key={s._id} value={s.name} className="text-sm py-2">
                    {s.name}
                  </option>
                ))}
              </select>
              {formik.touched.source && formik.errors.source ? (
                <div className="text-negative text-sm">
                  {formik.errors.source}
                </div>
              ) : null}
            </div>
            {formik.values.genre === "Fashion" && (
              <div className="mb-4">
                <label className="block text-sm mb-2 text-primary">
                  Region
                </label>
                <select
                  className="w-full px-4 py-3 border border-disabled rounded-lg text-sm text-secondary"
                  {...formik.getFieldProps("region")}
                >
                  {regionOptions.map(r => (
                    <option key={r._id} value={r.name} className="text-sm py-2">
                      {r.name}
                    </option>
                  ))}
                </select>
                {formik.touched.region && formik.errors.region ? (
                  <div className="text-negative text-sm">
                    {formik.errors.region}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </form>
        <div className="flex justify-around">
          <button
            type="button"
            className="font-bold text-tertiary w-2/5 text-sm px-6 py-3 rounded-full border-light border-2 m-4"
            onClick={() => {
              formik.handleSubmit();
              onClose();
            }}
          >
            Add & Close
          </button>
          <button
            type="button"
            className="font-bold text-inverted w-2/5 text-sm bg-primary px-6 py-3 rounded-full m-4"
            onClick={() => formik.handleSubmit()}
          >
            Add & Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTarget;
