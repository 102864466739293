import React, { useState, useEffect } from "react";
import TargetRow from "../../components/Target/TargetRow";
import EditTarget from "../../components/Target/EditTarget";
import AddTarget from "../../components/Target/AddTarget";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import { TargetInterface } from "../../interface/TargetInterface";
import {
  getDropdownOptions,
  getAllTargets,
  getSearchTargets,
  getCounts,
  editTarget,
} from "../../app/api/admin/target";
import { toast } from "react-toastify";
import Pagination from "../../components/Target/Pagination";
import { useDebounce } from "../../app/hooks/targetHook";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  isSidebarOpen: boolean;
};

const Target: React.FC<Props> = ({ isSidebarOpen }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState<TargetInterface | null>(
    null
  );
  const [showActiveTargets, setShowActiveTargets] = useState(true);
  const [targetData, setTargetData] = useState<TargetInterface[]>([]);
  const [loading, setLoading] = useState(true); // New state for loading

  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);

  const [searchInput, setSearchInput] = useState("");
  const debouncedSearch = useDebounce(searchInput);

  const [genreOptions, setGenreOptions] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 15;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const loadDropdownOptions = async () => {
      try {
        const response = await getDropdownOptions();
        setGenreOptions(response.data.data.genres);
        setSourceOptions(response.data.data.sources);
        setRegionOptions(response.data.data.regions);
      } catch (error) {
        toast.error("Error fetching Dropdown options", {
          hideProgressBar: true,
        });
      }
    };
    loadDropdownOptions();
  }, []);

  useEffect(() => {
    fetchTargets();
  }, [debouncedSearch]);

  useEffect(() => {
    setCurrentPage(1);
    fetchTargets();
  }, [showActiveTargets]);

  useEffect(() => {
    if (debouncedSearch) {
      handleDebouncedPageChange();
    } else {
      fetchTargets();
    }
  }, [currentPage]);

  const fetchTargets = async () => {
    setLoading(true); // Start loading
    const status = showActiveTargets ? "active" : "inactive";
    if (debouncedSearch) {
      try {
        const response = await getSearchTargets(
          status,
          debouncedSearch,
          currentPage,
          pageSize
        );
        setCurrentPage(1);

        setTargetData(response.data.data.results);
        setTotalPages(Math.ceil(response.data.data.totalRecords / pageSize));
      } catch (error) {
        toast.error("Error fetching targets", {
          hideProgressBar: true,
        });
        console.error(error);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      try {
        const response = await getAllTargets(status, currentPage, pageSize);
        setTargetData(response.data);
        const counts = await getCounts();

        setActiveCount(counts.data.data.activeCount);
        setInactiveCount(counts.data.data.inactiveCount);

        showActiveTargets
          ? setTotalPages(Math.ceil(counts.data.data.activeCount / pageSize))
          : setTotalPages(Math.ceil(counts.data.data.inactiveCount / pageSize));
      } catch (error) {
        toast.error("Error fetching targets", {
          hideProgressBar: true,
        });
        console.error("Error fetching targets:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const handleDebouncedPageChange = async () => {
    setLoading(true); // Start loading
    const status = showActiveTargets ? "active" : "inactive";
    try {
      const response = await getSearchTargets(
        status,
        debouncedSearch,
        currentPage,
        pageSize
      );
      setTargetData(response.data.data.results);
    } catch (error) {
      toast.error("Error fetching targets", {
        hideProgressBar: true,
      });
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleAddTarget = () => {
    setIsPopupOpen(true);
  };

  const handleRowClick = (target: TargetInterface) => {
    setSelectedTarget(target);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedTarget(null);
    fetchTargets();
  };

  const handleSave = async (updatedTarget: TargetInterface) => {
    const response = await editTarget(updatedTarget);
    toast.success(response.data.message, {
      hideProgressBar: true,
    });
    fetchTargets();
  };

  const toggleShowActiveTargets = async () => {
    setShowActiveTargets(!showActiveTargets);
  };

  return (
    <>
      <div
        className={`${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} fixed overflow-y-scroll h-[calc(100vh-80px)] ml-1`}
      >
        <div className="flex justify-around items-center w-full top-24 p-4 bg-white border">
          <div className="flex-none w-23 ">
            <button
              className={`text-xs pl-3 pr-3 pt-2 pb-2 rounded-md ${showActiveTargets ? "font-semibold bg-active text-primary" : "text-secondary"}`}
              onClick={toggleShowActiveTargets}
            >
              Active ({activeCount})
            </button>
            <button
              className={`text-xs pl-3 pr-3 pt-2 pb-2 rounded-md ${showActiveTargets ? "text-secondary" : "font-semibold bg-active text-primary"}`}
              onClick={toggleShowActiveTargets}
            >
              Inactive ({inactiveCount})
            </button>
          </div>
          <div className="flex-auto w-70  border border-strong items-center rounded-md p-1 mx-[5rem] max-w-[60rem] min-w-[300px]:">
            <div className="flex w-full">
              <SearchIcon className="select-none m-1" />
              <input
                type="text"
                placeholder="Search Sources"
                className="px-2 py-1 text-sm text-primary focus:outline-none w-96"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="flex-auto w-27">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* Header Row */}
        <div className="flex border-b-2 border-gray-200 sticky top-0 z-10 bg-white">
          <div className="w-[15%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-4 py-2">
            Sr No.
          </div>
          <div className="w-[30%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 text-left">
            Brand
          </div>
          <div className="w-[17.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 text-left">
            Genre
          </div>
          <div className="w-[17.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 text-left">
            Weightage
          </div>
          <div className="w-[20%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 text-left">
            Added On
          </div>
        </div>

        {/* Data Rows */}
        <div className="overflow-y-scroll scroller h-[calc(100vh-200px)]">
          {loading
            ? Array.from({ length: pageSize }).map((_, index) => (
                <div className="flex items-stretch bg-white border-b-2 border-light">
                  <div className="w-[20%] border-r-2 border-light px-6 py-2">
                    <Skeleton />
                  </div>
                  <div className="w-[20%] border-r-2 border-light px-6 py-2">
                    <Skeleton />
                  </div>
                  <div className="w-[20%] border-r-2 border-light px-6 py-2">
                    <Skeleton />
                  </div>
                  <div className="w-[20%] border-r-2 border-light px-6 py-2">
                    <Skeleton />
                  </div>
                  <div className="w-[20%] border-r-2 border-light px-6 py-2">
                    <Skeleton />
                  </div>
                </div>
              ))
            : targetData.map((target, index) => (
                <TargetRow
                  key={index}
                  rowId={(currentPage - 1) * pageSize + index + 1}
                  target={target}
                  onClick={handleRowClick}
                  genreOptions={genreOptions}
                  genre={target.genre}
                />
              ))}
        </div>

        <div className="fixed bottom-8 right-8">
          <button
            className="bg-primary hover:bg-secondary text-brandPrimary py-2 px-4 rounded-full text-4xl"
            onClick={handleAddTarget}
          >
            +
          </button>
        </div>
      </div>
      {isPopupOpen && selectedTarget && (
        <EditTarget
          target={selectedTarget}
          onClose={handleClosePopup}
          onSave={handleSave}
          fetchTargets={fetchTargets}
          genreOptions={genreOptions}
          sourceOptions={sourceOptions}
          regionOptions={regionOptions}
        />
      )}
      {isPopupOpen && !selectedTarget && (
        <AddTarget
          onClose={handleClosePopup}
          fetchTargets={fetchTargets}
          genreOptions={genreOptions}
          sourceOptions={sourceOptions}
          regionOptions={regionOptions}
        />
      )}
    </>
  );
};

export default Target;
