import React, { useState } from "react";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { copyImageToClipboard } from "copy-image-clipboard";
import { toast } from "react-toastify";
import { ReactComponent as RightCarousal } from "../../assets/icons/carousalRight.svg";
import { ReactComponent as LeftCarousal } from "../../assets/icons/carousalLeft.svg";
type Props = {
  imageUrl: string;
  images: string[];
  selectedIndex: number;
};

const ExpandView2: React.FC<Props> = ({ imageUrl, images, selectedIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);

  const handleCopyImageToClipboard = async (imageUrl: string) => {
    try {
      await copyImageToClipboard(
        `${process.env.REACT_APP_PROXY_URL}${imageUrl}`
      );
      toast.success("Image copied", {
        hideProgressBar: true,
      });
    } catch (error) {
      toast.error("Error copying image", {
        hideProgressBar: true,
      });
    }
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  };

  const handlePreviousImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="p-5 flex justify-center items-center h-[60%] bg-gray-100">
      <div
        className="absolute left-60 flex-grow flex justify-center items-center cursor-pointer z-10 transform transition-transform duration-300 hover:scale-110"
        onClick={handlePreviousImage}
      >
        <LeftCarousal />
      </div>

      <div className="relative flex-shrink-0">
        <img
          src={images[currentIndex]}
          alt="Expanded view"
          className="h-[500px] rounded-xl mb-4 transition-transform duration-200 ease-in-out hover:scale-105"
        />
        <div className="absolute bottom-2 right-2 flex items-center space-x-2 image-container">
          <div className="flex relative bottom-4 right-2 space-x-1 ">
            <div
              className="bg-slate-100 flex p-1 items-center justify-center rounded-full cursor-pointer "
              onClick={() => handleCopyImageToClipboard(images[currentIndex])}
            >
              <CopyToClipboard />
            </div>
          </div>
        </div>
      </div>

      <div
        className="absolute right-60 flex-grow flex justify-center items-center cursor-pointer z-10 transform transition-transform duration-300 hover:scale-110"
        onClick={handleNextImage}
      >
        <RightCarousal />
      </div>
    </div>
  );
};

export default ExpandView2;
