import { admin, user } from "../axios";

interface updateCollaboratorPayload {
  user_name?: string;
  email?: string;
  access_level?: string;
}

interface updateMultipleCollaboratorPayload {
  id: string;
  data: updateCollaboratorPayload;
}
interface updatePayload {
  collaborators: updateMultipleCollaboratorPayload[];
}

interface addCollaboratorPayload {
  board_id?: string;
  user_id?: string;
  user_name?: string;
  email?: string;
  access_level?: string;
}

interface addMultipleCollaboratorsPayload {
  collaborators: addCollaboratorPayload[];
}
export const getCollaborators = async () => {
  const response = await user.get("collaborators");
  return response;
};

export const getCollaboratorByBoardId = async (boardId: string) => {
  const response = await user.get(`collaborators?board_id=${boardId}`);
  return response;
};

export const getCollaboratorByUserIdAndBoardId = async (
  userId: string,
  boardId: string
) => {
  const response = await user.get(
    `collaborators?user_id=${userId}&board_id=${boardId}`
  );
  return response;
};

export const updateCollaborator = async (
  collaboratorId: string,
  payload: updateCollaboratorPayload
) => {
  const response = await user.put(`collaborators/${collaboratorId}`, payload);
  return response;
};

export const updateMultipleCollaborator = async (payload: updatePayload) => {
  const response = await user.put(`collaborators`, payload);
  return response;
};

export const addCollaborator = async (payload: addCollaboratorPayload) => {
  const response = await user.post(`collaborators`, payload);
  return response;
};

export const addMultipleCollaborators = async (
  payload: addMultipleCollaboratorsPayload
) => {
  const response = await user.post(`collaborators`, payload);
  return response;
};

export const deleteCollaborator = async (collaboratorId: string) => {
  const response = await user.delete(`collaborators/${collaboratorId}`);
  return response;
};

export const getUsers = async () => {
  const response = await user.get("users?limit=1000000");
  return response;
};
