import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../../app/api/auth";
import { ReactComponent as ICHNextLogo } from "../../assets/icons/ichNextLogo.svg";

interface ForgotPasswordFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
});

const ForgotPassword = () => {
  const [linkSent, setLinkSent] = useState(false);
  const [sendLinkError, setSendLinkError] = useState(false);

  const initialValues: ForgotPasswordFormValues = {
    email: "",
  };

  const handleSendLink = async (
    values: ForgotPasswordFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    try {
      await forgotPassword(values.email);
      setSendLinkError(false);
      setLinkSent(true);
      toast.success("Reset link sent successfully.", {
        hideProgressBar: true,
      });
    } catch (error) {
      setLinkSent(false);
      setSendLinkError(true);
      toast.error("Failed to send reset link. Please try again.", {
        hideProgressBar: true,
      });
    }
    setSubmitting(false);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 rounded-lg w-96 text-center">
        <ICHNextLogo className="mx-auto mb-12" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSendLink}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-8">
                <Field
                  className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600 text-xs"
                />
              </div>
              <div className="mb-6">
                <button
                  className="w-full bg-black text-white font-bold text-sm py-3 px-4 rounded-full focus:outline-none"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send Link
                </button>
              </div>
              <div className="text-center mt-4">
                <span className="font-semibold text-sm text-gray-500">
                  Have an account?{" "}
                </span>

                <Link
                  to="/auth/login"
                  className="font-semibold text-sm text-gray-500 underline"
                >
                  Login
                </Link>
              </div>
            </Form>
          )}
        </Formik>
        {linkSent && (
          <div className="mt-4 text-sm text-green-500 text-center font-bold">
            A reset link is sent to this mail ID.
          </div>
        )}
        {sendLinkError && (
          <div className="mt-4 text-sm text-red-500 text-center font-bold">
            Failed to send reset link. Please try again.
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
