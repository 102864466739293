import { admin } from "../axios";

interface User {
  fullName: string;
  isUserActive: boolean;
  groupName: string;
}

export const getUsers = async (limit: number) => {
  const response = await admin.get(`admin/users?limit=${limit}`);
  return response;
};

export const disableUser = async (userId: string) => {
  const response = await admin.put(`admin/users/${userId}/disable`);
  return response;
};

export const enableUser = async (userId: string) => {
  const response = await admin.put(`admin/users/${userId}/enable`);
  return response;
};

export const updateUser = async (userId: string, data: User) => {
  const response = await admin.put(`admin/users/${userId}`, data);
  return response;
};

export const deleteUser = async (userId: string) => {
  const response = await admin.delete(`admin/users/${userId}`);
  return response;
};
