import { CollaboratorOb } from "../../components/Boards/ShareBoard";

interface RemoveCollaboratorProps {
  removeCollaborator?: CollaboratorOb | null;
  onRemove: () => void;
  onCancel: () => void;
}

function RemoveCollaborator(props: RemoveCollaboratorProps) {
  const { removeCollaborator, onRemove, onCancel } = props;

  return (
    <div className="flex justify-center items-center bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg">
        <p className="text-primary font-bold text-2xl mb-4">
          Remove access to Board?
        </p>
        <p className="text-gray-600 mb-6">
          {removeCollaborator?.user_name} will no more be able access this Board
        </p>
        <div className="flex justify-between">
          <button
            className="bg-white text-gray-600 border border-gray-300 rounded-full px-6 py-2"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-white rounded-full px-6 py-2"
            onClick={() => onRemove()}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default RemoveCollaborator;
