import React from "react";

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="#000"
      version="1.1"
      viewBox="0 0 330 330"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M165 330c63.411 0 115-51.589 115-115 0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85S80.001 38.131 80.001 85v52.619C61.373 158.064 50 185.229 50 215c0 63.411 51.589 115 115 115zm15-110.014V240c0 8.284-6.716 15-15 15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986 0-13.785 11.215-25 25-25s25 11.215 25 25c0 8.162-3.932 15.421-10 19.986zM110.001 85c0-30.327 24.673-55 54.999-55 30.327 0 55 24.673 55 55v29.029C203.652 105.088 184.91 100 165 100c-19.909 0-38.651 5.088-54.999 14.028V85z"></path>
        </g>
      </g>
    </svg>
  );
}

export default LockIcon;
