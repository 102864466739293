import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { ReactComponent as BlackHeartIcon } from "../../assets/icons/heartWithBlack.svg";

import { toast } from "react-toastify";
import { copyToClipboard } from "./utils";
import NewsModal from "../../components/Feed/modal";
import SaveToBoard from "../../components/Feed/saveToBoard";
import { NewsItem } from "../../interface/NewsInterface";
import { deleteContentById } from "../../app/api/admin/content";
import { formatDate } from "../../utils";

type Props = {
  url: string;
  image: string;
  description: string;
  provider?: string[];
  title: string;
  source: string;
  handleImageError: (
    source: string,
    url: string,
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void;
  isSaveToBoardOpen: boolean;
  setSaveToBoardOpen: Dispatch<SetStateAction<boolean>>;
  closeSaveToBoard: () => void;
  fromBoard?: boolean;
  id?: string;
  boardId?: string;
  getBoardData?: (option: string) => void;
  closeDailyExpandView?: () => void;
  date?: string;
  notes?: string;
};

const DailyExpandView: React.FC<Props> = ({
  url,
  image,
  description,
  provider,
  title,
  source,
  handleImageError,
  isSaveToBoardOpen,
  setSaveToBoardOpen,
  closeSaveToBoard,
  fromBoard,
  id,
  boardId,
  getBoardData,
  closeDailyExpandView,
  date,
  notes,
}) => {
  //Function to copy text to clipboard
  const handleCopyToClipboard = async () => {
    const response = await copyToClipboard({ title, description, url });
    if (response == "success") {
      toast.success("Text Copied", {
        hideProgressBar: true,
      });
    } else if (response == "error") {
      toast.error("Error Copying Text", {
        hideProgressBar: true,
      });
    }
  };

  const selectedNews: NewsItem = {
    url,
    image,
    description,
    title,
    source,
  };

  const handleRemoveContent = async (id: string) => {
    try {
      // eslint-disable-next-line prettier/prettier
      await deleteContentById(id || "");
      toast.success("Successfully deleted the content", {
        hideProgressBar: true,
      });
      closeDailyExpandView?.();
      getBoardData?.(boardId || "");
    } catch (e) {
      toast.error("Error while deleting the content", {
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="border border-black p-3 rounded-md font-poppins relative">
      <div className="flex mb-2 gap-4 relative">
        <img
          src={image}
          className="w-[100px] h-[100px] object-fill rounded-lg"
          onError={e => handleImageError(source, url, e)}
        />
        <div className="flex flex-col">
          <div className="text-sm  ml-1">
            {source === "Bing News"
              ? provider && provider.length > 0
                ? provider[0]
                : "Bing News"
              : source}
          </div>
          <div className="text-sm font-bold ml-1">{formatDate(date)}</div>

          <h3 className="font-bold text-base">
            <a href={url} target="_blank">
              <p className="line-clamp-4 underline"> {title}</p>
            </a>
          </h3>
        </div>
      </div>

      <div className="text-sm min-h-[100px] max-h-[285px] scroller sc overflow-y-scroll">
        {description}
      </div>
      {fromBoard && (
        <>
          <div className="text-primary text-sm font-bold mb-2">Notes Added</div>

          <div className="text-xs mb-1 text-primary">
            {notes ? notes : "No Notes added"}
          </div>
        </>
      )}
      <div className="flex space-x-2 items-center absolute right-7 bottom-3">
        {fromBoard ? (
          <div
            className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer"
            onClick={() => handleRemoveContent(id || "")}
          >
            <BlackHeartIcon />
          </div>
        ) : (
          <div
            className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer"
            onClick={() => setSaveToBoardOpen(true)}
          >
            <HeartIcon />
          </div>
        )}

        <div
          className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer "
          onClick={handleCopyToClipboard}
        >
          <CopyToClipboard />
        </div>
      </div>
      <NewsModal
        isOpen={isSaveToBoardOpen}
        onClose={closeSaveToBoard}
        title="Save to Board"
      >
        {
          <SaveToBoard
            selectedNews={selectedNews}
            // onClose={closeSaveToBoard}
            handleImageError={handleImageError}
          />
        }
      </NewsModal>
    </div>
  );
};

export default DailyExpandView;
