export const EVENTS_COLORS: { [key: string]: string } = {
  Festival: "#D5F5D5",
  Sports: "#FFC0CB",
  "fashion week": "#FFDAB9",
  "international holiday": "#E3E392",
  "movie release": "#DBC0D5",
  "wedding dates": "#F5F5DC",
  "n/a": "#DBC0D5",
};

export const EVENT_PERIODS = [
  { label: "J-F-M", months: [1, 2, 3] },
  { label: "A-M-J", months: [4, 5, 6] },
  { label: "J-A-S", months: [7, 8, 9] },
  { label: "O-N-D", months: [10, 11, 12] },
];
