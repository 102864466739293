import React, { useState } from "react";
import ExpandView2 from "./ExpandView";
import faviconImage from "../../assets/icons/icon.png";
interface Link {
  title: string;
  content: string;
  url: string;
}

interface UrlSectionProps {
  links: Link[];
}

const UrlSection: React.FC<UrlSectionProps> = ({ links }) => {
  const handleCardClick = (url: string) => {
    window.open(url, "_blank");
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = faviconImage;
  };

  return (
    <>
      {links && links.length > 0 && (
        <div
          className={`flex flex-col items-center rounded mb-1 ml-1 overflow-y-scroll h-[calc(100vh-285px)] scroller`}
        >
          {links &&
            links?.map((link, index) => {
              const faviconUrl = new URL("/favicon.ico", link.url).href;
              return (
                <div
                  key={index}
                  className="p-1 mb-2 rounded-md shadow-md shadow-gray-400 cursor-pointer transform scale-90 hover:scale-95 bg-white"
                  onClick={() => handleCardClick(link.url)}
                >
                  <div className="flex flex-col justify-around">
                    <div className="flex gap-1">
                      <img
                        src={faviconUrl}
                        alt={`${link.title} favicon`}
                        className="w-3 h-3 "
                        onError={handleImageError}
                      />

                      <div className="text-xs text-secondary flex items-center">
                        <div className="line-clamp-2">{link.title}</div>
                      </div>
                    </div>
                    <div className="extra-details opacity-[0.6] transition hover:opacity-100 text-[9px] font-poppins text-primary line-clamp-3">
                      {link.content}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default UrlSection;
