import {
  brandsParams,
  GetBrandsParams,
  Post,
} from "../../interface/DesignerInterface";
import { capitalizeFirstLetter } from "../../utils";
import { admin, user } from "./axios";

const getDesignerFocusPosts = async (
  params: {
    page?: number;
    pageSize?: number;
    startDate?: string;
    endDate?: string;
    genre?: string;
    product?: string;
    gender?: string;
    region?: string;
    searchQuery?: string;
    colors?: string;
    category?: string;
    s3Key?: string;
    bucketName?: string;
  },
  options?: { signal?: AbortSignal }
) => {
  const response = await user.get("designer-focus/posts", {
    params,
    signal: options?.signal,
  });

  const filteredPosts = response.data.data.posts.filter(
    (post: Post) => !post.is_video
  );

  return {
    ...response.data,
    posts: filteredPosts,
  };
};

export const searchDesignerFocusPosts = async (params: {
  page?: number;
  pageSize?: number;
  query: string | undefined;
}) => {
  const response = await user.get("designer-focus/search", {
    params,
  });
  const filteredPosts = response.data.data.posts.filter(
    (post: Post) => !post.is_video
  );

  return {
    ...response.data,
    posts: filteredPosts,
  };
};

const getDropdownData = async () => {
  const response = await user.get(`/targets/targetoptions`);

  return response.data.data;
};

const getBrandsData = async (params: GetBrandsParams) => {
  const response = await user.get(`/designer-focus/brands`, { params });
  return response.data.data;
};

const getProducts = async () => {
  const response = await user.get(`/designer-focus/products`);
  const products = response.data.data.filter(
    (product: string) => product !== "N/A" && product !== "None"
  );

  return products;
};

export { getDesignerFocusPosts, getDropdownData, getBrandsData, getProducts };
