import { admin, user } from "../axios";
import { TargetInterface } from "../../../interface/TargetInterface";

export const getSearchTargets = async (
  status: string,
  input: string,
  currentPage: number,
  pageSize: number
) => {
  const response = await user.get("targets/search", {
    params: {
      status,
      input,
      currentPage,
      pageSize,
    },
  });
  return response;
};

export const getCounts = async () => {
  const response = await user.get("targets/count");
  return response;
};

export const getDropdownOptions = async () => {
  const response = await user.get("targets/targetoptions");
  return response;
};

export const getAllTargets = async (
  status: string,
  currentPage: number,
  pageSize: number
) => {
  const response = await user.get("targets", {
    params: {
      status,
      currentPage,
      pageSize,
    },
  });
  return response.data;
};

export const addTarget = async (newTarget: TargetInterface) => {
  const response = await user.post("targets/add", newTarget);
  return response;
};

export const editTarget = async (target: TargetInterface) => {
  const targetId = target._id;
  const response = await user.put(`targets/${targetId}/update`, target);
  return response;
};

export const deleteTarget = async (targetId: string) => {
  const response = await user.delete(`targets/${targetId}/delete`);
  return response;
};
