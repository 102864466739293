import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OTPInput from "../../components/Auth/OTPInput";
import { resetPassword } from "../../app/api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ICHNextLogo } from "../../assets/icons/ichNextLogo.svg";

interface FormValues {
  email: string;
  otp: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  otp: Yup.string().length(6, "OTP must be 6 characters").required("Required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .max(64, "Password must be at most 64 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Required"),
});

const ResetPassword: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(false);
  const navigate = useNavigate();

  const onChange = (
    value: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    setOtp(value);
    setFieldValue("otp", value);
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await resetPassword(values.email, otp, values.newPassword);
      setPasswordResetError(false);
      setPasswordReset(true);
      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    } catch (error) {
      setPasswordReset(false);
      setPasswordResetError(true);
      toast.error("Error resetting your password. Please retry.", {
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="p-8 rounded-lg w-96 items-center">
        <ICHNextLogo className="mx-auto mb-8" />
        {!passwordReset && (
          <h1 className="font-bold text-3xl mb-4 text-center">
            Reset Your Password
          </h1>
        )}
        {passwordReset && (
          <div className="mt-4 text-2xl text-positive text-center font-bold">
            Congratulations your password has been reset. Redirecting to login
            page...
          </div>
        )}
        <Formik
          initialValues={{
            email: "",
            otp: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold text-gray-500 mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <Field
                  className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold text-gray-500 mb-2"
                  htmlFor="otp"
                >
                  Enter OTP
                </label>
                <OTPInput
                  value={otp}
                  onChange={value => onChange(value, setFieldValue)}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold text-gray-500 mb-2"
                  htmlFor="newPassword"
                >
                  Enter New Password
                </label>
                <Field
                  className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="******"
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold text-gray-500 mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <Field
                  className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="******"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="mb-6">
                <button
                  className="w-full mt-4 bg-black text-white font-bold text-sm py-3 px-4 rounded-full focus:outline-none"
                  type="submit"
                >
                  Submit
                </button>
              </div>

              {passwordResetError && (
                <div className="mt-4 text-sm text-red-600 text-center font-bold">
                  An error occoured while resetting your password.
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
