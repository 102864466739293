import React, { useState, ChangeEvent } from "react";
import { addContent } from "../../app/api/admin/content";
import { SelectedPost } from "../../interface/DesignerInterface";
import { toast } from "react-toastify";
import Modal from "../Common/Modal";
import CreateBoard from "../Boards/CreateBoard";
import {
  getBoardsByUserId,
  getBoardsSearchByAccessLevel,
} from "../../app/api/admin/board";
import { Board } from "../../interface/BoardsInterface";

type Props = {
  imageUrl: string;
  selectedPost: SelectedPost | null;
  publicBoards: Board[];
  setPublicBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  privateBoards: Board[];
  setPrivateBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  onClose: () => void;
  userId: string;
};

const SaveToBoard: React.FC<Props> = ({
  imageUrl,
  selectedPost,
  onClose,
  userId,
  publicBoards,
  setPublicBoards,
  privateBoards,
  setPrivateBoards,
}) => {
  const [selectedBoards, setSelectedBoards] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notes, setNotes] = useState<string>();
  const [searchString, setSearchString] = useState<string>("");

  const handleCheckboxChange = (boardId: string) => {
    if (selectedBoards.includes(boardId)) {
      setSelectedBoards(selectedBoards.filter(id => id !== boardId));
    } else {
      setSelectedBoards([...selectedBoards, boardId]);
    }
  };

  const handleSubmit = async () => {
    if (!selectedPost) {
      console.error("No post selected");
      return;
    }

    const postId = selectedPost?.postId.toString();
    const payload = {
      board_ids: selectedBoards,
      category_name: "Designer",
      postId: postId,
      notes: notes,
    };

    try {
      await addContent(payload);
      toast.success("Added content successfully", {
        hideProgressBar: true,
        style: { width: "270px" },
      });
      setSelectedBoards([]);
      onClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.warn(error?.response.data.message, {
        hideProgressBar: true,
      });
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getBoards = async () => {
    const res = await getBoardsByUserId(userId);
    setPublicBoards(res?.data?.data?.public.boards);
    setPrivateBoards(res?.data?.data.private.boards);
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    setSearchString(searchStr);
  };

  const filteredPublicBoards = publicBoards.filter(i =>
    i.board_name.toLowerCase().includes(searchString)
  );

  const filteredPrivateBoards = privateBoards.filter(i =>
    i.board_name.toLowerCase().includes(searchString)
  );

  return (
    <div className="flex justify-center items-center h-[60%] bg-gray-100">
      <div>
        <div className="flex">
          <div className="w-1/2 pr-4">
            <div className="relative">
              <img
                src={imageUrl}
                alt="A person wearing a hat and a black sweater"
                className="rounded-lg mb-4"
              />
            </div>
            <label className="block text-gray-700 mb-2">
              Enter Notes (optional)
            </label>
            <textarea
              className="w-full border rounded-lg p-2"
              placeholder="Input Text"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </div>
          <div className="w-1/2 pl-4">
            <div className="flex items-center mb-4">
              <input
                type="text"
                className="w-full border rounded-lg p-2"
                placeholder="Search Board"
                onChange={handleSearch}
                value={searchString}
              />
              <button
                className="ml-2 text-sm text-primary"
                onClick={toggleModal}
              >
                +
              </button>
            </div>
            <div className="h-[300px] overflow-x-auto">
              {filteredPublicBoards.length === 0 &&
                filteredPrivateBoards.length === 0 && <div> No boards</div>}
              {filteredPublicBoards.length > 0 && (
                <>
                  <h3 className="text-gray-700 mb-2">Public</h3>
                  <div className="mb-2">
                    {filteredPublicBoards?.map(board => (
                      <div className="mb-2" key={board.id}>
                        <input
                          type="checkbox"
                          id={board.id}
                          className="mr-2"
                          checked={selectedBoards.includes(board.id)}
                          onChange={() => handleCheckboxChange(board.id)}
                          style={{ cursor: "pointer" }}
                        />
                        <label htmlFor={board.id} className="text-gray-700">
                          {board.board_name}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {filteredPrivateBoards.length > 0 && (
                <>
                  <h3 className="text-gray-700 mb-2 mt-4">Private</h3>
                  {filteredPrivateBoards.map(board => (
                    <div className="mb-2" key={board.id}>
                      <input
                        type="checkbox"
                        id={board.id}
                        className="mr-2"
                        checked={selectedBoards.includes(board.id)}
                        onChange={() => handleCheckboxChange(board.id)}
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor={board.id} className="text-gray-700">
                        {board.board_name}
                      </label>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6">
          <button
            className="w-full bg-black text-white py-3 rounded-lg"
            onClick={handleSubmit}
          >
            Add to board{" "}
            {selectedBoards.length > 0 && `(${selectedBoards.length})`}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} title="Create Board">
          <CreateBoard
            onClose={closeModal}
            userId={userId}
            refresh={getBoards}
          />
        </Modal>
      )}
    </div>
  );
};

export default SaveToBoard;
