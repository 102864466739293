import React from "react";
import { capitalizeFirstLetter } from "../../utils";

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  status: string;
}

interface UserRowProps {
  user: User;
  index: number;
  onClick: (user: User) => void;
}

const UserRow: React.FC<UserRowProps> = ({ user, index, onClick }) => {
  return (
    <div
      className="flex border-b-2 border-gray-200 hover:bg-gray-100 cursor-pointer w-full"
      onClick={() => onClick(user)}
    >
      <div className="w-[25%] border-r-2 border-gray-200 px-6 py-4 text-sm">
        {capitalizeFirstLetter(user.name)}
      </div>
      <div className="w-[45%] border-r-2 border-gray-200 px-6 py-4 text-sm">
        {user.email}
      </div>
      <div className="w-[15%] border-r-2 border-gray-200 px-6 py-4 text-sm">
        {capitalizeFirstLetter(user.role)}
      </div>
      <div className="w-[15%] px-2 py-4">
        <span
          className={`flex justify-center items-center text-xs font-thin rounded w-full h-6 ${
            user.status === "Active"
              ? "bg-green-100 text-green-800"
              : user.status === "Pending"
                ? "bg-yellow-100 text-yellow-800"
                : "bg-gray-200 text-gray-600"
          }`}
        >
          {user.status}
        </span>
      </div>
    </div>
  );
};

export default UserRow;
