import React, { useState } from "react";
import { inviteUser } from "../../app/api/auth";
import { validateEmail } from "../../utils";
import { toast } from "react-toastify";
import { handleError } from "../../utils";
interface AddMemberProps {
  onClose: () => void;
  refreshUsers: () => void;
}

const AddMember: React.FC<AddMemberProps> = ({ onClose, refreshUsers }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [roleError, setRoleError] = useState(false);

  const validateFields = () => {
    let isValid = true;

    if (name.trim() === "") {
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (role === "") {
      setRoleError(true);
      isValid = false;
    } else {
      setRoleError(false);
    }

    return isValid;
  };

  const inviteAndClose = async () => {
    if (!validateFields()) return;
    try {
      await inviteUser(name, email, role);
      refreshUsers();
      onClose();
    } catch (error: unknown) {
      handleError(error);
    }
    onClose();
  };

  const inviteAndNext = async () => {
    if (!validateFields()) return;
    try {
      await inviteUser(name, email, role);
      refreshUsers();
      resetValues();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const resetValues = () => {
    setName("");
    setEmail("");
    setRole("");
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-white rounded-lg shadow-lg relative w-96">
        <div className="flex w-full items-center justify-between p-3 ">
          <h2 className="text-lg font-bold">Add Member</h2>
          <button onClick={onClose} className="text-3xl">
            &times;
          </button>
        </div>

        <form className="pl-12 pr-12 pt-8 pb-4">
          <div className="mb-4">
            <label className="block text-sm mb-2">Name</label>
            <input
              type="text"
              className={`w-full px-4 py-3 border rounded-lg text-sm ${nameError ? "border-red-500" : ""}`}
              placeholder="Enter Name"
              value={name}
              onChange={e => {
                setName(e.target.value);
                setNameError(false);
              }}
            />
            {nameError && (
              <p className="text-red-500 text-xs mt-2">Name is required.</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-2">Email</label>
            <input
              type="email"
              className={`w-full px-4 py-3 border rounded-lg text-sm ${emailError ? "border-red-500" : ""}`}
              placeholder="Enter Email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
            />
            {emailError && (
              <p className="text-red-500 text-xs mt-2">
                Please enter a valid email.
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-2">Role</label>
            <span className="flex">
              <label className="w-1/2">
                <input
                  type="radio"
                  name="role"
                  value="admin"
                  checked={role === "admin"}
                  className="accent-black"
                  onChange={e => {
                    setRole(e.target.value);
                    setRoleError(false);
                  }}
                />{" "}
                Admin
              </label>
              <label>
                <input
                  type="radio"
                  name="role"
                  value="forecaster"
                  checked={role === "forecaster"}
                  onChange={e => {
                    setRole(e.target.value);
                    setRoleError(false);
                  }}
                  className="accent-black"
                />{" "}
                Forecaster
              </label>
            </span>
            {roleError && (
              <p className="text-red-500 text-xs mt-2">Please select a role.</p>
            )}
          </div>
        </form>
        <div className="flex justify-around shadow-top-md">
          <button
            type="button"
            className="font-bold text-sm px-6 py-3 rounded-full border-gray border-2 m-4"
            onClick={inviteAndClose}
          >
            Invite & Close
          </button>
          <button
            type="submit"
            className="font-bold text-sm bg-black text-white px-6 py-3 rounded-full m-4"
            onClick={inviteAndNext}
          >
            Invite & Add Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
