import { admin, forecaster } from "./axios";

export const getKeyEvents = async (role: string | undefined) => {
  const response =
    role === "admin"
      ? await admin.get(`key-events`)
      : await forecaster.get(`key-events`);
  return response;
};

export const getFilteredEvents = async (
  categories: string[],
  fromDate: string,
  toDate: string,
  role: string | undefined
) => {
  const categoriesParam = categories.join(",");
  const response =
    role === "admin"
      ? await admin.get(`key-events/filter`, {
          params: {
            categories: categoriesParam,
            fromDate,
            toDate,
          },
        })
      : await forecaster.get(`key-events/filter`, {
          params: {
            categories: categoriesParam,
            fromDate,
            toDate,
          },
        });

  return response.data;
};

export const getEventCategories = async (role: string | undefined) => {
  const response =
    role === "admin"
      ? await admin.get(`key-events/categories`)
      : await forecaster.get(`key-events/categories`);
  return response;
};
