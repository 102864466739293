import React, { useEffect, useState } from "react";
import UserRow from "../components/Invite/UserRow";
import AddMember from "../components/Invite/AddMember";
import EditMember from "../components/Invite/EditMember";
import { deleteUser, getUsers, updateUser } from "../app/api/admin/invite";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  status: string;
}

interface UserResponse {
  email: string;
  name: string;
  group: string;
  userId: string;
  userStatus: string;
}

const Invite = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getInvitedUsers = async () => {
    try {
      const response = await getUsers(0);
      const users = response.data.data.users.map((user: UserResponse) => {
        return {
          id: user.userId,
          name: user.name,
          email: user.email,
          role: user.group,
          status:
            user.userStatus.charAt(0).toUpperCase() + user.userStatus.slice(1),
        };
      });

      setUserData(users);
    } catch (error) {
      toast.error("Error fetching users", {
        hideProgressBar: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInvitedUsers();
  }, []);

  const handleAddRow = () => {
    setIsPopupOpen(true);
  };

  const handleRowClick = (user: User) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedUser(null);
  };

  const handleSave = async (updatedUser: User) => {
    try {
      await updateUser(updatedUser.id.toString(), {
        fullName: updatedUser.name,
        isUserActive: updatedUser.status === "Active",
        groupName: updatedUser.role,
      });
      toast.success("User updated successfully", {
        hideProgressBar: true,
      });
      getInvitedUsers();
    } catch (error) {
      toast.error("Error while updating user, please try again", {
        hideProgressBar: true,
      });
    }
  };

  const handleDelete = async (userId: number) => {
    try {
      await deleteUser(userId.toString());
      toast.success("User deleted successfully", {
        hideProgressBar: true,
      });
      getInvitedUsers();
    } catch (error) {
      toast.error("Error while deleting user, please try again", {
        hideProgressBar: true,
      });
    }
  };

  return (
    <div
      className={`${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} fixed overflow-y-scroll h-[calc(100vh-80px)] ml-1 ${isPopupOpen ? "z-10" : ""}`}
    >
      {/* Header Row */}
      <div className="flex border-b-2 border-gray-200 sticky top-0 z-10 bg-gray-100 bg-white">
        <div className="w-[25%] border-r-2 border-gray-200 font-bold px-6 py-3">
          Name
        </div>
        <div className="w-[45%] border-r-2 border-gray-200 font-bold px-6 py-3">
          Email ID
        </div>
        <div className="w-[15%] border-r-2 border-gray-200 font-bold px-6 py-3">
          Role
        </div>
        <div className="w-[15%] font-bold px-6 py-3">Status</div>
      </div>

      {/* Data Rows */}
      {isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="flex border-b-2 border-gray-200">
              <div className="w-[25%] px-6 py-3">
                <Skeleton width={150} />
              </div>
              <div className="w-[25%] px-6 py-3">
                <Skeleton width={250} />
              </div>
              <div className="w-[25%] px-6 py-3">
                <Skeleton width={100} />
              </div>
              <div className="w-[25%] px-6 py-3">
                <Skeleton width={100} />
              </div>
            </div>
          ))
        : userData.map((user, index) => (
            <UserRow
              key={user.id}
              index={index}
              user={user}
              onClick={handleRowClick}
            />
          ))}

      {/* Add Row Button */}
      <div className="fixed bottom-8 right-8">
        <button
          className="bg-black hover:bg-blue-200 text-white py-2 px-4 rounded-full text-4xl"
          onClick={handleAddRow}
        >
          +
        </button>
      </div>

      {/* Popups */}
      {isPopupOpen && selectedUser && (
        <EditMember
          user={selectedUser}
          onClose={handleClosePopup}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
      {isPopupOpen && !selectedUser && (
        <AddMember onClose={handleClosePopup} refreshUsers={getInvitedUsers} />
      )}
    </div>
  );
};

export default Invite;
