import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function InviteIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={props.fill}
      viewBox="0 0 24 24"
    >
      <path d="M8.143 11.143a3.857 3.857 0 100-7.714 3.857 3.857 0 000 7.714zm12-3a3.001 3.001 0 11-6.002 0 3.001 3.001 0 016.002 0zM1.715 14.786a1.929 1.929 0 011.928-1.929h9c.17 0 .334.022.492.064a6.411 6.411 0 00-1.992 4.65c0 .57.074 1.123.214 1.648-.836.304-1.888.495-3.214.495-6.428 0-6.428-4.5-6.428-4.5v-.428zm21.428 2.786a5.572 5.572 0 11-11.143 0 5.572 5.572 0 0111.143 0zm-5.142-3.429a.428.428 0 10-.858 0v3h-3a.429.429 0 000 .857h3v3a.429.429 0 00.858 0v-3h3a.428.428 0 100-.857h-3v-3z"></path>
    </svg>
  );
}

export default InviteIcon;
